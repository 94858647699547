.enquiry-form {
    background-color: $bg--white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 auto;

    &__title {
        color: $text--secondary;
        font-family: inherit;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 15px; 
    }

    &__subtitle {
        color: $text--primary;
        font-family: inherit;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 45px; 
        text-transform: capitalize;
        max-width: 75%;
    }

    .umbraco-forms-page {
        padding-block-start: 10px;
        display: flex;
        flex-direction: column;
        gap: 19px;

        .umbraco-forms-fieldset {
            .row-fluid {
                display: flex;
                flex-direction: row;
                gap: 26px;
                justify-content: space-between;
            }
        }

        .umbraco-forms-container {
            flex: 1;

            textarea {
                height: 166px;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .enquiry-form {
        padding: 37px 18px 66px;
        gap: 7px;

        &__title {
            font-size: 12px;
            line-height: 12px; 
        }

        &__subtitle {
            font-size: 16px;
            line-height: 30px; 
            max-width: 100%;
        }

        .umbraco-forms-page {
            padding-block-start: 11px;
            gap: 10px;

            .umbraco-forms-fieldset {
                .row-fluid {
                    flex-direction: column;
                    gap: 10px;
                }
            }

            .umbraco-forms-container {
                textarea {
                    height: 100px;
                }
            }
        }
    }
}