/*=============================================
=            CTA Info Panel            =
=============================================*/

.info {
  padding: 98px 85px 120px 58px;

  @include media-breakpoint-down(md) {
    padding: 50px 32px;
  }

    &__subtitle {
        color: $secondary;
        font-size: 15px;
        font-weight: 700;
        line-height: 15px;
        margin-block-end: 10px;
    }

    &__title {
        max-width: 850px;
        color: $text--dark-blue;
        font-size: 45px;
        font-weight: 700;
        line-height: 57px;
        text-transform: capitalize;
    }

    &__bottom {
      row-gap: 30px;
      column-gap: 60px;
      margin-block-start: 50px;

      &-left {
        gap: 30px;
      }
    }

    &__image {
      object-fit: cover;
    }

    &__card {
      &-outer {
        position: relative;
        height: 496px;

        &:before {
          position: absolute;
          content: "";
          width: 30px;
          height: 60px;
          background-color: $primary;
          inset-block-end: 0;
          inset-inline-start: 51%;

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        &:after {
          position: absolute;
          content: "";
          width: 100%;
          height: 30px;
          background: linear-gradient(270deg, $primary 49%, rgba(255,255,255,1) 49%);
          inset-block-end: -5px;

          @include media-breakpoint-down(sm) {
            background: $primary;
            height: 35px;
          }
        }
      }

      &-inner {
        padding: 40px;
        background-color: $primary;
        position: absolute;
        width: 80%;
        inset-block-start: 50%;
        transform: translateY(-50%);
        inset-inline-end: 0;

        @include media-breakpoint-down(sm) {
          width: 100%;
          inset-block-start: 70%;
        }
      }

      &-icon {
        margin-block-end: 20px;
        width: 50px;
      }

      &-text {
        max-width: 60%;
        color: $white;
        font-size: 17px;
        font-weight: 700;
        line-height: 26px; 
        text-transform: capitalize;
        margin-block-end: 15px;
      }

      &-link {
        margin-inline-start: 25px;
        width: 22px;
        height: 22px;
      }
    }

    &__image {
        max-width: 486px;
        aspect-ratio: 243 / 250;
    }

    &__content {
      height: 100%;

      @include media-breakpoint-down(lg) {
        order: 1;
      }

      .link {
        font-size: 15px;
        letter-spacing: 0.2px;
      }
    }

    &__stat {
      color: $secondary;
      font-size: 65px;
      font-weight: 700;
      line-height: 65px;
      margin-block-end: 20px; 

      &-container {
        max-width: 175px;
      }

      &-line {
        background-color: #E7EBEF;
        width: 30px;
        height: 2px;
        margin: 30px auto;
      }
    }

    &__label {
      color: #64748B;
      text-align: center;
      font-size: 17px;
      font-weight: 700;
      line-height: 26px;
    }

    .link--outline {
        width: fit-content;
        margin-block-start: 40px;
    }

    .container {
        padding: 0;
        max-width: 485px;
    }

    .rte {
      color: $text--dark-blue;
      line-height: 26px;
      
      & ul {
        list-style: none;
      }

      & li {
        color: $text--dark-blue;
        position: relative;
        margin-block-end: 10px;
        
        &:after {
          position: absolute;
          content: "";
          height: 2px;
          width: 14px;
          background-color: $secondary;
          inset-block-start: 10px;
          inset-inline-start: -30px;
        }
      }
    }
}

/*=====  End of CTA Info Panel  ======*/
