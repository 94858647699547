.product-details{
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    &__glide {
        max-width: 590px;
    }

    &__upper {
        display: flex;
        flex-direction: row;
        gap: 170px;
        justify-content: space-around;

        &-left {
            display: flex;
            flex-direction: column;
            gap: 15px;

            &__image {
                width: 533px;
                height: 461px;

                &-container {
                    position: relative;

                    #glide__pagination {
                        position: absolute;
                        background-color: $white;
                        top: 10px;
                        right: 10px;
                        padding: 10px 15px;
                        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                        color: black;
                        border-radius: 20px;
                    }
                }
            }

            .glide {
                max-width: 530px;
                &__slide {
                    height: 200px;
                    width: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &.active {
                        border: 4px solid #e7e7e7;
                    }
                }
            }
        }

        &-right {
            display: flex;
            flex-direction: column;

            table {
                width: 100%;
                border-collapse: collapse;
                margin-block: 10px;

                tr {
                    &:nth-child(even) {
                        background-color: #E7E7E7;
                    }
                    &:nth-child(odd) {
                        background-color: #ffffff;
                    }
                }

                td {
                    border: 1px solid #E7E7E7;
                    padding: 10px;

                    &:first-child {
                        width: 30%;
                        color: #666;
                        font-family: inherit;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                    
                    &:last-child {
                        color: #2C2C2C;
                        font-family: inherit;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }

        &-brochure {
            &-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                transition: transform 0.3s ease-in-out;
                will-change: transform;

                &:hover {
                    transform: scale(1.1);
                }
            }

            &-image {
                display: inline;
                width: 35px;
                height: 28;
            }

            &-text {
                color: #262C63;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 21.6px; 
            }
        }

        &-button {
            padding: 14px 20px;
            border-radius: 4px;
            background: $bg--secondary;
            color: #FFF;
            font-family: inherit;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 21.6px;
            width: fit-content;
            cursor: pointer;
            @include transition;

            &:hover {
                background: $bg--primary;
            }

            &-container {
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                gap: 50px;
            }
        }

        &-product-name {
            color: #262C63;
            font-family: inherit;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 47.88px; 
            text-transform: uppercase;
        }

        &-category-name{
            color: #000;
            font-family: inherit;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 47.88px; /* 133% */
            text-transform: uppercase;
        }
    }

    &__lower {
        margin-block-start: 40px;
        display: flex;
        flex-direction: column;

        &-inner {
            margin: 0;
        }
    }

    &__bar {
        display: flex;
        flex-direction: row;
        flex: 1;
        background-color: #F7F8F9;
        margin-block-end: 30px;

        &-item {
            padding: 10px 20px;
            color: #626262;
            text-align: center;
            font-family: inherit;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            position: relative;
            cursor: pointer;

            &.active {
                background-color: #262C63;
                color: #FFF;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -10px; 
                    left: 50%;
                    transform: translateX(-50%);
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 10px solid #262C63;
                }
            }
        }
    }

    &__specs {
        &-inner {
            display: none;
            flex-direction: row;
            justify-content: space-between;
            gap: 100px;

            &.active {
                display: flex;
            }
        }
    
        &-left {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 40px;
        }

        &-right {
            flex-grow: 1;
        }

        &-table {
            display: flex;
            flex-direction: column;
            gap: 16px;

            table {
                border-collapse: collapse;
                border-style: hidden;
                width: 100%;
    
                td {
                    padding-block: 10px;
                    border: 1px solid #E7E7E7;
    
                    &:first-child {
                        color: #000;
                        font-family: inherit;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        width: 32%;
                    }
    
                    &:last-child {
                        padding-inline-start: 20px;
                        color: #000;
                        font-family: inherit;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
    
            &-name {
                color: #1C1C1C; 
                font-family: inherit;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 23.2px; 
                text-transform: uppercase;
            }
        }
    }

    &__features {
        &-inner {
            display: none;
            flex-direction: column;
            gap: 40px;

            &.active {
                display: flex;
            }
        }

        &-item {
            padding: 15px 25px;
            box-shadow: 0 0px 10px rgb(0 0 0 / 0.2);
            border-radius: 5px;
            display: flex;
            flex-direction: column;

            @include transition;

            &:hover {
              box-shadow: 0px 6px 7px 8px rgba(218, 218, 218, 0.6);
            }

            &-title {
                margin-block-end: 10px;
                color: #1c1c1c;
                font-family: inherit;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 23.2px;

                &:after {
                    margin-block-start: 10px;
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1.5px;
                    background-color: #e7e7e7;
                }
            }

            &-content {
                font-family: inherit;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0;

                .container {
                    padding: 0;
                    max-width: none;
                }
            }
        }
    }

    &__equipment {
        &-inner {
            display: none;
            gap: 23px;

            &.active {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 23px;
            }
        }

        &-item {
            padding: 10px 20px;
            box-shadow: 0 0px 10px rgb(0 0 0 / 0.2);
            text-align: center;

            @include transition;

            &:hover {
                box-shadow: 0px 6px 7px 8px rgba(218, 218, 218, 0.6);
            }

            &-title {
                color: #1c1c1c;
                font-family: inherit;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 23.2px;
                margin-block-end: 10px;

                &:after {
                    margin-block-start: 10px;
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1.5px;
                    background-color: #e7e7e7;
                }
            }

            &-content {
                font-family: inherit;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}

@include media-breakpoint-down (sm) {
    .product-details {
        padding-inline: 20px;

        &__upper {
            flex-direction: column;
            gap: 30px;

            &-left {
                &__image {
                    width: 350px;
                    height: 350px;

                    &-container{
                        #glide__pagination {
                            top: 0;
                            right: 0;
                            padding: 5px 10px;
                            font-size: 12px;
                        }
                    }
                }

                .glide {
                    max-width: 100%;
                    &__slide {
                        height: 100px;
                        width: 100px;
                    }
                }
            }

            &-right {
                table {
                    tr {
                        td {
                            &:first-child {
                                width: 10%;
                            }
                        }
                    }
                }
            }

            &-button {
                &-container {
                    flex-direction: column;
                    gap: 14px;
                    align-items: center;
                }
            }

            &-category {
                &-name {
                    font-size: 24px;
                    line-height: 32px;
                    text-align: center;
                }
            }

            &-product{
                &-name {
                    font-size: 24px;
                    line-height: 32px;
                    text-align: center;
                }
            }
        }

        &__bar {
            flex-direction: column;
            gap: 10px;
            margin-block-end: 20px;

            &-item {
                padding: 10px 0;
                font-size: 16px;

                &.active {
                    &::after {
                        bottom: -5px;
                        left: 50%;
                        transform: translateX(-50%);
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-top: 5px solid #262C63;
                    }
                }
            }
        }

        &__specs {
            &-inner {
                flex-direction: column;
                gap: 20px;

                &.active {
                    gap: 25px;
                }
            }

            &-table {
                table {
                    td {
                        &:first-child {
                            width: 35%;
                        }
                    }
                }
            }

            &-left {
                gap: 25px;
            }
        }

        &__features {
            &-inner {
                flex-direction: column;
                gap: 20px;
            }
        }

        &__equipment {
            &-inner {
                &.active {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }

            &-item {
                flex: 1;
            }
        }
    }
}