@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';

.get-in-touch {
    background-size: cover;
    height: fit-content;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #E7EBEF;
    }

    &__background {
        position: absolute;
        height: 695px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 0;

        &-image {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $bg--primary;
            opacity: 0.85;
            z-index: 1; 
        }
    }

    &__bottom {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 80px;
        align-items: center;
        margin-block-end: 30px;

        &-left {
            color: $text--primary;
            font-family: inherit;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            flex: 1;
            display: flex;
            justify-content: center;
        }

        &-right {
            flex: 1;
            display: flex;
            justify-content: center;
            flex-direction: row;
            gap: 50px;
        }
    }

    &__wrapper {
        &-top {
            padding-block-start: 130px;
            position: relative;
            display: flex;
            flex-direction: row;
            gap: 80px;
        }
    }

    &__quote {
        color: $bg--white;
        font-family: inherit;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 47px; 
        overflow: auto;
        position: relative;
        max-width: 100%;

        &-glide {
            &--slides {
                position: relative;
                overflow-y: auto;
                height: 250px;
            }
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $bg--secondary;
            border-radius: 10px;
        }
    }

    &__person {
        &-name {
            color: $bg--white;
            font-family: inherit;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px; 
            text-transform: capitalize;
        }

        &-job {
            color: #F9F9F9;
            font-family: inherit;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 13px; 
        }

        &-container {
            display: none;
            flex-direction: column;
            gap: 12px;

            &.active {
                display: flex;
                animation: fade_in_show 0.3s;
            }
        }
    }

    &__image {
        height: 80px;
        width: 60px;
        border-radius: 2px;

        &-container {
            display: flex;
            flex-direction: row;
            gap: 10px;
            margin-bottom: 10px;
            width: fit-content;
        }

        &-symbol {
            width: 30px;
            height: 22px;
            align-self: flex-end;
        }
    }

    &__button {
        color: $bg--secondary;
        font-size: 24px;
        padding: 4px 8px;
        border-radius: 2px;
        border: 2px solid $bg--secondary;
        cursor: pointer;

        @include transition;

        &:hover {
            color: $bg--white;
            border-color: $bg--white;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;

            &:hover, &:focus {
                color: $bg--secondary;
                border-color: $bg--secondary;
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: flex-end;
        flex-direction: row;
        gap: 10px;
    }

    &__testimonial {
        &-container {
            display: flex;
            flex-direction: column;
            gap: 50px;
        }

        &-bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-left: 2px solid $bg--white;
            padding-inline-start: 42px;
        }

        &-title {
            color: $bg--secondary;
            font-family: inherit;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 16.5px; 
            text-transform: capitalize;
            margin-left: 10px;
        }
    }

    &__right {
        max-width: 585px;
        flex: 1;
    }

    &__contact {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        border: 1px solid #F2F2F2;
        width: fit-content;
        gap: 10px;

        &-bottom {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        
        &-label {
            font-size: 13px;
            font-weight: 400;
        }

        &-value {
            font-weight: 700;
            font-size: 14px;

            @include transition;

            &:hover {
                color: $text--secondary;
                text-decoration: underline;
            }
        }

        &-icon {
            width: 16px;
            height: 16px;
        }
    }

    &__left {
        background-color: $bg--white;
        box-shadow: 0px 1px 73px 0px rgba(40, 40, 40, 0.15);
        padding: 60px 45px 85px;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-radius: 5px;
        z-index: 2;
        overflow: visible;
        height: fit-content;

        &-wrapper {
            max-width: 585px;
            min-height: 650px;
        }

        &-title {
            color: $bg--primary;
            font-family: inherit;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 33px; 
            text-transform: capitalize;
        }

        &-subtitle {
            color: $bg--primary;
            font-family: inherit;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
        }

        .umbraco-forms-page {
            display: flex;
            flex-direction: column;
            gap: 26px;
    
            .umbraco-forms-fieldset {
                .row-fluid {
                    display: flex;
                    flex-direction: row;
                    gap: 37px;
                    justify-content: space-between;
                }
            }
    
            .umbraco-forms-container {
                flex: 1;
    
                textarea {
                    height: 135px;
                }
            }
        }
    }
}

@keyframes fade_in_show {
    0% {
         opacity: 0;
         transform: scale(0)
    }

    100% {
         opacity: 1;
         transform: scale(1)
    }
}

@include media-breakpoint-down(md) {
    .get-in-touch {
        display: block;
        height: fit-content;

        &:after {
            bottom: unset;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;

            &-top {
                padding-block-start: unset;
                flex-direction: column-reverse;
                gap: 50px;
            }
        }

        &__left {
            padding: 12px 10px;
            border-radius: 5px 5px 0 0;

            &-title {
                text-align: center;
            }

            &-wrapper {
                min-height: unset;
            }

            .umbraco-forms-page {
                display: flex;
                flex-direction: column;
                gap: 26px;
        
                .umbraco-forms-fieldset {
                    .row-fluid {
                        display: flex;
                        flex-direction: column;
                        gap: 26px;
                        justify-content: space-between;
                    }
                }
        
                .umbraco-forms-container {
                    flex: 1;
        
                    textarea {
                        height: 135px;
                    }
                }
            }
        }

        &__right {
            padding: 56px 32px;
            max-width: 100%;
        }

        &__bottom {
            background-color: #fff;
            padding-block: 10px;
            z-index: $above;
            flex-direction: column;
            gap: 20px;
        }
    
        &__quote {
            &-glide {
                &--slides {
                    height: 250px;
                }
            }
        }
    }
}