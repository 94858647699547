/*=============================================
=            CTA Banner            =
=============================================*/

.cta-banner {
  width: 100%;
  position: relative;
  height: 485px;
  background-size: cover;
  background-position: center; 
  
  @include media-breakpoint-down(md) {
    background-position: right;
  }

  &__content {
    height: 100%;
    width: 50%;
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
    z-index: $above;
    margin-inline-start: auto;
    padding-inline-end: 130px;

    @include media-breakpoint-down(md) {
      padding: 32px;
      width: 100%;
    }
  }

  &__title {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    margin-block-end: 10px;
  }

  &__subtitle {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 38.4px;
    margin-block-end: 24px;
  }
}

/*=====  End of CTA Banner  ======*/
