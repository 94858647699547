/*=============================================
=            header            =
=============================================*/

.header {
  background-color: $bg--white;
  color: $text--primary;
  padding-inline: 32px;
  max-width: $container-width--default;
  margin: 0 auto;

  &__logo {
    padding: 16px 0;
  }

  &__search {
    &-bar {
      display: none;
    }
  }

  &__links {
    &--search-active {
      .header {
        &__link {
          display: none;

          &-title {
            display: none;
          }
        }

        &__search {
          &-bar {
            width: 100%;
            display: flex;
          }

          &-form {
            width: 100%;
          }
        }
      }
    }

    &-desktop {
      gap: 36px;
      width: 70%;
    }
  }

  &-links {
    &-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  &-link {
    color: #000;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.352px;
    white-space: nowrap;

    @include transition;

    &:hover {
      color: $text--secondary;
    }
  }

  &__double {
    padding: 25px 56px;
    display: flex;
    flex-direction: row;
    gap: 70px;

    &-breaker {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 247%;
    left: -48%;
    z-index: 2;
    background-color: $bg--white;
    border-top: 1px solid $bg--secondary;
    display: none;
    flex-direction: row;

    &.active {
      display: flex;
    }
  }

  &__features {
    &-right {
      padding: 28px 35px;
      display: none;
      flex-direction: row;
      gap: 40px;
      background-color: #F7F8F9;

      &.active {
        display: flex;
      }
    }

    &-left {
      cursor: pointer;
    }

    &-links {
      min-width: 210px;
    }

    &-images {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &-top {
        width: 255px;
        height: 133px;
      }

      &-bottom {
        width: 255px;
        height: 161px;
      }
    }

    &-logo {
      box-sizing: border-box;
      height: 100%;
      width: 100px;
      z-index: 1;
      position: relative;

      &-container {
        padding: 15px 40px;
        width: 180px;
        height: 62px;
        box-sizing: border-box;
        position: relative;
        transition: all 0.3s ease;
        z-index: 1;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 0px;
          height: 100%;
          background-color: #F7F8F9;
        }

        &:not(.active):hover {
          &:after {
            width: 100%;
          }
        }

        &.active {
          background-color: #F7F8F9;

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 3px;
            height: 100%;
            background-color: $bg--secondary;
          }
        }
      }
    }
  }

  &__link {
    position: relative;

    &-title {
      color: #0D0F11;
      font-family: inherit;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: -0.352px;
      cursor: pointer;
      @include transition;

      &:hover {
        color: $text--secondary;
      }
    }

    &.active &-title {
      color: $text--secondary;
    }

    &.active {
      &::after {
        content: "";
        position: absolute;
        bottom: -148%;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $bg--secondary;
      }
    }
  }

  &__right {
    gap: 48px;
  }

  &__buttons {
    gap: 16px;
  }

  &__button {
    background-color: #E7EAEE;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    border: 2px solid transparent;
    stroke: #64748B;

    @include transition;

    &:hover {
      border-color: #0D0F11;
    }

    &--active {
      background-color: $bg--primary;
    }
  }
}

@include media-breakpoint-down(md) {

  .header,
  .navbar {
    display: none;
  }

  .mobile-menu {
    display: flex;
  }
}

@include media-breakpoint-up(md) {
  .mobile-menu {
    display: none;
  }

  .header,
  .navbar {
    display: flex;
  }
}

@include media-breakpoint-down(md) {
  .header, .navbar {
    display: none;
  }

  .mobile-menu {
    display: flex;
  }
}

@include media-breakpoint-up(md) {
  .mobile-menu {
    display: none;
  }

  .header, .navbar {
    display: flex;
  }
}

/*=====  End of header  ======*/