/*=============================================
=            Links Card Grid            =
=============================================*/

.links-grid {
  display: flex;
  flex-direction: column;
  gap: 56px;

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(332px, 1fr));
    grid-gap: 16px;
  }

  &__title {
    color: #000;
    text-align: center;
    font-family: inherit;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; 
    letter-spacing: -0.84px;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
        font-size: 32px;
    }
  }

  &__subtitle {
    color: #282E39;
    text-align: center;
    font-family: inherit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    max-width: 50%;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &-container {
      min-height: 217px;
      max-height: 217px;
    }
  }

  &__item {
    box-shadow: 10px 4px 90px 0px rgba(0, 0, 0, 0.10);

    &-title {
      color: #1A202C;
      text-transform: uppercase;
      margin-bottom: 17px;
      font-size: 22px;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: -0.44px;
    }

    &-content {
      padding: 23px 30px 19px;
    }
  }

  &__list {
    height: 100%;
    list-style: disc;
    text-decoration: underline;
    color: $text--celeste;
    margin-left: 15px;
    gap: 5px;
    
    &-link {
      @include transition;
      text-decoration-color: $text--secondary;
      text-decoration: underline;
      font-size: 18px;
      line-height: 30px;

      &:hover {
        color: $text--secondary;
        //change textunderline color
      }
    }
  }

}

/*=====  End of Links Card Grid  ======*/