.search {
    display: flex;
    flex-direction: column;

    &__count {
        padding-block-start: 30px;
        font-weight: 500;
        opacity: .8;
    }

    &__pagination {
        padding: 17px 20px;
        color: $primary;
        font-family: inherit;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        border-radius: 2px;
        border: 2px solid #E7EBEF;
        cursor: pointer;

        &:not(&--active):hover {
            background-color: #F6F7F9;

            &:active {
                color: $white;
            }
        }

        &--active {
            background-color: $primary;
            border-color: $primary;
            color: #FFF;
            pointer-events: none;

            @include transition;

            &:hover {
                color: $white;
                opacity: 0.8;
            }
        }

        &-container {
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            gap: 10px;
            margin-block-start: 40px;
        }
    }

    &__found-text {
        font-family: inherit;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        margin-inline-start: 12px;
        margin-block-start: 40px;
    }

    &__top {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &-title {
            font-family: inherit;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            margin-inline-start: 23px;
        }
    }

    &__result {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
        padding-block: 30px;

        &-link {
            color: $primary;
            font-family: inherit;
            font-size: 20.836px;
            font-style: normal;
            font-weight: 700;

            @include transition;

            &:hover {
                color: $secondary;
            }
        }

        &-description {
            max-width: 88%;
            color: #333;
            font-family: inherit;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #E7EAEE;
        }
    }

    &__section {
        margin-block-start: 40px;

        &-title {
            color: #333;
            font-family: inherit;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            position: relative;
            padding-block-end: 15px;
            margin-block-end: 11px;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #E7EAEE;
            }
        }
    }

    &__input {
        outline: none;
        font-size: 15px;
        width: 100%;
        font-family: inherit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        &::placeholder {
            color: #64748B;
        }

        &-icon {
            width: 24px;
            height: 24px;
        }

        &-container {
            display: flex;
            flex-direction: row;
            gap: 22px;
            width: 100%;
        }

        &-wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 22px;
            padding: 7px 10px 8px 22px;
            background-color: #F6F7F9;
            align-items: center;

            .button {
                padding: 12px 20px;
                line-height: unset;

                &__label {
                    font-size: 14px;
                    color: #FFF;
                    font-size: 14px;
                    font-weight: 700;
                    min-width: 100px;
                }
            }
        }
    }
}

.highlight {
    &--primary {
        color: $primary;
    }

    &--secondary {
        color: $secondary;
    }

    &--yellow {
        color: #FBF719;
    }
}

@include media-breakpoint-down(md) {
    .search {
        &__result {
            &-description {
                max-width: 100%;
            }
        }

        &__section {
            margin-block-start: 30px;
        }

        &__found-text {
            margin-inline-start: 0;
            margin-block-start: 30px;
        }

        &__input {
            &-wrapper {
                padding: 7px 10px 8px 10px;
                flex-direction: column;

                .button {
                    width: 100%;
                }
            }
        }

        &__top {
            &-title {
                margin-inline-start: 0;
                text-align: center;
            }
        }
    }
}