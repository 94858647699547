.not-found {
    margin: 0 auto;
    gap:20px;

    .link {
        width: fit-content;

        &__label {
            text-decoration: unset;
        }
    }

    &__title {
        font-size: 3rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    &__subtitle {
        font-size: 1.5rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }

    &__left {
        flex: 1;
        align-self: center;
    }

    &__image {
        max-height: 100%;
        max-width: 600px;
        margin: auto;
    }
}

@include media-breakpoint-down (sm) {
    .not-found {
        flex-direction: column;
        gap: 5px;
    }
}