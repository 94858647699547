.widget {
    position: fixed;
    bottom: 10px;
    right: 20px;
    z-index: 1000;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    &__toggle, &__content {
        background-color: $bg--secondary; 
        border-radius: 50%;
        text-align: center;
        position: relative;
    }

    &__toggle {
        width: 65px;
        height: 65px;
        padding: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s ease;
        will-change: scale;

        &:hover {
            transform: scale(.9);
        }

        &-icon {
            height: 27px;
            width: 27px;
        }
    }

    &__list {
        display: flex;
        visibility: hidden;
        flex-direction: column;
        margin-right: -10px;
        gap: 10px;

        &--active {
            visibility: visible;
            animation: listAnimation 0.3s ease;
        }

        &-item {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 10px;
            align-items: center;
            background-color: $bg--secondary;
            padding: 6px 5px 6px 12px;
            border-radius: 32px;
            font-size: 14px;
            font-weight: 700;
            color: $bg--white;
            font-family: inherit;
            cursor: pointer;
            transition: scale 0.3s ease;

            &:hover, 
            &:nth-child(2):hover {
                scale: .9;
                color: $bg--white;
            }

            &:nth-child(2) {
                transform: translateX(-23%);
            }
        }

        &-icon {
            width: 28px;
            height: 25px;

            &-container {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 8px;
                border-radius: 50%;
                background-color: $bg--white;
            }
        }
    }
}

@keyframes listAnimation {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}