/*=============================================
=            CTA Info Panel            =
=============================================*/

.info-panel {
  background: linear-gradient(to right, $bg--primary 60%, $bg--white 50%);
  margin: 50px auto;
  color: $text--white;

  @include media-breakpoint-down(md) {
    background: $bg--primary;
    flex-direction: column;
  }

  &__left, &__right {
    flex: 1;
    
  }

  &__inner {
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__left {
    justify-content: flex-end;

    &-wrapper {
      max-width: 750px;
      padding: 100px 59px 100px 100px;
      gap: 30px;

      @include media-breakpoint-down(md) {
        padding: 32px;
      }
    }
  }

  &__right {
    flex: 1;
    position: relative;
    max-height: 580px;
    cursor: pointer;
  }

  &__title {
    font-size: 36px;
    font-weight: 700;
    color: $text--secondary;
  }

  &__subtitle, &__footer {
    font-size: 16px;
    font-weight: 300;
  }

  &__stats {
    gap: 5px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__stat-value {
    font-size: 60px;
    font-weight: 700;
    line-height: 64px; 
  }

  &__stat-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__button {
    background-color: $bg--primary;
    position: absolute;
    left: -1px;
    bottom: 0;
    cursor: pointer;
    width: 100px;
    padding: 18px 19px;
    gap: 11px;

    
    &:hover .info-panel__icon{
      transform: scale(1.1);
    }
    
    &:focus .info-panel__icon{
      transform: scale(1.1);
    }

    &-text {
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      line-height: 130%; 
      letter-spacing: -0.24px;
    }
  }

  &__icon {
    height: 100%;
    width: 100%;

    @include transition;

    &-container {
      margin: auto;
    }
  }
}

/*=====  End of CTA Info Panel  ======*/
