/*=============================================
=            Brand Carousel            =
=============================================*/

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.brands {
    &__header {
        margin-bottom: 50px;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        &-right {
            .link {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    &__title {
        color: $text--black;
        font-size: 39px;
        font-weight: 700;
        line-height: 200%;
        letter-spacing: -0.858px;

        &-highlight {
            color: $text--primary;
        }
    }

    &__subtitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;
        letter-spacing: -0.396px;
    }

    .link--simple-primary {
        @include media-breakpoint-down(md) {
            padding: 10px 0;
        }
    }

    &-carousel {
        cursor: pointer;
        margin-bottom: 0 !important;

        &__image {
            object-fit: contain;
            max-height: 79px;
        }

        //  Glide Carousel Styles
        .glide__slide {
            opacity: 0.5;
            filter: grayscale(1);

            @include transition;

            @include media-breakpoint-down(xs) {
                filter: none;
                opacity: 1;
            }

            &:hover,
            &:focus {
                filter: none;
                opacity: 1;
            }
        }

        .glide__bullets {
            position: relative;
            display: flex;
            justify-content: center;
            margin-top: 50px;
            list-style-type: none;
            transform: translateX(0);
            left: 0;

            button {
                display: block;
                width: 8px;
                height: 8px;
                padding: 0;
                border: none;
                border-radius: 100%;
                background-color: #ccc;
                text-indent: -9999px;
            }

            .glide__bullet--active {
                background-color: $bg--primary;
                width: 40px;
                border-radius: 10px;
            }

            .glide__bullet {
                margin-inline-end: 7px;
                cursor: pointer;
                display: none;
            }
        }

        // used to make the bullets look like pages
        @media (min-width: 1301px) and (max-width: 4000px) {
            .glide__bullet:nth-child(6n + 1) {
                display: inline;
            }
        }

        @media (min-width: 1111px) and (max-width: 1300px) {
            .glide__bullet:nth-child(5n + 1) {
                display: inline;
            }
        }

        @media (min-width: 851px) and (max-width: 1100px) {
            .glide__bullet:nth-child(4n + 1) {
                display: inline;
            }
        }

        @media (min-width: 601px) and (max-width: 850px) {
            .glide__bullet:nth-child(3n + 1) {
                display: inline;
            }
        }

        @media (min-width: 460px) and (max-width: 600px) {
            .glide__bullet:nth-child(2n + 1) {
                display: inline;
            }
        }

        @media (min-width: 1px) and (max-width: 459px) {
            .glide__bullet:nth-child(1n + 1) {
                display: inline;
            }
        }
    }
}

/*=====  End of Brand Carousel  ======*/
