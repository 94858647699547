/*=============================================
=            Services Component            =
=============================================*/

.services {
  position: relative;
  background: linear-gradient(90deg, $primary 50%, transparent 65%, transparent 100%);
  
  @include media-breakpoint-down(sm) {
    background: linear-gradient(90deg, $primary 75%, transparent 85%, transparent 100%);
  }

  &__title {
    color: $white;
    text-transform: uppercase;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 30px;
  }

  &__inner {
    margin: 0 auto;
    padding: 51px 134px 10px;

    @include media-breakpoint-down(md) {
      padding: 51px 30px 10px;
    }
  }

  &__tabs {
    color: $white;
    gap: 10px;
    box-sizing: border-box;
    border-bottom: 6px solid #E4EBF2;
    width: fit-content;
    padding: 8px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  &__label {
    position: relative;
    max-width: 125px;
    font-size: 16px;
    text-align: center;
    transition: background-color, border-color 0.2s ease-in-out;
    cursor: pointer;

    &:focus {
      border-color: $black;
    }

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -14px;
      transform: translate(-50%, 0);
      height: 6px;
      width: 100%;
      background-color: $secondary;
      z-index: -1;
    }

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0%;
      transform: translate(-50%, 100%) rotate(45deg);
      height: 22px;
      width: 22px;
      background: linear-gradient(135deg, $secondary 50%, transparent 50%);;
      z-index: -1;
    }

    label {
      cursor: pointer;
    }

    &:first-of-type:before,
    &:last-of-type:before {
      width: 136%;
    }
  }

  &__radio {
    width: 0; 
    height: 0; 
    position: absolute; 
    left: -9999px;
  }

  &__radio + &__label {
    margin: 0; 
    padding: 5px 0px; 
    box-sizing: border-box;
    position: relative; 
    font-size: 16px;
    font-weight: 400;
  }

  &__radio:hover + &__label {
    border-color: $secondary;    
  }

  &__radio:checked + &__label {
    color: $bg--secondary;
    z-index: 1;
  }

  &__cards {
    display: none;

    &-top {
      gap: 24px;
      margin: 32px 0 24px;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
    }
  }

  &__card {  
    max-width: 50%;

    @include media-breakpoint-down(md) {
      min-width: auto;
      max-width: none;
      width: 100%;
    }

  &-image {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    height: 100%;
    object-fit: cover;
  }

    &-title {
      margin-bottom: 20px;
      margin-inline-end: auto;
      font-size: 26px;
      font-weight: 500;
      line-height: 35px;
      letter-spacing: 0.5px;
      color: $text--secondary;

      @include media-breakpoint-down(md) {
        font-size: 25px;
        line-height: 29px;
        margin-bottom: 10px;
      }
    }

    &-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0.5px;
      color: $text--primary;
    }
  }

  .rte {
    color: $white;
    padding: 0;
    line-height: 30px;
    margin-block-end: 10px;

    li {
      color: $white;
    }
  }

  ::-webkit-scrollbar{
    height: 8px;
    width: 4px;
    background: rgba($secondary ,.5);
  }

  ::-webkit-scrollbar-thumb{
    background: $secondary;
    border-radius: 20px;
  }
}

/*=====  End of Services Component  ======*/
