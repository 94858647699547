.umbraco-forms-page {
    &.success {
        text-align: center;
        font-size: 50px;
        color: $text--primary;
    }

    &.disabled {
        input, select, textarea {
            pointer-events: none;
            background-color: #F9F9F9;
            opacity: 0.5;
            color: #757575;
        }
    }

    input, select, textarea {
        flex: 1;
        width: 100%;
        border: 1px solid #ECECEC;
        background: #F9F9F9;
        padding: 15px 21px;
        color: #000;
        resize: none;

        font-family: inherit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &::placeholder {
            color: #757575;
        }

        @include media-breakpoint-down(md) {
            font-size: 12px;
        }

        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    textarea {
        padding-inline-start: 19px;
    }

    input[type="submit"] {
        cursor: pointer;
        color: #FFF;
        font-family: inherit;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 21.6px; 
        background-color: $bg--primary;
        border-radius: 4px;

        @include transition;

        &:hover {
            background-color: $bg--secondary;
        }
    }

    select {
        color: #757575;
        flex: 1;
        width: 100%;
        padding-inline-start: 16px;
    }

    label {
        display: none;
    }
}

.umbraco-forms-hidden {
    display: none;
}

.error {
    &-list {
        color: #FF0000;
        font-family: inherit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 10px 20px;
        list-style-type: disc;

        &__item {
            color: #FF0000;
            font-family: inherit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}