/*=============================================
=            navbar            =
=============================================*/

.navbar {
  background-color: $bg--primary;
  color: $text--white;

  &__link {
    @include transition;

    &:hover,
    &:focus {
      scale: 1.1;
    }
  }

  &__wrapper {
    width: $container-width--default;
    padding-inline: 32px;
    margin: 0 auto;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column-reverse;
    padding-block-end: 15px;
  }

  &__socials {
    gap: 8px;
  }

  &__right {
    gap: 55px;
  }

  &__dropdown {
    display: flex;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    flex-direction: column;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    gap: 30px;
    top: 100%;
    left: 0;
    z-index: 2;
    width: min-content; 
    background-color: $bg--primary;
    padding: 20px 30px;
    align-items: start;
    justify-content: start;
    transition: visibility 0.2s linear 0.2s, opacity 0.2s linear; 
    transform: translateZ(0); 

    &.visible {
      visibility: visible;
      opacity: 1;
      transition-delay: 0s; 
    }

    &-item {
      position: relative;
      transition: color 0.1s ease-in;

      &:hover {
        color: $bg--secondary;
      }

      &.selected {
        color: $bg--secondary;
        &::before {
          content: "\2713";
          position: absolute;
          left: -20px;
          top: -1px;
        }
      }
    }
  }

  &__dropdowns {
    &-container {
      display: flex;
      flex-direction: row;
      gap: 16px;
      height: 100%;
      color: #F6F7F9;
    }

    &-breaker {
      width: 1px;
      background-color: rgba(246, 247, 249, 0.50);
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      gap: 7px;

      &-upper {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        gap: 7px;
        cursor: pointer;
      }
    }
  }

  &__contact {
    padding: 13px 17px 8px;
    background-color: $bg--secondary;
    height: 100%;

    &-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 150%; 
    }

    &-number {
      font-size: 16px;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0.8px;
    }
  }
}

/*=====  End of navbar  ======*/