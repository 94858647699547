/*=============================================
=            detailed Card Grid            =
=============================================*/

.info-cards {
    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
        column-gap: 100px;
        row-gap: 40px;

        @include media-breakpoint-down(sm) {
            grid-template-columns: initial;
        }
    }

    &__header {
        margin-bottom: 56px;
    }

    &__content {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        max-width: 880px;
        text-align: center;

        @include media-breakpoint-down(sm) {
            text-align: start;
        }
    }

    &__item {
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        gap: 19px;

        .link {
            padding: 10px;
            font-size: 16px;
        }

        &-image {
            width: 100%;
            height: 100%;
            object-fit: contain;

            &-container {
                padding-inline: 30px;
                min-height: 300px;
                max-height: 300px;
            }
        }

        &-text {
            &-title {
                color: $black;
                text-align: center;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: 33.6px;
                padding: 10px;
            }

            &-content {
                color: #3a3a3a;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 27px;
                padding: 10px 10px 0 10px;
            }
        }
    }

    .rte {
        text-align: center;
        max-width: 1200px;
        padding: 0px 30px;
        font-size: 18px;
        line-height: 27px;
    }
}

/*=====  End of detailed Card Grid  ======*/
