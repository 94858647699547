/*=============================================
=            Banner Carousel            =
=============================================*/

@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';

.banner {
    &-carousel {
        &__image {
            z-index: -1;
            position: absolute;
            width: 100%;
            height: 480px;
            inset-inline-start: 0;
            inset-block-start: 0;
            object-fit: cover;
        }

        &__content {
            max-width: 860px;
            z-index: $above;
            color: $white;
            text-align: center;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 46px;
            padding-block-end: 30px;
        }

        &-container {
            position: relative;
            height: 100%;
            padding: 20px;
            background-color: rgba($primary, .7);
        }

        //  Glide Carousel Styles
        .glide__slide {
            position: relative;
            height: 480px;
        }
    }

    .glide__bullets {
        position: absolute;
        inset-block-end: 28%;
        inset-inline-start: 50%;
        transform: translate(-50%, 100%);
        width: fit-content;
        height: fit-content;
        z-index: $above;
        display: flex;
        justify-content: center;
        list-style-type: none;

        @include media-breakpoint-down(sm) {
            inset-block-end: 10%;
        }

        button {
            display: block;
            width: 32px;
            height: 32px;
            padding: 0;
            border: none;
            border-radius: 100%;
        }

        .glide__bullet {
            position: relative;
            margin-inline-end: 20px;
            cursor: pointer;
            color: $white;
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            background-color: transparent;
            box-shadow: none;

            &:not(:last-child):after {
                content: '';
                position: absolute;
                height: 1px;
                width: 6px;
                background-color: $white;
                inset-inline-end: -14px;
                inset-block-start: 50%;
            }
        }

        .glide__bullet--active {
            background-color: $bg--secondary;
        }
    }
}

/*=====  End of Banner Carousel  ======*/
