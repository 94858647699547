/*=============================================
=            Video Carousel            =
=============================================*/

@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';

.video {
    position: relative;
    margin-bottom: 180px;

    @include media-breakpoint-down(sm) {
        padding-bottom: 200px;
    }

    @include media-breakpoint-down(xs) {
        padding-bottom: 250px;
    }

    &__wrapper {
        position: relative;

        &--with-pointer {
            cursor: pointer;
        }
    }

    &__player {
        background-color: $black;
        width: 100%;
        max-height: 700px;
        aspect-ratio: 32/15;
        pointer-events: none;
    }

    &__thumbnail {
        z-index: $above;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        cursor: pointer;
        pointer-events: none;

        &--visible {
            opacity: 1;
            pointer-events: auto;
        }

        &-play-icon {
            z-index: $above;
            cursor: pointer;
            pointer-events: none;
            position: absolute;
            width: 100px;
            inset-inline-start: 50%;
            inset-block-start: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__text {
        &-container {
            z-index: $above;
            color: $white;
            max-width: 400px;
            position: absolute;
            transform: translateY(-36%);

            @include media-breakpoint-down(sm) {
                max-width: 100%;
                inset-inline-end: 0;
                inset-block-end: -2px;
                transform: translateY(100%);
            }
        }

        &-upper {
            padding: 30px 50px;

            @include media-breakpoint-down(sm) {
                color: $black;
                height: fit-content !important;
                padding: 15px 32px;
                color: $black;
            }
        }

        &-lower {
            background-color: $secondary;
            padding: 16px 50px;
            padding-bottom: 50px;

            @include media-breakpoint-down(sm) {
                padding: 15px 32px;
            }
        }

        &-title {
            height: fit-content;
            margin-top: auto;
            max-width: 75%;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;

            @include media-breakpoint-down(sm) {
                max-width: 100%;
            }
        }

        &-content {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-block-end: 20px;
        }

        &-link {
            position: relative;
            padding-inline-start: 34px;
            font-size: 15px;
            font-weight: 700;
            
            &-arrow {
                position: absolute;
                width: 22px;
                inset-block-start: 0;
                inset-inline-start: 0;
            }
        }
    }
}

/*=====  End of Video Carousel  ======*/
