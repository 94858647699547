.brand-hero {
    height: 774px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 18px;

    &__wrapper {
        width: 100%;
        padding-block-start: 180px;
        padding-inline: 128px;
        height: 100%;
        position: relative;
        background-size: cover;
    }

    &__top {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 580px;

        &-wrapper {
            max-width: $container-width--default;
            margin: 0 auto;
        }

        &-title {
            color: #FFF;
            font-family: inherit;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
            letter-spacing: -0.96px;
            text-transform: uppercase;
        }

        &-content {
            color: #FFF;
            font-family: inherit;
            font-size: 22px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
        }
    }

    &__search {
        cursor: pointer;
        padding: 14px 20px;
        border-radius: 4px;
        font-size: 18px;
        font-weight: 700;
        line-height: 21.6px;
        gap: 8px;
        min-width: fit-content;

        background-color: $bg--primary;
        color: $text--white;

        @include media-breakpoint-down(md) {
            font-size: 14px;
        }

        &:hover,
        &:focus {
            background-color: $bg--secondary;
            color: $text--white;
        }
    }

    &__bottom {
        position: absolute;
        padding: 20px 94px;
        border-radius: 5px 5px 0px 0px;
        background-color: $bg--white;
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.10);
        justify-content: space-between;
        bottom: -6%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;

        button {
            padding: 14px 32px;
        }

        &-desktop {
            display: flex;
        }

        &-mobile {
            display: none;
        }
    }

    &__buttons {
        &-container {
            display: flex;
            flex-direction: row;
            gap: 20px;
            align-items: center;
            padding-block-start: 30px;
        }

        &-quote {
            color: #FFF;
            text-align: center;
            font-family: inherit;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            letter-spacing: -0.32px;

            display: flex;
            padding: 15px 30px;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            background: $bg--secondary;
            transition: scale 0.3s ease-in-out;

            &:hover {
                cursor: pointer;
                color: #fff;
                scale: 1.05;
            }
        }

        &-link {
            color: #FFF;
            text-align: center;
            font-family: inherit;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            letter-spacing: -0.32px;
            text-decoration-line: underline;
            padding: 0px 30px;

            &:hover {
                color: $bg--secondary;
            }
        }
    }

    &__selection {
        margin-right: 30px;
        padding-right: 30px;
        position: relative;

        &:not(.no-after):after {
            content: "";
            position: absolute;
            right: 0;
            height: 100%;
            width: 1px;
            background-color: #DEDEDE;

            @include media-breakpoint-down(md) {
                width: 0px;
            }
        }

        select,
        option {
            width: fit-content;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: -0.28px;
            color: #1A202C;
            font-family: inherit;
            transform: translateX(-3px);
            width: 170px;
        }
    }

    &-breaker {
        width: 122px;
        height: 3px;
        background: #fff;
        margin-bottom: 17px;
    }

    &__label {
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;
    }
}

@include media-breakpoint-down(md) {
    .brand-hero {
        height: auto;
        display: flex;

        &__buttons {
            &-container {
                padding-block-start: 0px;
                gap: 5px;
            }

            &-quote {
                padding: 15px 30px;
                font-size: 14px;
            }

            &-link {
                font-size: 14px;
            }
        }

        &__top {
            padding: 44px 19px;
            width: 90%;

            &-title {
                font-size: 32px;
            }

            &-content {
                font-size: 16px;
            }
        }

        &__bottom {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 15px 30px;
            gap: 10px;

            &-desktop {
                display: none;
            }

            &-mobile {
                display: flex;
            }
        }

        &__selection {
            margin-right: 0px;
            padding: 10px 30px;
            width: 100%;

            option,
            select {
                width: 100%;
            }
        }

        &__selections {
            flex: 1;
            border-radius: 3px;
            border: 1px solid #E7E7E7;
            width: 100%;
            align-items: flex-start;
            justify-content: flex-start;
        }

        &__wrapper {
            padding: 0px;
            width: 100%;
            height: fit-content;
        }

        &-breaker {
            margin-bottom: 10px;
        }

        .button {
            flex: 1;
            width: 100%;
        }
    }
}