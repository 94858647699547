.feature-title {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 10px;
    align-items: center;
    max-width: 850px;
    padding-block: 59px;

    &__title {
        color: $text--secondary;
        text-align: center;
        font-family: inherit;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 15px; 
    }

    &__subtitle {
        color: $text--primary;
        text-align: center;
        font-family: inherit;
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: 57px; 
        text-transform: capitalize;
    }

    &__description {
        color: #282E39;
        text-align: center;
        font-family: inherit;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; 
    }
}