/*=============================================
=            Small Hero            =
=============================================*/

.hero {
  background-repeat: no-repeat;
  background-size: cover;
  height: 458px;
  position: relative;

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  &__mute-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: $bg--secondary;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    z-index: 2;
    padding: 10px;
    color: #fff;
    font-size: 32px;
    border: 3px solid $bg--secondary;

    will-change: transform;

    @include transition;

    &:hover {
      background-color: transparent;
      border-color: $bg--secondary;
      color: $bg--secondary;
    }

    &:before {
      display: block;
      width: 33px;
      height: 33px;
    }

    @include media-breakpoint-down(sm) {
      bottom: 10px;
      right: 10px;
      font-size: 24px;
      padding: 6px;
      bottom: 87%;
      right: 6px;
      background-color: transparent;
      border-color: $bg--secondary;
      color: $bg--secondary;

      &:before {
        width: 24px;
        height: 24px;
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(15, 54, 123, 0.56) 0%, rgba(38, 44, 99, 0.56) 100%);
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  &__inner {
    text-align: center;
    max-width: 697px;
    gap: 16px;
    margin: 0 auto;
    z-index: 1;
    padding-top: 170px;
  }

  &__title {
    color: $text--white;
    font-family: Rubik;
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    position: relative;
  }

  &__breadcrumbs {
    position: absolute;
    bottom: 32px;
    left: 135px;
    z-index: 1;
    color: $text--white;

    @include media-breakpoint-down(md) {
      left: 60px;
    }

    @include media-breakpoint-down(sm) {
      left: 40px;
    }

    @include media-breakpoint-down(xs) {
      left: 20px;
    }
  }

  &__breadcrumb {
    margin-left: 5px;
    padding-left: 10px;
    position: relative;
    font-family: Rubik;
    font-size: 16px;
    line-height: 12px;

    &-icon {
      margin-right: 8px;

      @include media-breakpoint-down(sm) {
        width: 20px;
        height: auto;
      }
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      width: 5px;
      height: 1px;
      background-color: $bg--white;
    }
  }

  &__breadcrumb:first-child {
    margin-left: 0;
    padding-left: 0;
    color: #C2C2C2;
    font-family: Rubik;
    font-size: 16px;
    line-height: 12px;

    &:before {
      content: "";
      position: absolute;
      width: 0px;
      height: 0px;
    }
  }
}

/*=====  End of Small Hero  ======*/