/*=============================================
=            hero            =
=============================================*/

.homepage-hero {
  position: relative;
  width: 100%;
  height: 669px;

  @include media-breakpoint-down(md) {
    height: 100%;
  }

  &__carousel {
    height: 669px;

    &:before {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(90deg,rgba(0,0,0,.75) 0,rgba(0,0,0,0) 80%);
      z-index: 1;
    }

    &-image {
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 669px;
      inset-inline-start: 0;
      inset-block-start: 0;
      object-fit: cover;
    }

    &-container {
      position: relative;
      height: 100%;
      padding: 20px;
    }

    .glide__slide {
      position: relative;
      height: 669px;
      width: 300px;
    }
  }

  &__inner {
    position: absolute;
    inset: 0;
    max-width: 1440px;
    padding-inline: 32px;
    margin: auto;
    
    @include media-breakpoint-down(md) {
      max-width: 100%;
      padding: 0 20px;
    }
  }
  
  &__content {
    color: $text--white;
    max-width: 720px;
    gap: 16px;
    z-index: 1;
  }

  &__title {
    font-size: 62px;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: -1.24px;
    text-transform: uppercase;
    position: relative;
    padding-top: 16px;

    @include media-breakpoint-down(md) {
      font-size: 32px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100px;
      height: 2px;
      background-color: $bg--secondary;
    }
  }

  &__subtitle {
    font-size: 22px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 10px;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    background-color: $bg--white;
    padding: 20px 50px;
    border-radius: 5px 5px 0px 0px;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.10);

    @include media-breakpoint-down(md) {
      flex-direction: column;
      position: relative;
    }

    button {
      padding: 14px 32px;
    }
  }

  &__selections {
    @include media-breakpoint-down(md) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 20px;
      column-gap: 40px;
      margin-bottom: 10px;
    }

    @include media-breakpoint-down(sm) {
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  &__selection {
    margin-right: 30px;
    padding-right: 30px;
    position: relative;

    @include media-breakpoint-down(md) {
      margin-right: 0px;
      padding-right: 0px;
      margin-bottom: 8px;
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      height: 100%;
      width: 1px;
      background-color: #DEDEDE;

      @include media-breakpoint-down(md) {
        width: 0px;
      }
    }

    select,
    option {
      width: 200px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.28px;
      color: #1A202C;
      font-family: inherit;
      transform: translateX(-3px);
    }
  }

  &__selection:last-child {
    margin-right: 0;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      width: 0px;
    }
  }

  &__label {
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
  }

  .link {
    color: $text--white;
  }
}

/*=====  End of hero  ======*/