/*=============================================
=            media-with-text            =
=============================================*/

.media-with-text {
    padding-inline: 35px;

    &--grey {
        background-color: $tertiary;
    }

    &__bottom {
        gap: 60px;

        @include media-breakpoint-down(md) {
            gap: 30px;
            flex-direction: column-reverse;
        }

        &--reversed {
            flex-direction: row-reverse;

            @include media-breakpoint-down(md) {
                flex-direction: column-reverse;
            }
        }

        &-left,
        &-right {
            height: 100%;
            flex: 1;
        }

        &-title {
            font-size: 22px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 1.09px;
            text-transform: uppercase;
            margin-block-end: 20px;
        }

        &-content {
            font-size: 15px;
            font-weight: 400;
            line-height: 26px;
            margin-block-end: 20px;
        }
    }
}

/*=====  media-with-text  ======*/
