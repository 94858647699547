.single-news {
    display: flex;
    flex-direction: row;
    gap: 180px;
    padding: 40px 50px;
    flex: 1;
    justify-content: space-between;
    margin: 0 auto;
    width: fit-content;

    &__right {
        width: 950px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        &-image {
            width: 100%;
            height: 480px;
            background-size: cover;
        }

        &-content {
            display: none;
            font-family: inherit;

            &.active {
                display: block;
            }

            .container {
                padding: 0px;
            }
        }
    }

    &__author {
        &-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 40px;
        }

        &-title {
            color: $bg--secondary;
            font-family: inherit;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
        }

        &-name {
            color: $bg--primary;
            font-family: inherit;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
        }

        &-date {
            color: #666;
            font-family: inherit;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
        }
    }

    &__left {
        display: flex;
        flex-direction: column;
        max-width: 360px;
        gap: 40px;

        &-title {
            color: #0B2653;
            font-family: inherit;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 26.4px;
            text-transform: capitalize;
        }
    }

    &__tabs {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        &-item {
            color: #1C1C1C;
            font-family: inherit;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px; 
            padding: 20px;
            width: 100%;
            background-color: #F7F8F9;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            will-change: scale;

            @include media-breakpoint-up(md) {
                &:hover {
                    background-color: #F4F4F4;
                    scale: 1.1;
                }
            }

            &.active {
                background-color: $bg--primary;
                color: #FFF;
                position: relative;

                &::before{
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 5px;
                    height: 50%;
                    background-color: $bg--secondary;
                    transform: translateY(-50%);
                }
            }
        }
    }

    &__share {
        &-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
        }

        &-caption {
            color: $bg--primary;
            font-family: inherit;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px; 
            text-transform: capitalize;
        }
    }

    &__social {
        &-wrapper {
            display: flex;
            flex-direction: row;
            gap: 8px;
        }

        &-button {
            width: 32px;
            height: 32px;
            cursor: pointer;
            transition: scale 0.3s ease-in-out;
            will-change: scale;

            &:hover {
                scale: 1.1;
            }
        }

        &-image {
            width: 100%;
            height: 100%;
        }
    }

    &__tags {
        gap: 24px;

        &-container {
            display: flex;
            flex-direction: row;
            gap: 8px;
            flex-wrap: wrap;
        }

        &-item {
            padding: 8px 10px;
            border-radius: 2px;
            border: 1px solid #E7EBEF;
            color: #51668A;
            font-family: inherit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px; 
            text-transform: capitalize;
        }
    }

    &__posts {
        gap: 24px;

        &-container {
            display: flex;
            flex-direction: column;
        }

        &-image {
            width: 80px;
            height: 80px;
        }

        &-item {
            gap: 12px;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            padding-block: 20px;

            &:first-child {
                padding-block-start: 0px;
            }

            &:last-child {
                padding-block-end: 0px;
            }

            &:not(:last-child)::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #E7EBEF;
            }
        }

        &-title {
            color: #0B2653;
            font-family: inherit;
            font-size: 19px;
            font-style: normal;
            font-weight: 700;
            line-height: 25px; 
            text-transform: capitalize;

            &:hover {
                color: $bg--secondary;
            }
        }

        &-date {
            color: #51668A;
            font-family: inherit;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 13px; 
        }

        &-text {
            &-container {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
        }
    }

    &__input {
        &-text {
            padding: 17px 20px;
            color: #51668A;
            font-family: inherit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-radius: 3px;
            border: 2px solid #E7EBEF;
            background: #F4F4F4;

            &::placeholder {
                color: #c4c4c4;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .single-news {
        flex-direction: column;
        gap: 40px;
        padding-inline: 20px;

        &__right {
            width: 100%;
            gap: 16px;

            &-image {
                height: 300px;
            }

            &-content {
                &.active {
                    padding: 0px;
                }
            }
        }

        &__left {
            max-width: 100%;
            gap: 30px;

            &-title {
                font-size: 20px;
                line-height: 22px;
            }
        }

        &__author {
            &-container {
                flex-direction: row;
                justify-content: space-between;
            }

            &-title {
                font-size: 14px;
                line-height: 16px;
            }

            &-name {
                font-size: 12px;
                line-height: 14px;
            }

            &-date {
                font-size: 12px;
                line-height: 14px;
            }
        }

        &__tabs {
            &-item {
                padding: 14px 15px;
                font-size: 14px;
                line-height: 19px;
            }
        }

        &__share {
            &-caption {
                font-size: 14px;
                line-height: 16px;
            }
        }

        &__tags {
            gap: 12px;

            &-item {
                padding: 4px 8px;
                font-size: 10px;
                line-height: 12px;
            }
        }

        &__posts {
            gap: 11px;

            &-image {
                width: 60px;
                height: 60px;
            }

            &-item {
                padding-block: 10px;
            }

            &-title {
                font-size: 16px;
                line-height: 18px;
            }

            &-date {
                font-size: 11px;
                line-height: 13px;
            }
        }
    }
}