/*=============================================
=            button            =
=============================================*/

.button {
  padding: 14px 20px;
  cursor: pointer;
  padding: 14px 20px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px; 
  gap: 8px;
  min-width: fit-content;

  @include transition; 

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover, &:focus {
      background-color: $bg--primary;
      color: $text--white;
    }
  }

  &--underline .button__label{
    text-decoration: underline;
  }

  &--primary {
    background-color: $bg--primary;
    color: $text--white;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }

    &:hover, &:focus {
      background-color: $bg--secondary;
      color: $text--white;
    }

    &-wide {
      background-color: $bg--primary;
      color: $text--white;
      padding: 14px 32px;
  
      &:hover, &:focus {
        background-color: $bg--secondary;
        color: $text--white;
      }
    }
  }

  &--secondary {
    background-color: $bg--secondary;
    color: $text--white;

    &:hover, &:focus {
      background-color: $bg--primary;
      color: $text--white;
    }

    &-wide {
      background-color: $bg--white;
      color: $text--primary;
      border: 2px solid $primary;
      padding: 14px 32px;
  
      &:hover, &:focus {
        color: $text--secondary;
        border-color: $secondary;
      }
    }

    &-large {
      background-color: $bg--secondary;
      color: $text--white;
      font-size: 16px;
      padding: 15px 50px;

      @include media-breakpoint-down(md) {
        font-size: 14px;
        padding: 10px 30px;
      }
  
      &:hover, &:focus {
        background-color: #F26101;
      }
    }
  }

  &--outline {
    background-color: $bg--white;
    color: $text--primary;
    border: 2px solid $primary;

    &:hover, &:focus {
      color: $text--secondary;
      border-color: $secondary;
    }

    &-wide {
      background-color: $bg--white;
      color: $text--primary;
      border: 1px solid $primary;
      padding: 14px 32px;
  
      &:hover, &:focus {
        color: $text--secondary;
        border-color: $secondary;
      }
    }
  }

  &--simple-primary {
    font-family: 'Inter', sans-serif;
    color: $text--primary;
    gap: 10px;
    padding: 12px 20px;

    &:hover, &:focus {
      color: $text--secondary;
    }
  }

  &--simple-secondary {
    font-family: 'Inter', sans-serif;
    color: $text--secondary;
    gap: 10px;
    padding: 12px 20px;

    &:hover, &:focus {
      color: $text--primary;
    }
  }
}

/*=====  End of button  ======*/
