$black: #000;
$white: #fff;
$light-grey: #F8F8F8;
$medium-grey: #DADADA;
$dark-grey: #2D2D2D;
$darker-grey: #2A2A2A;

// primary colours

$primary: #262C63;
$secondary: #FF5003;
$tertiary: #ECF2FE;
$deep-sapphire: #1E234F;
$celeste: #45A8DF;
$light-blue: #ECF2FE;

// secondary colours

$success-color: #3A9B39;
$warning-color: #EDAD31;
$error-color: #D32929;

// background colours

$bg--black: $black;
$bg--white: $white;

$bg--primary: $primary;
$bg--secondary: $secondary;
$bg--celeste: $celeste;

// text colours

$text--black: $black;
$text--white: $white;
$text--primary: $primary;
$text--secondary: $secondary;
$text--dark-blue: #0b2653;
$text--celeste: $celeste;