/*=============================================
=            footer            =
=============================================*/

.footer {
  &__top {
    background-color: $deep-sapphire;
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    padding: 77px 130px 77px 139px;
    min-height: 262px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding: 30px;
    }

    &-wrapper {
      max-width: $container-width--default;
      padding-inline: 110px;
      margin: 0 auto;

      @include media-breakpoint-down(lg) {
        padding-inline: 0;
      }
    }

    &-cta {
      position: relative;
      color: $light-grey;
      margin-inline-end: 20px;
      padding-inline-end: 20px;
      font-size: 24px;
      line-height: 36px;
      flex: 0 0 45%;

      &:before {
        content: url(/assets/img/images/footer-cta-bg-element.svg);
        position: absolute;
        inset-block-start: -77px;
        inset-inline-start: -95px;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &:after {
        content: url(/assets/img/images/footer-cta-bg-element.svg);
        position: absolute;
        inset-block-end: -77px;
        inset-inline-end: -95px;
        transform: rotate(180deg);

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      [dir="rtl"] & {
        &:before {
          transform: scaleX(-1);
        }

        &:after {
          transform: scaleY(-1);
        }
      }
    }

    &-title,
    &-content {
      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
    }

    &-title {
      font-weight: 700;
    }

    &-content {
      font-weight: 300;
      min-height: 72px;
    }

    &-icon {
      width: 24px;
      height: 18px;
    }

    &-email {
      height: 70px;
      position: relative;
      flex: 0 0 55%;
      padding: 10px 20px;
      border-radius: 5px;
      background-color: $white;
      z-index: $above;
      gap: 10px;

      @include media-breakpoint-down(md) {
        margin-top: 30px;
      }

      @include media-breakpoint-down(sm) {
        margin-top: 30px;
        flex-direction: column;
      }

      button {
        @include media-breakpoint-down(sm) {
          width: 100%;
          margin-top: 10px;
        }
      }
    }

    &-input {
      padding: 5px;
      width: 100%;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      @include media-breakpoint-down(md) {
        margin-inline-start: 50px;
        width: 90%;
      }
    }

    &-button {
      padding: 15px 30px;
    }

    &-response {
      &--success,
      &--failure {
        display: none;
      }

      &--success {
        color: $primary;
      }

      &--failure {
        color: $error-color;
      }
    }

    &-submitted {
      &--success,
      &--failure {
        .footer__top {
          &-input,
          &-button {
            display: none;
          }
        }
      }

      &--success {
        .footer__top {
          &-response {
            &--success {
              display: block;
            }
          }
        }
      }

      &--failure {
        .footer__top {
          &-response {
            &--failure {
              display: block;
            }
          }
        }
      }
    }
  }

  &__mid {
    font-size: 14px;
    font-weight: 300;
    padding: 30px 32px;
    row-gap: 50px;
    column-gap: 120px;
    min-height: 300px;
    width: 100%;
    background-color: $dark-grey;
    color: $white;

    @include media-breakpoint-up(xxxl) {
      justify-content: space-around;
    }

    &-wrapper {
      width: $container-width--default;
      padding-inline: 32px;
    }

    &-link {
      position: relative;
      will-change: transform;

      &:before {
        @include transition;
        will-change: opacity;
        opacity: 0;
        content: "";
        position: absolute;
        top: 0;
        left: -5px;
        width: 1px;
        height: 100%;
        background-color: $secondary;
      }

      &:hover,
      &:focus {
        transform: translateX(5px);

        &:before {
          opacity: 1;
        }
      }
    }

    &-info {
      gap: 16px;
      max-width: 272px;
    }

    &-logo {
      width: 150px;
    }

    &-icons {
      gap: 13px;
    }

    &-icon {
      @include transition;

      &:hover,
      &:focus {
        scale: 1.1;
      }
    }

    &-columns {
      gap: 8px;
    }

    &-column-title {
      margin-bottom: 15px;
      padding-inline-end: 20px;
      font-weight: 400;
    }
  }

  &__bottom {
    min-height: 46px;
    background-color: $darker-grey;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    &-link,
    &-copyright {
      height: 25px;
      padding: 0 14px;
      font-size: 12px;
      font-weight: 300;
      line-height: 25px;
      letter-spacing: -0.352px;
      color: $white;
      border-inline-end: 1px solid $white;

      @include media-breakpoint-down(md) {
        border-inline-end: none;
      }

      &:last-child {
        border-inline-end: none;
      }
    }

    &-copyright {
      &--desktop {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &--mobile {
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }

    &-link {

      &:hover,
      &:focus {
        color: rgba(#fff, .8);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .footer {
    &__top {
      &-wrapper {
        padding: 0;
        flex-direction: column;
      }

      &-email,
      &-cta {
        width: 100%;
      }

      &-cta {
        margin-inline: 0;
        padding-inline: 0;
      }
    }

    &__mid {
      &-info {
        display: flex;
        flex-direction: column;
      }

      &-logo,
      &-icons {
        align-self: center;
      }

      &-columns {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-column {
        &-title {
          margin-block: 0;
          padding: 0;
        }
      }

      &-wrapper {
        flex-wrap: unset;
        flex-direction: column;
        align-items: center;
        gap: 25px;

        > div:not(:first-child):not(:only-child) {
          width: 70%;
        }

        > *:not(:last-child) {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -12px;
            width: 100%;
            height: 0.5px;
            background-color: rgba(#E7EAEE, .7);
          }
        }
      }
    }
  }
}

/*=====  End of header  ======*/