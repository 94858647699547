html,
body {
    font-family: $font-family--base;
    font-weight: 400;
    overflow-x: hidden;
    color: $text--black;
}

.container {
    margin: 0 auto;
    padding: 50px 32px;
    max-width: $container-width--default;

    &--full {
        width: 100%;
        margin: 0 auto;
    }

    &--padding-left {
        padding: 0 0 0 32px;
        margin: 0 auto;
        max-width: $container-width--default;
    }

    &--awkward {
        margin: 0 auto;
        max-width: $container-width--default;
        padding: 0 200px;

        @include media-breakpoint-down(lg) {
            padding: 0 16px;
        }
    }

    &--has-margin {
        padding-top: 60px;
        padding-bottom: 60px;

        @include media-breakpoint-down(md) {
            padding-bottom: 30px;
            padding-top: 30px;
        }
    }
}

.hidden {
    display: none;
    visibility: hidden;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}
