/*=============================================
=            CTA Panel            =
=============================================*/

.cta-panels {
  margin: 50px auto;
  margin-block-end: 150px;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }

  &__left, &__right {
    flex: 1;
  }

  &__inner {
    @include media-breakpoint-down(lg) {
      flex-direction: column-reverse;
    }
  }

  &__right {
    padding: 100px 59px 100px 100px;
    box-sizing: border-box;
    flex: 0.776;
    gap: 30px;

    @include media-breakpoint-down(lg) {
      padding: 32px;
    }
  }

  &__left {
    padding-inline: 32px;
    flex: 1;
    position: relative;
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
  }

  &__horizontal-line {
    position: absolute;
    inset-inline-start: 0;
    inset-block-end: -20px;
    width: 69px;
    height: 1px;
    background-color: $secondary;
  }

  &__footer {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
  }

  &__stats {
    position: relative;
    gap: 5px;
    padding-block-end: 10px;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__stat-value {
    color: $text--secondary;
    font-size: 60px;
    font-weight: 700;
    line-height: 64px; 
  }

  &__stat-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
  }

  &__cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    grid-gap: 56px;

    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
  }

  &__card {
    width: 100%;
    max-width: 350px;
    min-height: 328px;
    padding: 40px;
    padding-inline-end: 56px;
    padding-block-end: 16px;
    color: $primary;
    background-color: $tertiary;
    justify-self: end;

    @include media-breakpoint-down(lg) {
      justify-self: center;
    }

    @include media-breakpoint-down(sm) {
      top: 0px;
    }

    &-icon {
      position: absolute;
      inset-block-start: 50%;
      inset-inline-start: 50%;
      transform: translate(-50%, -50%);
      max-width: 30px;
      max-height: 30px;

      &-container {
        position: relative;
        width: 56px;
        height: 56px;
        background-color: $primary;
        border-radius: 50px;
      }
    }

    &-title {
      font-size: 24px;
      font-weight: 700;
      margin-block-start: 24px;
      margin-block-end: 16px;
    }

    &-content {
      max-width: 240px;
      font-size: 14px;
      line-height: 150%;
      font-weight: 300;
    }
  }

  &__card:first-child {
    background-color: $primary;
    color: $white;

    .cta-panels__card-icon-container {
      background-color: $tertiary;
    }
  }

  &__card:nth-of-type(odd) {
    justify-self: start;
    top: 104px;
    position: relative;

    @include media-breakpoint-down(lg) {
      justify-self: center;
    }

    @include media-breakpoint-down(sm) {
      top: 0px;
    }
  }
}

/*=====  End of CTA Panel  ======*/
