/*=============================================
=            Product Card Grid            =
=============================================*/

.product-grid {
  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 17px;
  }

  &__subtitle {
    color: #282E39;
    font-family: inherit;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 5px;

    > *:not(:last-child) {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -12px;
        width: 100%;
        height: 0.5px;
        background-color: #E7EAEE;
      }
    }

    &-container {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-inline: 13px;
    }

    &-key {
      color: #000;
      font-family: inherit;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      text-transform: capitalize;
      flex-shrink: 0;
      white-space: nowrap;
    }

    &-value {
      color: #000;
      font-family: inherit;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;
      text-align: right;
    }
  }

  &__header {
    margin-bottom: 42px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    &-right {
      gap: 30px;
      text-transform: uppercase;

      @include media-breakpoint-down(md) {
        margin-block-start: 10px;
      }

      .link {
        font-size: 16px;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.32px;
        padding: 15px 30px;

        &__label {
          padding-inline: 28px;
        }
      }
    }

    &-left {
      max-width: 629px;
    }
  }

  &__title {
    color: $text--black;
    font-size: 39px;
    font-weight: 700;
    line-height: 200%;
    letter-spacing: -0.78px;

    @include media-breakpoint-down(md) {
      line-height: 100%;
      margin-block-end: 10px;
    }

    &-highlight {
      color: $text--primary;
    }
  }

  &__item {
    padding: 22px;
    border: 1px solid #F2F2F2;
    gap: 12px;
    background-color: $bg--white;

    &-image {
      height: 196px;
      width: 80%;
      object-fit: contain;

      &-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-title {
      text-transform: capitalize;

      &-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }

    &-model {
      font-weight: 700;
      text-transform: uppercase;
      color: $text--primary;
    }
  }

  &__buttons {
    gap: 11px;
    margin-top: auto;

    .link {
      width: 100%;
      font-size: 14px;
    }
  }
}

/*=====  End of Product Card Grid  ======*/