.mobile-menu {
    background-color: $bg--white;
    position: relative;

    &__logo {
        max-width: 88px;
        max-height: 41px;

        &-link {
            &--no-press {
                pointer-events: none;
            }
        }
    }

    &__header {
        padding: 16px 26px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;

        &-right {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 22px;
        }

        &-text {
            &-container {
                cursor: pointer;
                color: $bg--primary;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-family: inherit;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                color: $bg--primary;
                gap: 6px;

                transition: color 0.3s ease;

                &:hover {
                    color: $text--secondary;
                }

                .zmdi {
                    font-size: 23px;
                }
            }
        }
    }

    &__items {
        &-container {
            display: none;
            flex-direction: column;
            gap: 16px;

            &--active {
                display: flex;
            }

            > *:not(:last-child) {
                border-bottom: 0.5px solid #E7E7E7;
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 52px;
        align-items: center;
        padding: 16px 10px;
        cursor: pointer;

        color: $bg--primary;
        font-family: inherit;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;

        @include transition;

        .zmdi {
            color: $bg--primary;
        }

        &-image {
            max-width: 90px;
            max-height: 100%;
            width: auto;
            object-fit: contain;
        }

        &:hover {
            color: $text--secondary;

            .zmdi {
                color: $text--secondary;
            }
        }
    }

    &__overlay {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        transform: translateX(100%);
        background-color: $white;
        top: 80px;
        width: 100%;
        height: calc(100vh - 80px);
        z-index: 3;
        transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
        border-top: 3px solid $bg--primary;
    
        &--active {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }

        &-contact {
            font-family: inherit;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            
            &-container {
                position: absolute;
                bottom: 0;
                padding-block: 17px;
                width: 100%;
                background-color: $bg--primary;
                color: $bg--white;
                left: 0;
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: center;
                justify-content: center;
                transition: color 0.3s ease;

                &:hover {
                    color: $text--secondary;
                }

                .zmdi {
                    font-size: 23px;
                }
            }
        }

        &-wrapper {
            padding: 17px 21px;
            max-height: 93.5%;
            overflow-y: auto;
            
            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $bg--primary;
                border-radius: 10px;
            }
        }
    }

    &__language {
        cursor: pointer;

        @include transition;

        &-upper {
            display: flex;
            flex-direction: row;
            gap: 6px;
            position: relative;
            align-items: flex-end;

            &-text {
                color: #000;
                font-family: inherit;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
            }
        }

        &-overlay {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            transform: translateX(100%);
            background-color: $white;
            top: 80px;
            width: 100%;
            height: calc(100vh - 80px);
            z-index: 3;
            transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
            border-top: 3px solid $bg--primary;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 17px 21px;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $bg--primary;
                border-radius: 10px;
            }

            > *:not(:last-child) {
                border-bottom: 0.5px solid #E7E7E7;
            }
        
            &--active {
                opacity: 1;
                visibility: visible;
                transform: translateX(0);
            }

            &-link {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &__icon {
        width: 24px;
        height: 20px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;

        &-bar {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: black;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
        }

        &-bar:nth-child(1) {
            top: 0px;
            right: 0;
            left: auto;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
        }

        &-bar:nth-child(2) {
            top: 9px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
        }

        &-bar:nth-child(3) {
            top: 18px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
        }

        &--open .mobile-menu__icon-bar:nth-child(1) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            top: 1px;
            left: 8px;
            right: auto;
            width: 100%;
            height: 2px;
            background-color: $bg--primary;
        }

        &--open .mobile-menu__icon-bar:nth-child(2) {
            width: 0%;
            opacity: 0;
        }

        &--open .mobile-menu__icon-bar:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 18px;
            left: 8px;
            height: 2px;
            background-color: $bg--primary;
        }
    }
}