/*=============================================
=                CTA Cards                =
=============================================*/

.cta-cards {
    &-background {
        background-color: #f7f8f9;
    }
    
    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
        column-gap: 50px;
        row-gap: 40px;

        @include media-breakpoint-down(sm) {
            grid-template-columns: initial;
        }
    }

    &__header {
        margin-bottom: 56px;
    }

    &__title {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.704px;
        text-transform: capitalize;
        margin-block-end: 10px;
    }

    &__content {
        font-size: 18px;
        font-weight: 400;
        line-height: 160%;
        max-width: 880px;
        letter-spacing: -0.396px;
    }

    &__item {
        background-color: $white;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        gap: 22px;
        padding: 20px;

        &-image {
            width: 385px;
            height: 215px;
            object-fit: contain;

            &-container {
                max-height: 300px;
            }
        }

        &-text {
            &-title {
                color: $black;
                text-align: center;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
            }

            &-content {
                color: #3a3a3a;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 27px;
            }
        }

        &-link {
            margin-top: auto;
            color: $primary;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 24px */
            letter-spacing: -0.32px;
            text-decoration-line: underline;
        }
    }
}

@include media-breakpoint-down(md) {
    .cta-cards {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__title,
        &__content {
            text-align: center;
        }

        &__item {
            &-image {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }
    }
}

/*=====  End of CTA Cards  ======*/
