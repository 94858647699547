.industry-sidebar {
    display: flex;
    flex-direction: row;
    gap: 180px;
    padding-block: 40px;
    max-width: $container-width--default;
    justify-content: space-between;
    margin: 0 auto;

    &__left {
        line-height: 24px;

        .container{
            padding: 0;
        }

        &-content {
            display: none;

            &.active {
                display: block;
            }
        }

        &-title {
            color: #222;
            font-family: inherit;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px; 
            text-transform: capitalize;
        }

        &-image {
            width: 100%;
            height: 100%;
            background-size: cover;

            &-container {
                position: relative;
                width: 100%;
                height: 420px;
                margin-block: 20px;
            }
        }

        &-icon {
            transform: rotate(-45deg);
            max-width: 100%;
            max-height: 100%;

            &-container {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 85px;
                height: 85px;
                background-color: $bg--secondary;
                transform: rotate(45deg);
                right: 10%;
                top: -10%;
            }
        }
    }

    &__right {
        min-width: 320px;
        background-color: #F5F5F5;
        padding-block-start: 48px;
        padding-inline: 27px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        height: fit-content;
    }

    &__column {
        display: flex;
        flex-direction: column;
        gap: 30px;

        &-icon{
            &-container{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
            }
        }

        &-title {
            color: #222;
            font-family: inherit;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px; 
            text-transform: capitalize;
        }

        &-link {
            &-item {
                display: flex;
                flex-direction: row;
                gap: 12px;
                background-color: #FFF;
                padding: 12px 18px;
                padding-inline-end: 5px;
                margin-bottom: 20px;
                transition: all 0.3s ease;
                cursor: pointer;
                will-change: transform;
                backface-visibility: hidden;

                &:hover {
                    transform: scale(1.048);
                    transform-origin: 100% 0;
                }
            }

            &-name {
                color: #222;
                font-family: inherit;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; 
            }

            &-size {
                color: #222;
                font-family: inherit;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; 
            }

            &-image {
                width: 35px;
                height: 35px;
            }
        }

        &-item {
            position: relative;

            &.line-break {
                padding-block: 15px;
                border-top: 1px solid #E9E9E9;
                border-bottom: 1px solid #E9E9E9;
            };

            &.active {
                .industry-sidebar__column-item-name-hover {
                    color: $bg--secondary;
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: -27px;
                    width: 2px;
                    height: 100%;
                    background-color: $bg--secondary;
                }
            }

            &:before {
                content: ""; 
            }

            &-container {
                display: flex;
                flex-direction: column;
            }

            &-icon {
                width: 30px;
                height: 30px;
            }

            &-name{
                color: #222;
                font-family: inherit;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; 
                text-transform: capitalize;

                &-hover {
                    transition: color 0.2s ease;
                    cursor: pointer;

                    &:hover {
                        color: $bg--secondary;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .industry-sidebar {
        flex-direction: column;
        flex-direction: column-reverse;
        gap: 30px;

        .container {
            padding: 0;
        }

        &__left {
            flex-basis: 100%;
            
            &-image {
                &-container {
                    height: 300px;
                    margin-block: 35px;
                }
            }

            &-icon {
                &-container {
                    width: 55px;
                    height: 55px;
                    top: -9%;
                }
            }

            &-title {
                font-size: 24px;
            }
        }

        &__right {
            flex-basis: 100%;
            padding-inline: 15px;
            padding-block-start: 20px;
            gap: 20px;
        }

        &__column {
            gap: 15px;

            &-title {
                font-size: 16px;
            }

            &-link {
                &-item {
                    padding: 10px 12px;
                    margin-bottom: 15px;
                }

                &-name,
                &-size {
                    font-size: 14px;
                    line-height: 18px;
                }

                &-image {
                    width: 30px;
                    height: 30px;
                }
            }

            &-item {
                &.active {
                    &:before {
                        left: -14px;
                    }
                }

                &-icon {
                    width: 25px;
                    height: 25px;
                }

                &-name {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }
}
