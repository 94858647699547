.services-page-hero {
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
    width: 100%;
    position: relative;
    color: #FFF;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(15, 54, 123, 0.56) 0%, rgba(38, 44, 99, 0.56) 100%);
        height: 100%;
        width: 100%;
        z-index: 0;
    }

    &__inner {
        max-width: 830px;
        padding-block-start: 100px;
        margin-left: 90px;
        z-index: $above;

        @include media-breakpoint-down(sm) {
            margin-left: 0;
            padding-block-start: 50px;
        }
    }

    &__title {
        color: #FFF;
        font-family: inherit;
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: 57px;
        text-transform: capitalize;
        margin-bottom: 14px;

        @include media-breakpoint-down(sm) {
            font-size: 25px;
            line-height: 28px;
            text-align: center;
        }
    }

    &__content {
        font-family: inherit;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
            text-align: center;
        }
    }

    .hero__breadcrumbs {
        left: 85px;
    }
}