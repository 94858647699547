/*=============================================
=            detailed Card Grid            =
=============================================*/

.detailed-grid {
    font-family: inherit;

    &.container {
        padding-block: 0px 37px;
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    &__header {
        margin-block: 56px;

        &:empty {
            margin-block: 0;
        }

        &--left {
            align-items: flex-start;
            gap: 10px;

            .detailed-grid__content {
                max-width: unset;
            }
        }
    }

    &__title {
        font-size: 36px;
        font-weight: 700;
        line-height: 40px;
        max-width: 880px;
    }

    &__subtitle {
        position: relative;
        color: $secondary;
        max-width: 612px;
        font-size: 18px;
        font-weight: 700;
        line-height: 14px;
        margin: 24px 0;

        &:before,
        &:after {
            content: '';
            position: absolute;
            inset-block-start: 50%;
            width: 50%;
            border-bottom: 2px solid $secondary;
        }

        &:before {
            inset-inline-start: -15px;
            margin-inline-start: -50%;
        }

        &:after {
            inset-inline-end: -15px;
            margin-inline-end: -50%;
        }
    }

    &__content {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        max-width: 880px;
        text-align: center;

        @include media-breakpoint-down(sm) {
          text-align: start;
      }
    }

    &__item {
        flex: 0 0 calc(100% / 3 - 16px);
        box-shadow: 0px 3px 7px 0px rgba(218, 218, 218, 0.7);

        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
        }

        @include transition;

        &:hover {
          box-shadow: 0px 6px 7px 3px rgba(218, 218, 218, 0.6);
        }

        &-body {
            padding: 32px 20px;
            height: 100%;
        }

        &-icon {
            max-width: 60px;
            max-height: 50px;
            padding-inline-end: 10px;
            margin-inline-end: 10px;
            border-inline-end: 2px solid $secondary;
        }

        &-image {
            width: 100%;
            height: 100%;
            object-fit: cover;

            &-container {
                min-height: 284px;
                max-height: 284px;
            }
        }

        &-text {
            &-title {
                color: #222;
                font-family: inherit;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                margin-block-end: 10px;
            }

            &-content {
                color: #848484;
                font-family: inherit;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            &-container {
                margin-inline-start: 10px;
                max-width: 225px;
            }
        }
    }
}

/*=====  End of detailed Card Grid  ======*/
