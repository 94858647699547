.brand-categories {
    padding-block: 60px;
    padding-inline-start: 55px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include media-breakpoint-up(desktop) {
        justify-content: space-evenly;
    }

    &__left {
        max-width: 555px;

        &-upper {
            display: flex;
            flex-direction: column;
            gap: 27px;
            padding-block-end: 36px;
        }

        &-lower {
            display: grid;
            gap: 11px;
            grid-template-columns: repeat(4, 1fr);
        }

        &-title, &-content {
            max-width: 378px;
        }

        &-title {
            color: #000;
            font-family: inherit;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            text-transform: uppercase;
        }

        &-content {
            color: #000;
            font-family: inherit;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 22px; 
        }
    }

    &__right {
        flex: 1;

        @include media-breakpoint-up(desktop) {
            flex: none;
            position: relative;
        }
    }

    &__item {
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
        gap: 10px; 
        box-shadow: 10px 0px 90px 0px rgba(0, 0, 0, 0.10);
        align-items: center;
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
        will-change: transform;
        backface-visibility: hidden;

        &-image {
            height: 60px;
            width: 60px;
            pointer-events: none;
        }

        &-name {
            color: #000;
            text-align: center;
            font-family: inherit;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; 
            pointer-events: none;
        }

        &:hover {
            transform: scale(1.09);
        }

        &.active {
            background: #262C63;
            .brand-categories__item-name {
                color: #FFF;
            }
        }
    }

    &__image {
        height: 540px;
        width: 630px;
        position: relative;
        bottom: -100px;
        margin-left: auto;
        margin-right: 120px;
    
        @include media-breakpoint-down(md) {
            height: 480px;
            width: 560px;
        }
    
        @include media-breakpoint-down(sm) {
            height: 420px;
            width: 490px;
        }
    
        &-mask {
            position: absolute;
            right: 0;
            height: 800px;
            width: 700px;
            z-index: -1;
            margin-top: -120px;
    
            @include media-breakpoint-down(lg) {
                height: 720px;
                width: 630px;
                margin-top: -108px;
            }
    
            @include media-breakpoint-down(md) {
                height: 640px;
                width: 560px;
                margin-top: -96px;
            }
    
            @include media-breakpoint-down(sm) {
                height: 560px;
                width: 490px;
                margin-top: -84px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .brand-categories {
        padding-block: 40px;
        padding-inline: 20px;
        gap: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__left {
            &-upper {
                gap: 20px;
                padding-block-end: 20px;
            }

            &-lower {
                gap: 10px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
            }

            &-title, &-content {
                width: 100%;
            }

            &-title {
                font-size: 24px;
            }

            &-content {
                font-size: 14px;
            }
        }

        &__image {
            height: 375px;
            width: 100%;
            position: static;

            &-mask {
                display: none;
            }
        }
    }
}