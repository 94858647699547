/*=============================================
=            Focus Card Grid            =
=============================================*/

.focus-grid {
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 302px;
    grid-auto-flow: dense;
    grid-gap: 16px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
      max-height: 100%;
      overflow: auto;
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }
  }

  &__header {
    margin-bottom: 42px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    &-right {
      .link {
        font-size: 16px;
      }
    }
  }

  &__title {
    color: $text--black;
    font-size: 39px;
    font-weight: 700;
    line-height: 200%;
    letter-spacing: -0.858px;

    @include media-breakpoint-down(md) {
      line-height: 100%;
      margin-block-end: 10px;
    }

    &-highlight {
      color: $text--primary;
    }
  }

  .link--simple-primary {
    @include media-breakpoint-down(md) {
      padding: 10px 0;
    }
  }

  &__image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  &__item {
    position: relative;

    &:hover .focus-grid__overlay {
      opacity: 1;
      visibility: visible;
    }

    &--small,
    &--large {
      display: flex;
      height: 100%;
      width: 100%;
      grid-column: auto / span 1;
    }

    &--small {
      grid-row: span 1;
    }

    &--large {
      grid-row: span 2;

      @include media-breakpoint-down(sm) {
        grid-row: span 1;
      }
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: $text--white;
    background-color: rgba(38, 44, 99, 0.7);
    padding: 80px;
    opacity: 0;

    @include transition;

    @include media-breakpoint-down(md) {
      opacity: 1;
      visibility: visible;
    }

    &:focus-within {
      opacity: 1;
      visibility: visible;
    }

    .link {
      font-size: 16px;
      z-index: 2;

      &:hover,
      &:focus {
        color: $text--white;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      gap: 9px;
    }

    .link {
      padding: 0px;
    }

    &-link, 
    &-title,
    &-text {
      text-align: center;
    }

    &-link {
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &-title {
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

/*=====  End of Focus Card Grid  ======*/