.product-listing {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: $container-width--default;
    background-color: transparent;
    padding-block: 40px;

    &--wrapper {
        background-color: $light-grey;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    &__left {
        width: 332px;
        display: flex;
        flex-direction: column;
        gap: 28px;

        &-image {
            width: 100%;
            max-height: 376px;
        }
    }

    &__select {
        padding: 4px 15px;
        border-radius: 3px;
        border: 1px solid #9F9F9F;

        &-container {
            display: flex;
            flex-direction: row;
            gap: 6px;
        }
    }

    &__pagination {
        &-container {
            display: flex;
            flex-direction: row;
            gap: 5px;
        }

        &-button {
            border-radius: 2px;
            background: #F1F1F1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px 8px;
            will-change: background-color, scale, color;
            font-size: 16px;
            cursor: pointer;

            @include transition;

            &:hover {
                background-color: $bg--primary;
                color: $bg--white;
                scale: 1.05;
            }

            &.active {
                background-color: $bg--primary;
                color: $bg--white;
            }
        }
    }

    &__details {
        padding: 17px 21px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: $bg--white;

        &-right {
            display: flex;
            flex-direction: row;
            gap: 35px;
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
            gap: 20px;
            align-items: center;
        }
    }

    &--no-results-image {
        display: none;
        width: 100%;
        height: 100%;
        max-width: 700px;
        max-height: 700px;
        object-fit: contain;
    }

    &__right {
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        position: relative;

        &--loading {
            height: 100vh;
            justify-content: space-between;

            .product-listing {
                &__grid,
                &__details:last-child {
                    display: none;
                }
            }

            .loader {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
            }
        }

        &--no-results {
            height: 100vh;
            width: 100%;
            display: flex;
            align-items: center;
            
            .product-listing {
                &__grid,
                &__details {
                    display: none;
                }

                &--no-results-image {
                    display: block;
                }
            }
        }
    }

    &__filter {
        &-key {
            color: #000;
            font-family: inherit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            display: flex;
            flex-direction: row;
            gap: 5px;
            cursor: pointer;

            @include transition;

            &.disabled {
                color: #9F9F9F;
                cursor: not-allowed;

                &:hover {
                    color: #9F9F9F;
                }
            }

            &.active {
                color: $text--primary;
                font-weight: 700;
            }

            &:hover {
                color: $text--primary;
                font-weight: 700;
            }
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 17px;
    }

    &__checkbox {
        width: 20px;
        height: 20px;
        background-color: $light-grey;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        -webkit-appearance: none;

        &:checked {
            background-color: $bg--primary;

            &::after {
                content: '✔';
                color: $bg--white;
                font-size: 14px;
            }
        }

        &-label {
            color: #000;
            font-family: inherit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            max-width: 82%;
            cursor: pointer;

            @include transition;

            label {
                cursor: pointer;
            }

            &:hover {
                color: $text--primary;
                font-weight: 700;
            }
        }

        &-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__accordion {
        &.active {
            .product-listing__accordion-lower {
                display: flex;
            }
        }

        &--loading {
            .product-listing__checkbox-container {
                cursor: not-allowed;

                * {
                    cursor: not-allowed;
                }
            }
        }

        &-title {
            color: #333;
            font-family: inherit;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }

        &-upper {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 11px 14px;
            background-color: $light-grey;
            cursor: pointer;
        }

        &-lower {
            display: none;
            padding: 11px 14px;
            display: none;
            flex-direction: column;
            gap: 18px;
            max-height: 232px;
            overflow: auto;
            background-color: $bg--white;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $bg--primary;
                border-radius: 10px;
            }

            &.active {
                display: flex;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .product-listing {
        width: 100%;
        padding-block: 20px;
        flex-direction: column;
        gap: 20px;

        &__left {
            width: 100%;
            flex-direction: column;
            gap: 20px;

            &-image {
                max-width: 400px;
                max-height: 376px;

                &-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    display: none;
                }
            }
        }

        &__details {
            flex-direction: column;
            gap: 20px;
        }

        &__right {
            width: 100%;
        }
    }
}

.loader {
    display: none;
    border: 8px solid rgba($bg--secondary, 0.3);
    border-top: 8px solid $bg--primary;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}