.location-item {
    z-index: 1;
    position: relative;

    #map {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        overflow: hidden;
    }

    &-inner {
        padding: 64px 167px;
    }

    &__left {
        flex: 1;
        min-height: 600px;

        &-title {
            font-family: inherit;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            letter-spacing: -0.72px;
        }

        &-content {
            color: #282E39;
            font-family: inherit;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
        }

        &-region {
            padding-block: 40px;

            &-label {
                color: #282E39;
                font-family: inherit;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
            }

            &-dropdown {
                color: $bg--secondary;
                font-family: inherit;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
            }
        }

        &-branch {
            &-container {
                display: flex;
                flex-wrap: wrap;
                row-gap: 12px;
            }

            &-column {
                flex-basis: 47%;
                padding: 17px 31px;
                background: #FFF;
                position: relative;
                pointer-events: all;
                margin-inline-end: 10px;
                display: none;
                cursor: pointer;

                &:hover::before,
                &.active::before {
                    width: 100%;
                }

                &:before {
                    content: "";
                    transition: all 0.5s ease;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 1%;
                    background-color: $bg--secondary;
                    pointer-events: none;
                }

                &-name {
                    color: var(--text-title-text, #333);
                    font-variant-numeric: lining-nums proportional-nums;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    position: relative;
                    z-index: 1;
                }
            }
        }
    }

    &__right {
        flex: 1;
    }
}

@include media-breakpoint-down(sm) {
    .location-item {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        .gm-style-iw {
            max-width: 370px !important;
            max-height: 280px !important;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 2px;
            }
    
            &::-webkit-scrollbar-thumb {
                background-color: $bg--secondary;
                border-radius: 10px;
            }
        }

        #map {
            position: relative;
            width: 100%;
            height: 400px;
        }

        &-inner {
            background-color: $light-grey;
            padding: 64px 20px;
        }

        &__right {
            display: none;
        }

        &__left {
            text-align: center;
            height: fit-content;
            min-height: unset;

            &-title {
                font-size: 24px;
            }

            &-content {
                font-size: 16px;
            }

            &-region {
                align-self: center;

                &-label {
                    font-size: 16px;
                }

                &-dropdown {
                    font-size: 16px;
                }
            }

            &-branch {
                &-container {
                    display: flex;
                    flex-wrap: wrap;

                    &-column {
                        flex-basis: calc(50% - 5px);
                        margin-inline-end: 0;
                        margin-block-end: 10px;
                    }
                }
            }
        }
    }
}