@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Heebo&family=Inter&family=Plus+Jakarta+Sans:wght@400;500;700&family=Rajdhani:wght@300;400;700&family=Roboto:wght@300;400;500;700&family=Rubik:wght@300;400;500;700&display=swap");
#embedContainer {
  max-width: 1440px !important;
  margin: 0 auto !important;
  width: 100% !important;
  padding: 60px !important;
}

.fade-in {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-out {
  animation: fadeOut 1s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
html,
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  color: #000;
}

.container {
  margin: 0 auto;
  padding: 50px 32px;
  max-width: 1440px;
}
.container--full {
  width: 100%;
  margin: 0 auto;
}
.container--padding-left {
  padding: 0 0 0 32px;
  margin: 0 auto;
  max-width: 1440px;
}
.container--awkward {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 200px;
}
@media (max-width: 1199px) {
  .container--awkward {
    padding: 0 16px;
  }
}
.container--has-margin {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width: 991px) {
  .container--has-margin {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}

.hidden {
  display: none;
  visibility: hidden;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.flex {
  display: flex;
  flex-wrap: nowrap;
}
.flex--wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex--justify-space-around {
  justify-content: space-around;
}
.flex--justify-between {
  justify-content: space-between;
}
.flex--justify-center {
  justify-content: center;
}
.flex--justify-end {
  justify-content: flex-end;
}
.flex--align-center {
  align-items: center;
}
.flex--align-start {
  align-items: flex-start;
}
.flex--row {
  flex-direction: row;
}
.flex--column {
  flex-direction: column;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: currentColor;
  border: none;
  background: none;
}
*:hover,
*::before:hover,
*::after:hover {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

address {
  font-style: normal;
}

a {
  text-decoration: none;
  opacity: 1;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
a:hover, a:focus {
  color: currentColor;
  opacity: 1;
}

img {
  display: block;
  width: 100%;
}
img.inline {
  width: unset;
}

@media (max-width: 767px) {
  .hidden--sm-down {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .hidden--md-down {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .hidden--lg-up {
    display: none !important;
  }
}
.hidden-element {
  display: none;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

/*=============================================
=            Banner Carousel            =
=============================================*/
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.banner-carousel__image {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 480px;
  inset-inline-start: 0;
  inset-block-start: 0;
  object-fit: cover;
}
.banner-carousel__content {
  max-width: 860px;
  z-index: 1;
  color: #fff;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  padding-block-end: 30px;
}
.banner-carousel-container {
  position: relative;
  height: 100%;
  padding: 20px;
  background-color: rgba(38, 44, 99, 0.7);
}
.banner-carousel .glide__slide {
  position: relative;
  height: 480px;
}
.banner .glide__bullets {
  position: absolute;
  inset-block-end: 28%;
  inset-inline-start: 50%;
  transform: translate(-50%, 100%);
  width: fit-content;
  height: fit-content;
  z-index: 1;
  display: flex;
  justify-content: center;
  list-style-type: none;
}
@media (max-width: 767px) {
  .banner .glide__bullets {
    inset-block-end: 10%;
  }
}
.banner .glide__bullets button {
  display: block;
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  border-radius: 100%;
}
.banner .glide__bullets .glide__bullet {
  position: relative;
  margin-inline-end: 20px;
  cursor: pointer;
  color: #fff;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  background-color: transparent;
  box-shadow: none;
}
.banner .glide__bullets .glide__bullet:not(:last-child):after {
  content: "";
  position: absolute;
  height: 1px;
  width: 6px;
  background-color: #fff;
  inset-inline-end: -14px;
  inset-block-start: 50%;
}
.banner .glide__bullets .glide__bullet--active {
  background-color: #FF5003;
}

/*=====  End of Banner Carousel  ======*/
/*=============================================
=            Brand Carousel            =
=============================================*/
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.brands__header {
  margin-bottom: 50px;
}
@media (max-width: 991px) {
  .brands__header {
    flex-direction: column;
  }
}
.brands__header-right .link {
  font-size: 16px;
  line-height: 24px;
}
.brands__title {
  color: #000;
  font-size: 39px;
  font-weight: 700;
  line-height: 200%;
  letter-spacing: -0.858px;
}
.brands__title-highlight {
  color: #262C63;
}
.brands__subtitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
  letter-spacing: -0.396px;
}
@media (max-width: 991px) {
  .brands .link--simple-primary {
    padding: 10px 0;
  }
}
.brands-carousel {
  cursor: pointer;
  margin-bottom: 0 !important;
}
.brands-carousel__image {
  object-fit: contain;
  max-height: 79px;
}
.brands-carousel .glide__slide {
  opacity: 0.5;
  filter: grayscale(1);
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479px) {
  .brands-carousel .glide__slide {
    filter: none;
    opacity: 1;
  }
}
.brands-carousel .glide__slide:hover, .brands-carousel .glide__slide:focus {
  filter: none;
  opacity: 1;
}
.brands-carousel .glide__bullets {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  list-style-type: none;
  transform: translateX(0);
  left: 0;
}
.brands-carousel .glide__bullets button {
  display: block;
  width: 8px;
  height: 8px;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #ccc;
  text-indent: -9999px;
}
.brands-carousel .glide__bullets .glide__bullet--active {
  background-color: #262C63;
  width: 40px;
  border-radius: 10px;
}
.brands-carousel .glide__bullets .glide__bullet {
  margin-inline-end: 7px;
  cursor: pointer;
  display: none;
}
@media (min-width: 1301px) and (max-width: 4000px) {
  .brands-carousel .glide__bullet:nth-child(6n+1) {
    display: inline;
  }
}
@media (min-width: 1111px) and (max-width: 1300px) {
  .brands-carousel .glide__bullet:nth-child(5n+1) {
    display: inline;
  }
}
@media (min-width: 851px) and (max-width: 1100px) {
  .brands-carousel .glide__bullet:nth-child(4n+1) {
    display: inline;
  }
}
@media (min-width: 601px) and (max-width: 850px) {
  .brands-carousel .glide__bullet:nth-child(3n+1) {
    display: inline;
  }
}
@media (min-width: 460px) and (max-width: 600px) {
  .brands-carousel .glide__bullet:nth-child(2n+1) {
    display: inline;
  }
}
@media (min-width: 1px) and (max-width: 459px) {
  .brands-carousel .glide__bullet:nth-child(1n+1) {
    display: inline;
  }
}

/*=====  End of Brand Carousel  ======*/
.brand-categories {
  padding-block: 60px;
  padding-inline-start: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (min-width: 1440px) {
  .brand-categories {
    justify-content: space-evenly;
  }
}
.brand-categories__left {
  max-width: 555px;
}
.brand-categories__left-upper {
  display: flex;
  flex-direction: column;
  gap: 27px;
  padding-block-end: 36px;
}
.brand-categories__left-lower {
  display: grid;
  gap: 11px;
  grid-template-columns: repeat(4, 1fr);
}
.brand-categories__left-title, .brand-categories__left-content {
  max-width: 378px;
}
.brand-categories__left-title {
  color: #000;
  font-family: inherit;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.brand-categories__left-content {
  color: #000;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
}
.brand-categories__right {
  flex: 1;
}
@media (min-width: 1440px) {
  .brand-categories__right {
    flex: none;
    position: relative;
  }
}
.brand-categories__item {
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 10px 0px 90px 0px rgba(0, 0, 0, 0.1);
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  will-change: transform;
  backface-visibility: hidden;
}
.brand-categories__item-image {
  height: 60px;
  width: 60px;
  pointer-events: none;
}
.brand-categories__item-name {
  color: #000;
  text-align: center;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  pointer-events: none;
}
.brand-categories__item:hover {
  transform: scale(1.09);
}
.brand-categories__item.active {
  background: #262C63;
}
.brand-categories__item.active .brand-categories__item-name {
  color: #FFF;
}
.brand-categories__image {
  height: 540px;
  width: 630px;
  position: relative;
  bottom: -100px;
  margin-left: auto;
  margin-right: 120px;
}
@media (max-width: 991px) {
  .brand-categories__image {
    height: 480px;
    width: 560px;
  }
}
@media (max-width: 767px) {
  .brand-categories__image {
    height: 420px;
    width: 490px;
  }
}
.brand-categories__image-mask {
  position: absolute;
  right: 0;
  height: 800px;
  width: 700px;
  z-index: -1;
  margin-top: -120px;
}
@media (max-width: 1199px) {
  .brand-categories__image-mask {
    height: 720px;
    width: 630px;
    margin-top: -108px;
  }
}
@media (max-width: 991px) {
  .brand-categories__image-mask {
    height: 640px;
    width: 560px;
    margin-top: -96px;
  }
}
@media (max-width: 767px) {
  .brand-categories__image-mask {
    height: 560px;
    width: 490px;
    margin-top: -84px;
  }
}

@media (max-width: 767px) {
  .brand-categories {
    padding-block: 40px;
    padding-inline: 20px;
    gap: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .brand-categories__left-upper {
    gap: 20px;
    padding-block-end: 20px;
  }
  .brand-categories__left-lower {
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .brand-categories__left-title, .brand-categories__left-content {
    width: 100%;
  }
  .brand-categories__left-title {
    font-size: 24px;
  }
  .brand-categories__left-content {
    font-size: 14px;
  }
  .brand-categories__image {
    height: 375px;
    width: 100%;
    position: static;
  }
  .brand-categories__image-mask {
    display: none;
  }
}
.brand-hero {
  height: 774px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 18px;
}
.brand-hero__wrapper {
  width: 100%;
  padding-block-start: 180px;
  padding-inline: 128px;
  height: 100%;
  position: relative;
  background-size: cover;
}
.brand-hero__top {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 580px;
}
.brand-hero__top-wrapper {
  max-width: 1440px;
  margin: 0 auto;
}
.brand-hero__top-title {
  color: #FFF;
  font-family: inherit;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.96px;
  text-transform: uppercase;
}
.brand-hero__top-content {
  color: #FFF;
  font-family: inherit;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}
.brand-hero__search {
  cursor: pointer;
  padding: 14px 20px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  gap: 8px;
  min-width: fit-content;
  background-color: #262C63;
  color: #fff;
}
@media (max-width: 991px) {
  .brand-hero__search {
    font-size: 14px;
  }
}
.brand-hero__search:hover, .brand-hero__search:focus {
  background-color: #FF5003;
  color: #fff;
}
.brand-hero__bottom {
  position: absolute;
  padding: 20px 94px;
  border-radius: 5px 5px 0px 0px;
  background-color: #fff;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  bottom: -6%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
.brand-hero__bottom button {
  padding: 14px 32px;
}
.brand-hero__bottom-desktop {
  display: flex;
}
.brand-hero__bottom-mobile {
  display: none;
}
.brand-hero__buttons-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  padding-block-start: 30px;
}
.brand-hero__buttons-quote {
  color: #FFF;
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.32px;
  display: flex;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: #FF5003;
  transition: scale 0.3s ease-in-out;
}
.brand-hero__buttons-quote:hover {
  cursor: pointer;
  color: #fff;
  scale: 1.05;
}
.brand-hero__buttons-link {
  color: #FFF;
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.32px;
  text-decoration-line: underline;
  padding: 0px 30px;
}
.brand-hero__buttons-link:hover {
  color: #FF5003;
}
.brand-hero__selection {
  margin-right: 30px;
  padding-right: 30px;
  position: relative;
}
.brand-hero__selection:not(.no-after):after {
  content: "";
  position: absolute;
  right: 0;
  height: 100%;
  width: 1px;
  background-color: #DEDEDE;
}
@media (max-width: 991px) {
  .brand-hero__selection:not(.no-after):after {
    width: 0px;
  }
}
.brand-hero__selection select,
.brand-hero__selection option {
  width: fit-content;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.28px;
  color: #1A202C;
  font-family: inherit;
  transform: translateX(-3px);
  width: 170px;
}
.brand-hero-breaker {
  width: 122px;
  height: 3px;
  background: #fff;
  margin-bottom: 17px;
}
.brand-hero__label {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
}

@media (max-width: 991px) {
  .brand-hero {
    height: auto;
    display: flex;
  }
  .brand-hero__buttons-container {
    padding-block-start: 0px;
    gap: 5px;
  }
  .brand-hero__buttons-quote {
    padding: 15px 30px;
    font-size: 14px;
  }
  .brand-hero__buttons-link {
    font-size: 14px;
  }
  .brand-hero__top {
    padding: 44px 19px;
    width: 90%;
  }
  .brand-hero__top-title {
    font-size: 32px;
  }
  .brand-hero__top-content {
    font-size: 16px;
  }
  .brand-hero__bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 30px;
    gap: 10px;
  }
  .brand-hero__bottom-desktop {
    display: none;
  }
  .brand-hero__bottom-mobile {
    display: flex;
  }
  .brand-hero__selection {
    margin-right: 0px;
    padding: 10px 30px;
    width: 100%;
  }
  .brand-hero__selection option,
.brand-hero__selection select {
    width: 100%;
  }
  .brand-hero__selections {
    flex: 1;
    border-radius: 3px;
    border: 1px solid #E7E7E7;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .brand-hero__wrapper {
    padding: 0px;
    width: 100%;
    height: fit-content;
  }
  .brand-hero-breaker {
    margin-bottom: 10px;
  }
  .brand-hero .button {
    flex: 1;
    width: 100%;
  }
}
/*=============================================
=            brands Card Grid            =
=============================================*/
.brands-grid {
  margin: 0 auto;
  padding: 74px 82px;
  max-width: 1440px;
}
@media (max-width: 767px) {
  .brands-grid {
    padding: 32px;
  }
}
.brands-grid__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 48px;
}
.brands-grid__header {
  margin-bottom: 100px;
}
.brands-grid__title {
  color: #0b2653;
  font-size: 36px;
  font-weight: 500;
  line-height: 40px;
  max-width: 880px;
}
.brands-grid__subtitle {
  position: relative;
  color: #FF5003;
  max-width: 612px;
  font-size: 18px;
  font-weight: 500;
  line-height: 14px;
  margin: 15px 0;
}
.brands-grid__subtitle:before, .brands-grid__subtitle:after {
  content: "";
  position: absolute;
  inset-block-start: 50%;
  width: 50%;
  border-bottom: 2px solid #FF5003;
}
.brands-grid__subtitle:before {
  inset-inline-start: -15px;
  margin-inline-start: -50%;
}
.brands-grid__subtitle:after {
  inset-inline-end: -15px;
  margin-inline-end: -50%;
}
.brands-grid__content {
  font-size: 23px;
  font-weight: 400;
  line-height: 35px;
  max-width: 650px;
  text-align: center;
}
@media (max-width: 767px) {
  .brands-grid__content {
    text-align: start;
  }
}
.brands-grid__item {
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.brands-grid__item:hover {
  scale: 1.05;
}
.brands-grid__item-image {
  min-height: 120px;
  max-height: 120px;
  object-fit: contain;
  padding: 0 30px;
}
.brands-grid__item-caption {
  text-align: center;
  color: #FF5003;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

/*=====  End of brands Card Grid  ======*/
/*=============================================
=            button            =
=============================================*/
.button {
  padding: 14px 20px;
  cursor: pointer;
  padding: 14px 20px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  gap: 8px;
  min-width: fit-content;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.button:disabled:hover, .button:disabled:focus {
  background-color: #262C63;
  color: #fff;
}
.button--underline .button__label {
  text-decoration: underline;
}
.button--primary {
  background-color: #262C63;
  color: #fff;
}
@media (max-width: 991px) {
  .button--primary {
    font-size: 14px;
  }
}
.button--primary:hover, .button--primary:focus {
  background-color: #FF5003;
  color: #fff;
}
.button--primary-wide {
  background-color: #262C63;
  color: #fff;
  padding: 14px 32px;
}
.button--primary-wide:hover, .button--primary-wide:focus {
  background-color: #FF5003;
  color: #fff;
}
.button--secondary {
  background-color: #FF5003;
  color: #fff;
}
.button--secondary:hover, .button--secondary:focus {
  background-color: #262C63;
  color: #fff;
}
.button--secondary-wide {
  background-color: #fff;
  color: #262C63;
  border: 2px solid #262C63;
  padding: 14px 32px;
}
.button--secondary-wide:hover, .button--secondary-wide:focus {
  color: #FF5003;
  border-color: #FF5003;
}
.button--secondary-large {
  background-color: #FF5003;
  color: #fff;
  font-size: 16px;
  padding: 15px 50px;
}
@media (max-width: 991px) {
  .button--secondary-large {
    font-size: 14px;
    padding: 10px 30px;
  }
}
.button--secondary-large:hover, .button--secondary-large:focus {
  background-color: #F26101;
}
.button--outline {
  background-color: #fff;
  color: #262C63;
  border: 2px solid #262C63;
}
.button--outline:hover, .button--outline:focus {
  color: #FF5003;
  border-color: #FF5003;
}
.button--outline-wide {
  background-color: #fff;
  color: #262C63;
  border: 1px solid #262C63;
  padding: 14px 32px;
}
.button--outline-wide:hover, .button--outline-wide:focus {
  color: #FF5003;
  border-color: #FF5003;
}
.button--simple-primary {
  font-family: "Inter", sans-serif;
  color: #262C63;
  gap: 10px;
  padding: 12px 20px;
}
.button--simple-primary:hover, .button--simple-primary:focus {
  color: #FF5003;
}
.button--simple-secondary {
  font-family: "Inter", sans-serif;
  color: #FF5003;
  gap: 10px;
  padding: 12px 20px;
}
.button--simple-secondary:hover, .button--simple-secondary:focus {
  color: #262C63;
}

/*=====  End of button  ======*/
/*=============================================
=            CTA Banner            =
=============================================*/
.cta-banner {
  width: 100%;
  position: relative;
  height: 485px;
  background-size: cover;
  background-position: center;
}
@media (max-width: 991px) {
  .cta-banner {
    background-position: right;
  }
}
.cta-banner__content {
  height: 100%;
  width: 50%;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  z-index: 1;
  margin-inline-start: auto;
  padding-inline-end: 130px;
}
@media (max-width: 991px) {
  .cta-banner__content {
    padding: 32px;
    width: 100%;
  }
}
.cta-banner__title {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  margin-block-end: 10px;
}
.cta-banner__subtitle {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  margin-block-end: 24px;
}

/*=====  End of CTA Banner  ======*/
/*=============================================
=                CTA Cards                =
=============================================*/
.cta-cards-background {
  background-color: #f7f8f9;
}
.cta-cards__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  column-gap: 50px;
  row-gap: 40px;
}
@media (max-width: 767px) {
  .cta-cards__grid {
    grid-template-columns: initial;
  }
}
.cta-cards__header {
  margin-bottom: 56px;
}
.cta-cards__title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.704px;
  text-transform: capitalize;
  margin-block-end: 10px;
}
.cta-cards__content {
  font-size: 18px;
  font-weight: 400;
  line-height: 160%;
  max-width: 880px;
  letter-spacing: -0.396px;
}
.cta-cards__item {
  background-color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  gap: 22px;
  padding: 20px;
}
.cta-cards__item-image {
  width: 385px;
  height: 215px;
  object-fit: contain;
}
.cta-cards__item-image-container {
  max-height: 300px;
}
.cta-cards__item-text-title {
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.cta-cards__item-text-content {
  color: #3a3a3a;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
.cta-cards__item-link {
  margin-top: auto;
  color: #262C63;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
  text-decoration-line: underline;
}

@media (max-width: 991px) {
  .cta-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cta-cards__title, .cta-cards__content {
    text-align: center;
  }
  .cta-cards__item-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
/*=====  End of CTA Cards  ======*/
/*=============================================
=            CTA Info Panel            =
=============================================*/
.info-panel {
  background: linear-gradient(to right, #262C63 60%, #fff 50%);
  margin: 50px auto;
  color: #fff;
}
@media (max-width: 991px) {
  .info-panel {
    background: #262C63;
    flex-direction: column;
  }
}
.info-panel__left, .info-panel__right {
  flex: 1;
}
@media (max-width: 991px) {
  .info-panel__inner {
    flex-direction: column;
  }
}
.info-panel__left {
  justify-content: flex-end;
}
.info-panel__left-wrapper {
  max-width: 750px;
  padding: 100px 59px 100px 100px;
  gap: 30px;
}
@media (max-width: 991px) {
  .info-panel__left-wrapper {
    padding: 32px;
  }
}
.info-panel__right {
  flex: 1;
  position: relative;
  max-height: 580px;
  cursor: pointer;
}
.info-panel__title {
  font-size: 36px;
  font-weight: 700;
  color: #FF5003;
}
.info-panel__subtitle, .info-panel__footer {
  font-size: 16px;
  font-weight: 300;
}
.info-panel__stats {
  gap: 5px;
}
@media (max-width: 991px) {
  .info-panel__stats {
    flex-direction: column;
    align-items: flex-start;
  }
}
.info-panel__stat-value {
  font-size: 60px;
  font-weight: 700;
  line-height: 64px;
}
.info-panel__stat-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
}
.info-panel__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.info-panel__button {
  background-color: #262C63;
  position: absolute;
  left: -1px;
  bottom: 0;
  cursor: pointer;
  width: 100px;
  padding: 18px 19px;
  gap: 11px;
}
.info-panel__button:hover .info-panel__icon {
  transform: scale(1.1);
}
.info-panel__button:focus .info-panel__icon {
  transform: scale(1.1);
}
.info-panel__button-text {
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.24px;
}
.info-panel__icon {
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.info-panel__icon-container {
  margin: auto;
}

/*=====  End of CTA Info Panel  ======*/
/*=============================================
=            CTA Panel            =
=============================================*/
.cta-panels {
  margin: 50px auto;
  margin-block-end: 150px;
}
@media (max-width: 1199px) {
  .cta-panels {
    flex-direction: column;
  }
}
.cta-panels__left, .cta-panels__right {
  flex: 1;
}
@media (max-width: 1199px) {
  .cta-panels__inner {
    flex-direction: column-reverse;
  }
}
.cta-panels__right {
  padding: 100px 59px 100px 100px;
  box-sizing: border-box;
  flex: 0.776;
  gap: 30px;
}
@media (max-width: 1199px) {
  .cta-panels__right {
    padding: 32px;
  }
}
.cta-panels__left {
  padding-inline: 32px;
  flex: 1;
  position: relative;
}
.cta-panels__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}
.cta-panels__horizontal-line {
  position: absolute;
  inset-inline-start: 0;
  inset-block-end: -20px;
  width: 69px;
  height: 1px;
  background-color: #FF5003;
}
.cta-panels__footer {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
}
.cta-panels__stats {
  position: relative;
  gap: 5px;
  padding-block-end: 10px;
}
@media (max-width: 1199px) {
  .cta-panels__stats {
    flex-direction: column;
    align-items: flex-start;
  }
}
.cta-panels__stat-value {
  color: #FF5003;
  font-size: 60px;
  font-weight: 700;
  line-height: 64px;
}
.cta-panels__stat-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
}
.cta-panels__cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  grid-gap: 56px;
}
@media (max-width: 767px) {
  .cta-panels__cards {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}
.cta-panels__card {
  width: 100%;
  max-width: 350px;
  min-height: 328px;
  padding: 40px;
  padding-inline-end: 56px;
  padding-block-end: 16px;
  color: #262C63;
  background-color: #ECF2FE;
  justify-self: end;
}
@media (max-width: 1199px) {
  .cta-panels__card {
    justify-self: center;
  }
}
@media (max-width: 767px) {
  .cta-panels__card {
    top: 0px;
  }
}
.cta-panels__card-icon {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
  max-width: 30px;
  max-height: 30px;
}
.cta-panels__card-icon-container {
  position: relative;
  width: 56px;
  height: 56px;
  background-color: #262C63;
  border-radius: 50px;
}
.cta-panels__card-title {
  font-size: 24px;
  font-weight: 700;
  margin-block-start: 24px;
  margin-block-end: 16px;
}
.cta-panels__card-content {
  max-width: 240px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 300;
}
.cta-panels__card:first-child {
  background-color: #262C63;
  color: #fff;
}
.cta-panels__card:first-child .cta-panels__card-icon-container {
  background-color: #ECF2FE;
}
.cta-panels__card:nth-of-type(odd) {
  justify-self: start;
  top: 104px;
  position: relative;
}
@media (max-width: 1199px) {
  .cta-panels__card:nth-of-type(odd) {
    justify-self: center;
  }
}
@media (max-width: 767px) {
  .cta-panels__card:nth-of-type(odd) {
    top: 0px;
  }
}

/*=====  End of CTA Panel  ======*/
/*=============================================
=            detailed Card Grid            =
=============================================*/
.detailed-grid {
  font-family: inherit;
}
.detailed-grid.container {
  padding-block: 0px 37px;
}
.detailed-grid__grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.detailed-grid__header {
  margin-block: 56px;
}
.detailed-grid__header:empty {
  margin-block: 0;
}
.detailed-grid__header--left {
  align-items: flex-start;
  gap: 10px;
}
.detailed-grid__header--left .detailed-grid__content {
  max-width: unset;
}
.detailed-grid__title {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  max-width: 880px;
}
.detailed-grid__subtitle {
  position: relative;
  color: #FF5003;
  max-width: 612px;
  font-size: 18px;
  font-weight: 700;
  line-height: 14px;
  margin: 24px 0;
}
.detailed-grid__subtitle:before, .detailed-grid__subtitle:after {
  content: "";
  position: absolute;
  inset-block-start: 50%;
  width: 50%;
  border-bottom: 2px solid #FF5003;
}
.detailed-grid__subtitle:before {
  inset-inline-start: -15px;
  margin-inline-start: -50%;
}
.detailed-grid__subtitle:after {
  inset-inline-end: -15px;
  margin-inline-end: -50%;
}
.detailed-grid__content {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  max-width: 880px;
  text-align: center;
}
@media (max-width: 767px) {
  .detailed-grid__content {
    text-align: start;
  }
}
.detailed-grid__item {
  flex: 0 0 calc(33.3333333333% - 16px);
  box-shadow: 0px 3px 7px 0px rgba(218, 218, 218, 0.7);
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 767px) {
  .detailed-grid__item {
    flex: 0 0 100%;
  }
}
.detailed-grid__item:hover {
  box-shadow: 0px 6px 7px 3px rgba(218, 218, 218, 0.6);
}
.detailed-grid__item-body {
  padding: 32px 20px;
  height: 100%;
}
.detailed-grid__item-icon {
  max-width: 60px;
  max-height: 50px;
  padding-inline-end: 10px;
  margin-inline-end: 10px;
  border-inline-end: 2px solid #FF5003;
}
.detailed-grid__item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.detailed-grid__item-image-container {
  min-height: 284px;
  max-height: 284px;
}
.detailed-grid__item-text-title {
  color: #222;
  font-family: inherit;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-block-end: 10px;
}
.detailed-grid__item-text-content {
  color: #848484;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.detailed-grid__item-text-container {
  margin-inline-start: 10px;
  max-width: 225px;
}

/*=====  End of detailed Card Grid  ======*/
.feature-title {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 10px;
  align-items: center;
  max-width: 850px;
  padding-block: 59px;
}
.feature-title__title {
  color: #FF5003;
  text-align: center;
  font-family: inherit;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}
.feature-title__subtitle {
  color: #262C63;
  text-align: center;
  font-family: inherit;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 57px;
  text-transform: capitalize;
}
.feature-title__description {
  color: #282E39;
  text-align: center;
  font-family: inherit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

/*=============================================
=            Focus Card Grid            =
=============================================*/
.focus-grid__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 302px;
  grid-auto-flow: dense;
  grid-gap: 16px;
  overflow: hidden;
}
@media (max-width: 991px) {
  .focus-grid__grid {
    grid-template-columns: repeat(2, 1fr);
    max-height: 100%;
    overflow: auto;
  }
}
@media (max-width: 767px) {
  .focus-grid__grid {
    grid-template-columns: 1fr;
  }
}
.focus-grid__header {
  margin-bottom: 42px;
}
@media (max-width: 991px) {
  .focus-grid__header {
    flex-direction: column;
  }
}
.focus-grid__header-right .link {
  font-size: 16px;
}
.focus-grid__title {
  color: #000;
  font-size: 39px;
  font-weight: 700;
  line-height: 200%;
  letter-spacing: -0.858px;
}
@media (max-width: 991px) {
  .focus-grid__title {
    line-height: 100%;
    margin-block-end: 10px;
  }
}
.focus-grid__title-highlight {
  color: #262C63;
}
@media (max-width: 991px) {
  .focus-grid .link--simple-primary {
    padding: 10px 0;
  }
}
.focus-grid__image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.focus-grid__item {
  position: relative;
}
.focus-grid__item:hover .focus-grid__overlay {
  opacity: 1;
  visibility: visible;
}
.focus-grid__item--small, .focus-grid__item--large {
  display: flex;
  height: 100%;
  width: 100%;
  grid-column: auto/span 1;
}
.focus-grid__item--small {
  grid-row: span 1;
}
.focus-grid__item--large {
  grid-row: span 2;
}
@media (max-width: 767px) {
  .focus-grid__item--large {
    grid-row: span 1;
  }
}
.focus-grid__overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: #fff;
  background-color: rgba(38, 44, 99, 0.7);
  padding: 80px;
  opacity: 0;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 991px) {
  .focus-grid__overlay {
    opacity: 1;
    visibility: visible;
  }
}
.focus-grid__overlay:focus-within {
  opacity: 1;
  visibility: visible;
}
.focus-grid__overlay .link {
  font-size: 16px;
  z-index: 2;
}
.focus-grid__overlay .link:hover, .focus-grid__overlay .link:focus {
  color: #fff;
}
.focus-grid__overlay-container {
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.focus-grid__overlay .link {
  padding: 0px;
}
.focus-grid__overlay-link, .focus-grid__overlay-title, .focus-grid__overlay-text {
  text-align: center;
}
.focus-grid__overlay-link {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
}
.focus-grid__overlay-title {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

/*=====  End of Focus Card Grid  ======*/
/*=============================================
=            footer            =
=============================================*/
.footer__top {
  background-color: #1E234F;
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  padding: 77px 130px 77px 139px;
  min-height: 262px;
}
@media (max-width: 991px) {
  .footer__top {
    flex-direction: column;
    padding: 30px;
  }
}
.footer__top-wrapper {
  max-width: 1440px;
  padding-inline: 110px;
  margin: 0 auto;
}
@media (max-width: 1199px) {
  .footer__top-wrapper {
    padding-inline: 0;
  }
}
.footer__top-cta {
  position: relative;
  color: #F8F8F8;
  margin-inline-end: 20px;
  padding-inline-end: 20px;
  font-size: 24px;
  line-height: 36px;
  flex: 0 0 45%;
}
.footer__top-cta:before {
  content: url(/assets/img/images/footer-cta-bg-element.svg);
  position: absolute;
  inset-block-start: -77px;
  inset-inline-start: -95px;
}
@media (max-width: 991px) {
  .footer__top-cta:before {
    display: none;
  }
}
.footer__top-cta:after {
  content: url(/assets/img/images/footer-cta-bg-element.svg);
  position: absolute;
  inset-block-end: -77px;
  inset-inline-end: -95px;
  transform: rotate(180deg);
}
@media (max-width: 991px) {
  .footer__top-cta:after {
    display: none;
  }
}
[dir=rtl] .footer__top-cta:before {
  transform: scaleX(-1);
}
[dir=rtl] .footer__top-cta:after {
  transform: scaleY(-1);
}
@media (max-width: 991px) {
  .footer__top-title, .footer__top-content {
    font-size: 20px;
  }
}
.footer__top-title {
  font-weight: 700;
}
.footer__top-content {
  font-weight: 300;
  min-height: 72px;
}
.footer__top-icon {
  width: 24px;
  height: 18px;
}
.footer__top-email {
  height: 70px;
  position: relative;
  flex: 0 0 55%;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #fff;
  z-index: 1;
  gap: 10px;
}
@media (max-width: 991px) {
  .footer__top-email {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .footer__top-email {
    margin-top: 30px;
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .footer__top-email button {
    width: 100%;
    margin-top: 10px;
  }
}
.footer__top-input {
  padding: 5px;
  width: 100%;
}
.footer__top-input:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
@media (max-width: 991px) {
  .footer__top-input {
    margin-inline-start: 50px;
    width: 90%;
  }
}
.footer__top-button {
  padding: 15px 30px;
}
.footer__top-response--success, .footer__top-response--failure {
  display: none;
}
.footer__top-response--success {
  color: #262C63;
}
.footer__top-response--failure {
  color: #D32929;
}
.footer__top-submitted--success .footer__top-input, .footer__top-submitted--success .footer__top-button, .footer__top-submitted--failure .footer__top-input, .footer__top-submitted--failure .footer__top-button {
  display: none;
}
.footer__top-submitted--success .footer__top-response--success {
  display: block;
}
.footer__top-submitted--failure .footer__top-response--failure {
  display: block;
}
.footer__mid {
  font-size: 14px;
  font-weight: 300;
  padding: 30px 32px;
  row-gap: 50px;
  column-gap: 120px;
  min-height: 300px;
  width: 100%;
  background-color: #2D2D2D;
  color: #fff;
}
@media (min-width: 1660px) {
  .footer__mid {
    justify-content: space-around;
  }
}
.footer__mid-wrapper {
  width: 1440px;
  padding-inline: 32px;
}
.footer__mid-link {
  position: relative;
  will-change: transform;
}
.footer__mid-link:before {
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  will-change: opacity;
  opacity: 0;
  content: "";
  position: absolute;
  top: 0;
  left: -5px;
  width: 1px;
  height: 100%;
  background-color: #FF5003;
}
.footer__mid-link:hover, .footer__mid-link:focus {
  transform: translateX(5px);
}
.footer__mid-link:hover:before, .footer__mid-link:focus:before {
  opacity: 1;
}
.footer__mid-info {
  gap: 16px;
  max-width: 272px;
}
.footer__mid-logo {
  width: 150px;
}
.footer__mid-icons {
  gap: 13px;
}
.footer__mid-icon {
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.footer__mid-icon:hover, .footer__mid-icon:focus {
  scale: 1.1;
}
.footer__mid-columns {
  gap: 8px;
}
.footer__mid-column-title {
  margin-bottom: 15px;
  padding-inline-end: 20px;
  font-weight: 400;
}
.footer__bottom {
  min-height: 46px;
  background-color: #2A2A2A;
}
@media (max-width: 991px) {
  .footer__bottom {
    flex-direction: column;
  }
}
.footer__bottom-link, .footer__bottom-copyright {
  height: 25px;
  padding: 0 14px;
  font-size: 12px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.352px;
  color: #fff;
  border-inline-end: 1px solid #fff;
}
@media (max-width: 991px) {
  .footer__bottom-link, .footer__bottom-copyright {
    border-inline-end: none;
  }
}
.footer__bottom-link:last-child, .footer__bottom-copyright:last-child {
  border-inline-end: none;
}
@media (max-width: 991px) {
  .footer__bottom-copyright--desktop {
    display: none;
  }
}
@media (min-width: 768px) {
  .footer__bottom-copyright--mobile {
    display: none;
  }
}
.footer__bottom-link:hover, .footer__bottom-link:focus {
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 991px) {
  .footer__top-wrapper {
    padding: 0;
    flex-direction: column;
  }
  .footer__top-email, .footer__top-cta {
    width: 100%;
  }
  .footer__top-cta {
    margin-inline: 0;
    padding-inline: 0;
  }
  .footer__mid-info {
    display: flex;
    flex-direction: column;
  }
  .footer__mid-logo, .footer__mid-icons {
    align-self: center;
  }
  .footer__mid-columns {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer__mid-column-title {
    margin-block: 0;
    padding: 0;
  }
  .footer__mid-wrapper {
    flex-wrap: unset;
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
  .footer__mid-wrapper > div:not(:first-child):not(:only-child) {
    width: 70%;
  }
  .footer__mid-wrapper > *:not(:last-child) {
    position: relative;
  }
  .footer__mid-wrapper > *:not(:last-child)::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -12px;
    width: 100%;
    height: 0.5px;
    background-color: rgba(231, 234, 238, 0.7);
  }
}
/*=====  End of header  ======*/
/*=============================================
=            header            =
=============================================*/
.header {
  background-color: #fff;
  color: #262C63;
  padding-inline: 32px;
  max-width: 1440px;
  margin: 0 auto;
}
.header__logo {
  padding: 16px 0;
}
.header__search-bar {
  display: none;
}
.header__links--search-active .header__link {
  display: none;
}
.header__links--search-active .header__link-title {
  display: none;
}
.header__links--search-active .header__search-bar {
  width: 100%;
  display: flex;
}
.header__links--search-active .header__search-form {
  width: 100%;
}
.header__links-desktop {
  gap: 36px;
  width: 70%;
}
.header-links-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.header-link {
  color: #000;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.352px;
  white-space: nowrap;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.header-link:hover {
  color: #FF5003;
}
.header__double {
  padding: 25px 56px;
  display: flex;
  flex-direction: row;
  gap: 70px;
}
.header__double-breaker {
  position: relative;
}
.header__double-breaker::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
}
.header__dropdown {
  position: absolute;
  top: 247%;
  left: -48%;
  z-index: 2;
  background-color: #fff;
  border-top: 1px solid #FF5003;
  display: none;
  flex-direction: row;
}
.header__dropdown.active {
  display: flex;
}
.header__features-right {
  padding: 28px 35px;
  display: none;
  flex-direction: row;
  gap: 40px;
  background-color: #F7F8F9;
}
.header__features-right.active {
  display: flex;
}
.header__features-left {
  cursor: pointer;
}
.header__features-links {
  min-width: 210px;
}
.header__features-images {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.header__features-images-top {
  width: 255px;
  height: 133px;
}
.header__features-images-bottom {
  width: 255px;
  height: 161px;
}
.header__features-logo {
  box-sizing: border-box;
  height: 100%;
  width: 100px;
  z-index: 1;
  position: relative;
}
.header__features-logo-container {
  padding: 15px 40px;
  width: 180px;
  height: 62px;
  box-sizing: border-box;
  position: relative;
  transition: all 0.3s ease;
  z-index: 1;
}
.header__features-logo-container:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0px;
  height: 100%;
  background-color: #F7F8F9;
}
.header__features-logo-container:not(.active):hover:after {
  width: 100%;
}
.header__features-logo-container.active {
  background-color: #F7F8F9;
}
.header__features-logo-container.active:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #FF5003;
}
.header__link {
  position: relative;
}
.header__link-title {
  color: #0D0F11;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.352px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.header__link-title:hover {
  color: #FF5003;
}
.header__link.active .header__link-title {
  color: #FF5003;
}
.header__link.active::after {
  content: "";
  position: absolute;
  bottom: -148%;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #FF5003;
}
.header__right {
  gap: 48px;
}
.header__buttons {
  gap: 16px;
}
.header__button {
  background-color: #E7EAEE;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  border: 2px solid transparent;
  stroke: #64748B;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.header__button:hover {
  border-color: #0D0F11;
}
.header__button--active {
  background-color: #262C63;
}

@media (max-width: 991px) {
  .header,
.navbar {
    display: none;
  }
  .mobile-menu {
    display: flex;
  }
}
@media (min-width: 768px) {
  .mobile-menu {
    display: none;
  }
  .header,
.navbar {
    display: flex;
  }
}
@media (max-width: 991px) {
  .header, .navbar {
    display: none;
  }
  .mobile-menu {
    display: flex;
  }
}
@media (min-width: 768px) {
  .mobile-menu {
    display: none;
  }
  .header, .navbar {
    display: flex;
  }
}
/*=====  End of header  ======*/
/*=============================================
=            Small Hero            =
=============================================*/
.hero {
  background-repeat: no-repeat;
  background-size: cover;
  height: 458px;
  position: relative;
}
.hero__video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.hero__mute-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #FF5003;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  z-index: 2;
  padding: 10px;
  color: #fff;
  font-size: 32px;
  border: 3px solid #FF5003;
  will-change: transform;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.hero__mute-btn:hover {
  background-color: transparent;
  border-color: #FF5003;
  color: #FF5003;
}
.hero__mute-btn:before {
  display: block;
  width: 33px;
  height: 33px;
}
@media (max-width: 767px) {
  .hero__mute-btn {
    bottom: 10px;
    right: 10px;
    font-size: 24px;
    padding: 6px;
    bottom: 87%;
    right: 6px;
    background-color: transparent;
    border-color: #FF5003;
    color: #FF5003;
  }
  .hero__mute-btn:before {
    width: 24px;
    height: 24px;
  }
}
.hero:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(15, 54, 123, 0.56) 0%, rgba(38, 44, 99, 0.56) 100%);
  height: 100%;
  width: 100%;
  z-index: 0;
}
.hero__inner {
  text-align: center;
  max-width: 697px;
  gap: 16px;
  margin: 0 auto;
  z-index: 1;
  padding-top: 170px;
}
.hero__title {
  color: #fff;
  font-family: Rubik;
  font-size: 48px;
  font-weight: 500;
  line-height: 56px;
  position: relative;
}
.hero__breadcrumbs {
  position: absolute;
  bottom: 32px;
  left: 135px;
  z-index: 1;
  color: #fff;
}
@media (max-width: 991px) {
  .hero__breadcrumbs {
    left: 60px;
  }
}
@media (max-width: 767px) {
  .hero__breadcrumbs {
    left: 40px;
  }
}
@media (max-width: 479px) {
  .hero__breadcrumbs {
    left: 20px;
  }
}
.hero__breadcrumb {
  margin-left: 5px;
  padding-left: 10px;
  position: relative;
  font-family: Rubik;
  font-size: 16px;
  line-height: 12px;
}
.hero__breadcrumb-icon {
  margin-right: 8px;
}
@media (max-width: 767px) {
  .hero__breadcrumb-icon {
    width: 20px;
    height: auto;
  }
}
.hero__breadcrumb:before {
  content: "";
  position: absolute;
  left: 0;
  width: 5px;
  height: 1px;
  background-color: #fff;
}
.hero__breadcrumb:first-child {
  margin-left: 0;
  padding-left: 0;
  color: #C2C2C2;
  font-family: Rubik;
  font-size: 16px;
  line-height: 12px;
}
.hero__breadcrumb:first-child:before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
}

/*=====  End of Small Hero  ======*/
/*=============================================
=            hero            =
=============================================*/
.homepage-hero {
  position: relative;
  width: 100%;
  height: 669px;
}
@media (max-width: 991px) {
  .homepage-hero {
    height: 100%;
  }
}
.homepage-hero__carousel {
  height: 669px;
}
.homepage-hero__carousel:before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0, rgba(0, 0, 0, 0) 80%);
  z-index: 1;
}
.homepage-hero__carousel-image {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 669px;
  inset-inline-start: 0;
  inset-block-start: 0;
  object-fit: cover;
}
.homepage-hero__carousel-container {
  position: relative;
  height: 100%;
  padding: 20px;
}
.homepage-hero__carousel .glide__slide {
  position: relative;
  height: 669px;
  width: 300px;
}
.homepage-hero__inner {
  position: absolute;
  inset: 0;
  max-width: 1440px;
  padding-inline: 32px;
  margin: auto;
}
@media (max-width: 991px) {
  .homepage-hero__inner {
    max-width: 100%;
    padding: 0 20px;
  }
}
.homepage-hero__content {
  color: #fff;
  max-width: 720px;
  gap: 16px;
  z-index: 1;
}
.homepage-hero__title {
  font-size: 62px;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: -1.24px;
  text-transform: uppercase;
  position: relative;
  padding-top: 16px;
}
@media (max-width: 991px) {
  .homepage-hero__title {
    font-size: 32px;
  }
}
.homepage-hero__title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 2px;
  background-color: #FF5003;
}
.homepage-hero__subtitle {
  font-size: 22px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 10px;
}
@media (max-width: 991px) {
  .homepage-hero__subtitle {
    font-size: 14px;
  }
}
.homepage-hero__bottom {
  position: absolute;
  bottom: 0;
  background-color: #fff;
  padding: 20px 50px;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
}
@media (max-width: 991px) {
  .homepage-hero__bottom {
    flex-direction: column;
    position: relative;
  }
}
.homepage-hero__bottom button {
  padding: 14px 32px;
}
@media (max-width: 991px) {
  .homepage-hero__selections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    column-gap: 40px;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .homepage-hero__selections {
    display: grid;
    grid-template-columns: 1fr;
  }
}
.homepage-hero__selection {
  margin-right: 30px;
  padding-right: 30px;
  position: relative;
}
@media (max-width: 991px) {
  .homepage-hero__selection {
    margin-right: 0px;
    padding-right: 0px;
    margin-bottom: 8px;
  }
}
.homepage-hero__selection:after {
  content: "";
  position: absolute;
  right: 0;
  height: 100%;
  width: 1px;
  background-color: #DEDEDE;
}
@media (max-width: 991px) {
  .homepage-hero__selection:after {
    width: 0px;
  }
}
.homepage-hero__selection select,
.homepage-hero__selection option {
  width: 200px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.28px;
  color: #1A202C;
  font-family: inherit;
  transform: translateX(-3px);
}
.homepage-hero__selection:last-child {
  margin-right: 0;
}
.homepage-hero__selection:last-child:after {
  content: "";
  position: absolute;
  right: 0;
  width: 0px;
}
.homepage-hero__label {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
}
.homepage-hero .link {
  color: #fff;
}

/*=====  End of hero  ======*/
.industry-sidebar {
  display: flex;
  flex-direction: row;
  gap: 180px;
  padding-block: 40px;
  max-width: 1440px;
  justify-content: space-between;
  margin: 0 auto;
}
.industry-sidebar__left {
  line-height: 24px;
}
.industry-sidebar__left .container {
  padding: 0;
}
.industry-sidebar__left-content {
  display: none;
}
.industry-sidebar__left-content.active {
  display: block;
}
.industry-sidebar__left-title {
  color: #222;
  font-family: inherit;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  text-transform: capitalize;
}
.industry-sidebar__left-image {
  width: 100%;
  height: 100%;
  background-size: cover;
}
.industry-sidebar__left-image-container {
  position: relative;
  width: 100%;
  height: 420px;
  margin-block: 20px;
}
.industry-sidebar__left-icon {
  transform: rotate(-45deg);
  max-width: 100%;
  max-height: 100%;
}
.industry-sidebar__left-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 85px;
  height: 85px;
  background-color: #FF5003;
  transform: rotate(45deg);
  right: 10%;
  top: -10%;
}
.industry-sidebar__right {
  min-width: 320px;
  background-color: #F5F5F5;
  padding-block-start: 48px;
  padding-inline: 27px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: fit-content;
}
.industry-sidebar__column {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.industry-sidebar__column-icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.industry-sidebar__column-title {
  color: #222;
  font-family: inherit;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: capitalize;
}
.industry-sidebar__column-link-item {
  display: flex;
  flex-direction: row;
  gap: 12px;
  background-color: #FFF;
  padding: 12px 18px;
  padding-inline-end: 5px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
  will-change: transform;
  backface-visibility: hidden;
}
.industry-sidebar__column-link-item:hover {
  transform: scale(1.048);
  transform-origin: 100% 0;
}
.industry-sidebar__column-link-name {
  color: #222;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.industry-sidebar__column-link-size {
  color: #222;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.industry-sidebar__column-link-image {
  width: 35px;
  height: 35px;
}
.industry-sidebar__column-item {
  position: relative;
}
.industry-sidebar__column-item.line-break {
  padding-block: 15px;
  border-top: 1px solid #E9E9E9;
  border-bottom: 1px solid #E9E9E9;
}
.industry-sidebar__column-item.active .industry-sidebar__column-item-name-hover {
  color: #FF5003;
}
.industry-sidebar__column-item.active:before {
  content: "";
  position: absolute;
  top: 0;
  left: -27px;
  width: 2px;
  height: 100%;
  background-color: #FF5003;
}
.industry-sidebar__column-item:before {
  content: "";
}
.industry-sidebar__column-item-container {
  display: flex;
  flex-direction: column;
}
.industry-sidebar__column-item-icon {
  width: 30px;
  height: 30px;
}
.industry-sidebar__column-item-name {
  color: #222;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
}
.industry-sidebar__column-item-name-hover {
  transition: color 0.2s ease;
  cursor: pointer;
}
.industry-sidebar__column-item-name-hover:hover {
  color: #FF5003;
}

@media (max-width: 991px) {
  .industry-sidebar {
    flex-direction: column;
    flex-direction: column-reverse;
    gap: 30px;
  }
  .industry-sidebar .container {
    padding: 0;
  }
  .industry-sidebar__left {
    flex-basis: 100%;
  }
  .industry-sidebar__left-image-container {
    height: 300px;
    margin-block: 35px;
  }
  .industry-sidebar__left-icon-container {
    width: 55px;
    height: 55px;
    top: -9%;
  }
  .industry-sidebar__left-title {
    font-size: 24px;
  }
  .industry-sidebar__right {
    flex-basis: 100%;
    padding-inline: 15px;
    padding-block-start: 20px;
    gap: 20px;
  }
  .industry-sidebar__column {
    gap: 15px;
  }
  .industry-sidebar__column-title {
    font-size: 16px;
  }
  .industry-sidebar__column-link-item {
    padding: 10px 12px;
    margin-bottom: 15px;
  }
  .industry-sidebar__column-link-name, .industry-sidebar__column-link-size {
    font-size: 14px;
    line-height: 18px;
  }
  .industry-sidebar__column-link-image {
    width: 30px;
    height: 30px;
  }
  .industry-sidebar__column-item.active:before {
    left: -14px;
  }
  .industry-sidebar__column-item-icon {
    width: 25px;
    height: 25px;
  }
  .industry-sidebar__column-item-name {
    font-size: 14px;
    line-height: 18px;
  }
}
/*=============================================
=            CTA Info Panel            =
=============================================*/
.info {
  padding: 98px 85px 120px 58px;
}
@media (max-width: 991px) {
  .info {
    padding: 50px 32px;
  }
}
.info__subtitle {
  color: #FF5003;
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  margin-block-end: 10px;
}
.info__title {
  max-width: 850px;
  color: #0b2653;
  font-size: 45px;
  font-weight: 700;
  line-height: 57px;
  text-transform: capitalize;
}
.info__bottom {
  row-gap: 30px;
  column-gap: 60px;
  margin-block-start: 50px;
}
.info__bottom-left {
  gap: 30px;
}
.info__image {
  object-fit: cover;
}
.info__card-outer {
  position: relative;
  height: 496px;
}
.info__card-outer:before {
  position: absolute;
  content: "";
  width: 30px;
  height: 60px;
  background-color: #262C63;
  inset-block-end: 0;
  inset-inline-start: 51%;
}
@media (max-width: 767px) {
  .info__card-outer:before {
    display: none;
  }
}
.info__card-outer:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 30px;
  background: linear-gradient(270deg, #262C63 49%, rgb(255, 255, 255) 49%);
  inset-block-end: -5px;
}
@media (max-width: 767px) {
  .info__card-outer:after {
    background: #262C63;
    height: 35px;
  }
}
.info__card-inner {
  padding: 40px;
  background-color: #262C63;
  position: absolute;
  width: 80%;
  inset-block-start: 50%;
  transform: translateY(-50%);
  inset-inline-end: 0;
}
@media (max-width: 767px) {
  .info__card-inner {
    width: 100%;
    inset-block-start: 70%;
  }
}
.info__card-icon {
  margin-block-end: 20px;
  width: 50px;
}
.info__card-text {
  max-width: 60%;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  line-height: 26px;
  text-transform: capitalize;
  margin-block-end: 15px;
}
.info__card-link {
  margin-inline-start: 25px;
  width: 22px;
  height: 22px;
}
.info__image {
  max-width: 486px;
  aspect-ratio: 243/250;
}
.info__content {
  height: 100%;
}
@media (max-width: 1199px) {
  .info__content {
    order: 1;
  }
}
.info__content .link {
  font-size: 15px;
  letter-spacing: 0.2px;
}
.info__stat {
  color: #FF5003;
  font-size: 65px;
  font-weight: 700;
  line-height: 65px;
  margin-block-end: 20px;
}
.info__stat-container {
  max-width: 175px;
}
.info__stat-line {
  background-color: #E7EBEF;
  width: 30px;
  height: 2px;
  margin: 30px auto;
}
.info__label {
  color: #64748B;
  text-align: center;
  font-size: 17px;
  font-weight: 700;
  line-height: 26px;
}
.info .link--outline {
  width: fit-content;
  margin-block-start: 40px;
}
.info .container {
  padding: 0;
  max-width: 485px;
}
.info .rte {
  color: #0b2653;
  line-height: 26px;
}
.info .rte ul {
  list-style: none;
}
.info .rte li {
  color: #0b2653;
  position: relative;
  margin-block-end: 10px;
}
.info .rte li:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 14px;
  background-color: #FF5003;
  inset-block-start: 10px;
  inset-inline-start: -30px;
}

/*=====  End of CTA Info Panel  ======*/
/*=============================================
=            detailed Card Grid            =
=============================================*/
.info-cards__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  column-gap: 100px;
  row-gap: 40px;
}
@media (max-width: 767px) {
  .info-cards__grid {
    grid-template-columns: initial;
  }
}
.info-cards__header {
  margin-bottom: 56px;
}
.info-cards__content {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  max-width: 880px;
  text-align: center;
}
@media (max-width: 767px) {
  .info-cards__content {
    text-align: start;
  }
}
.info-cards__item {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  gap: 19px;
}
.info-cards__item .link {
  padding: 10px;
  font-size: 16px;
}
.info-cards__item-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.info-cards__item-image-container {
  padding-inline: 30px;
  min-height: 300px;
  max-height: 300px;
}
.info-cards__item-text-title {
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.6px;
  padding: 10px;
}
.info-cards__item-text-content {
  color: #3a3a3a;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  padding: 10px 10px 0 10px;
}
.info-cards .rte {
  text-align: center;
  max-width: 1200px;
  padding: 0px 30px;
  font-size: 18px;
  line-height: 27px;
}

/*=====  End of detailed Card Grid  ======*/
/*=============================================
=            Insights Card Grid            =
=============================================*/
.insights-grid {
  position: relative;
}
.insights-grid__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-row-gap: 20px;
  grid-column-gap: 16px;
}
.insights-grid__header {
  margin-bottom: 30px;
}
.insights-grid__subtitle {
  position: relative;
  color: #FF5003;
  max-width: 612px;
  font-size: 18px;
  font-weight: 700;
  line-height: 14px;
  margin: 12px 0;
}
.insights-grid__title {
  color: #262C63;
  font-size: 40px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.84px;
  max-width: 880px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.insights-grid__content {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  max-width: 880px;
  text-align: center;
  opacity: 0.6;
}
@media (max-width: 767px) {
  .insights-grid__content {
    text-align: start;
  }
}
.insights-grid__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}
.insights-grid__image-container {
  position: relative;
  min-height: 177px;
  max-height: 177px;
}
.insights-grid__date {
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;
  background-color: #FF5003;
  border-radius: 0 5px;
  color: #fff;
  padding: 15px 27px 12px;
  font-size: 12px;
}
.insights-grid__horizontal-line {
  height: 1px;
  margin: 0 auto;
  width: 85%;
  background-color: #DADADA;
}
.insights-grid__item {
  border-radius: 5px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.insights-grid__item:hover {
  box-shadow: 0px 6px 8px 6px rgba(0, 0, 0, 0.05);
}
.insights-grid__item-title {
  color: #1a202c;
  margin-bottom: 17px;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.44px;
}
.insights-grid__item-content {
  padding-block-end: 17px;
  margin: 23px 18px 0px;
  font-size: 14px;
  font-weight: 300;
}
.insights-grid__link {
  color: #1E234F;
  font-size: 16px;
  padding: 12px 17px 15px;
}

/*=====  End of News Card Grid  ======*/
/*=============================================
=            link            =
=============================================*/
.link {
  cursor: pointer;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  gap: 8px;
  min-width: fit-content;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.link.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.link.disabled:hover, .link.disabled:focus {
  background-color: #262C63;
  color: #fff;
}
.link--underline .link__label {
  text-decoration: underline;
}
.link--primary {
  background-color: #262C63;
  color: #fff;
  padding: 12px 20px;
}
.link--primary:hover, .link--primary:focus {
  background-color: #FF5003;
  color: #fff;
}
.link--primary-wide {
  background-color: #262C63;
  color: #fff;
  padding: 14px 32px;
}
.link--primary-wide:hover, .link--primary-wide:focus {
  background-color: #FF5003;
  color: #fff;
}
.link--secondary {
  background-color: #FF5003;
  color: #fff;
  padding: 12px 20px;
}
@media (max-width: 991px) {
  .link--secondary {
    font-size: 14px;
  }
}
.link--secondary:hover, .link--secondary:focus {
  background-color: #262C63;
  color: #fff;
}
.link--secondary-wide {
  background-color: #fff;
  color: #262C63;
  border: 2px solid #262C63;
  padding: 14px 32px;
}
.link--secondary-wide:hover, .link--secondary-wide:focus {
  color: #FF5003;
  border-color: #FF5003;
}
.link--outline {
  background-color: #fff;
  color: #262C63;
  border: 1px solid #262C63;
  padding: 12px 20px;
}
.link--outline:hover, .link--outline:focus {
  color: #FF5003;
  border-color: #FF5003;
}
.link--outline-wide {
  background-color: #fff;
  color: #262C63;
  border: 1px solid #262C63;
  padding: 14px 32px;
}
.link--outline-wide:hover, .link--outline-wide:focus {
  color: #FF5003;
  border-color: #FF5003;
}
.link--outline-secondary {
  font-size: 16px;
  color: #FF5003;
  border: 1px solid #FF5003;
  padding: 14px 32px;
  width: fit-content;
}
.link--outline-secondary:hover, .link--outline-secondary:focus {
  color: #fff;
  border-color: #fff;
}
.link--simple-primary {
  font-family: "Inter", sans-serif;
  color: #262C63;
  gap: 10px;
  padding: 12px 20px;
}
@media (max-width: 991px) {
  .link--simple-primary {
    font-size: 14px;
  }
}
.link--simple-primary:hover, .link--simple-primary:focus {
  color: #FF5003;
}
.link--simple-secondary {
  font-family: "Inter", sans-serif;
  color: #FF5003;
  gap: 10px;
  padding: 12px 20px;
}
@media (max-width: 991px) {
  .link--simple-secondary {
    padding: 12px 0px;
    padding-inline-start: 10px;
    text-align: center;
  }
}
.link--simple-secondary:hover, .link--simple-secondary:focus {
  color: #262C63;
}

/*=====  End of link  ======*/
/*=============================================
=            Links Card Grid            =
=============================================*/
.links-grid {
  display: flex;
  flex-direction: column;
  gap: 56px;
}
.links-grid__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(332px, 1fr));
  grid-gap: 16px;
}
.links-grid__title {
  color: #000;
  text-align: center;
  font-family: inherit;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.84px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .links-grid__title {
    font-size: 32px;
  }
}
.links-grid__subtitle {
  color: #282E39;
  text-align: center;
  font-family: inherit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  max-width: 50%;
}
@media (max-width: 767px) {
  .links-grid__subtitle {
    max-width: 100%;
  }
}
.links-grid__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.links-grid__image-container {
  min-height: 217px;
  max-height: 217px;
}
.links-grid__item {
  box-shadow: 10px 4px 90px 0px rgba(0, 0, 0, 0.1);
}
.links-grid__item-title {
  color: #1A202C;
  text-transform: uppercase;
  margin-bottom: 17px;
  font-size: 22px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.44px;
}
.links-grid__item-content {
  padding: 23px 30px 19px;
}
.links-grid__list {
  height: 100%;
  list-style: disc;
  text-decoration: underline;
  color: #45A8DF;
  margin-left: 15px;
  gap: 5px;
}
.links-grid__list-link {
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  text-decoration-color: #FF5003;
  text-decoration: underline;
  font-size: 18px;
  line-height: 30px;
}
.links-grid__list-link:hover {
  color: #FF5003;
}

/*=====  End of Links Card Grid  ======*/
.location-item {
  z-index: 1;
  position: relative;
}
.location-item #map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}
.location-item-inner {
  padding: 64px 167px;
}
.location-item__left {
  flex: 1;
  min-height: 600px;
}
.location-item__left-title {
  font-family: inherit;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.72px;
}
.location-item__left-content {
  color: #282E39;
  font-family: inherit;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}
.location-item__left-region {
  padding-block: 40px;
}
.location-item__left-region-label {
  color: #282E39;
  font-family: inherit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.location-item__left-region-dropdown {
  color: #FF5003;
  font-family: inherit;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
.location-item__left-branch-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
}
.location-item__left-branch-column {
  flex-basis: 47%;
  padding: 17px 31px;
  background: #FFF;
  position: relative;
  pointer-events: all;
  margin-inline-end: 10px;
  display: none;
  cursor: pointer;
}
.location-item__left-branch-column:hover::before, .location-item__left-branch-column.active::before {
  width: 100%;
}
.location-item__left-branch-column:before {
  content: "";
  transition: all 0.5s ease;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1%;
  background-color: #FF5003;
  pointer-events: none;
}
.location-item__left-branch-column-name {
  color: var(--text-title-text, #333);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  position: relative;
  z-index: 1;
}
.location-item__right {
  flex: 1;
}

@media (max-width: 767px) {
  .location-item {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .location-item .gm-style-iw {
    max-width: 370px !important;
    max-height: 280px !important;
    overflow-y: auto;
  }
  .location-item .gm-style-iw::-webkit-scrollbar {
    width: 2px;
  }
  .location-item .gm-style-iw::-webkit-scrollbar-thumb {
    background-color: #FF5003;
    border-radius: 10px;
  }
  .location-item #map {
    position: relative;
    width: 100%;
    height: 400px;
  }
  .location-item-inner {
    background-color: #F8F8F8;
    padding: 64px 20px;
  }
  .location-item__right {
    display: none;
  }
  .location-item__left {
    text-align: center;
    height: fit-content;
    min-height: unset;
  }
  .location-item__left-title {
    font-size: 24px;
  }
  .location-item__left-content {
    font-size: 16px;
  }
  .location-item__left-region {
    align-self: center;
  }
  .location-item__left-region-label {
    font-size: 16px;
  }
  .location-item__left-region-dropdown {
    font-size: 16px;
  }
  .location-item__left-branch-container {
    display: flex;
    flex-wrap: wrap;
  }
  .location-item__left-branch-container-column {
    flex-basis: calc(50% - 5px);
    margin-inline-end: 0;
    margin-block-end: 10px;
  }
}
/*=============================================
=            media-with-text            =
=============================================*/
.media-with-text {
  padding-inline: 35px;
}
.media-with-text--grey {
  background-color: #ECF2FE;
}
.media-with-text__bottom {
  gap: 60px;
}
@media (max-width: 991px) {
  .media-with-text__bottom {
    gap: 30px;
    flex-direction: column-reverse;
  }
}
.media-with-text__bottom--reversed {
  flex-direction: row-reverse;
}
@media (max-width: 991px) {
  .media-with-text__bottom--reversed {
    flex-direction: column-reverse;
  }
}
.media-with-text__bottom-left, .media-with-text__bottom-right {
  height: 100%;
  flex: 1;
}
.media-with-text__bottom-title {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 1.09px;
  text-transform: uppercase;
  margin-block-end: 20px;
}
.media-with-text__bottom-content {
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  margin-block-end: 20px;
}

/*=====  media-with-text  ======*/
.mobile-menu {
  background-color: #fff;
  position: relative;
}
.mobile-menu__logo {
  max-width: 88px;
  max-height: 41px;
}
.mobile-menu__logo-link--no-press {
  pointer-events: none;
}
.mobile-menu__header {
  padding: 16px 26px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}
.mobile-menu__header-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 22px;
}
.mobile-menu__header-text-container {
  cursor: pointer;
  color: #262C63;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #262C63;
  gap: 6px;
  transition: color 0.3s ease;
}
.mobile-menu__header-text-container:hover {
  color: #FF5003;
}
.mobile-menu__header-text-container .zmdi {
  font-size: 23px;
}
.mobile-menu__items-container {
  display: none;
  flex-direction: column;
  gap: 16px;
}
.mobile-menu__items-container--active {
  display: flex;
}
.mobile-menu__items-container > *:not(:last-child) {
  border-bottom: 0.5px solid #E7E7E7;
}
.mobile-menu__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 52px;
  align-items: center;
  padding: 16px 10px;
  cursor: pointer;
  color: #262C63;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.mobile-menu__item .zmdi {
  color: #262C63;
}
.mobile-menu__item-image {
  max-width: 90px;
  max-height: 100%;
  width: auto;
  object-fit: contain;
}
.mobile-menu__item:hover {
  color: #FF5003;
}
.mobile-menu__item:hover .zmdi {
  color: #FF5003;
}
.mobile-menu__overlay {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transform: translateX(100%);
  background-color: #fff;
  top: 80px;
  width: 100%;
  height: calc(100vh - 80px);
  z-index: 3;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  border-top: 3px solid #262C63;
}
.mobile-menu__overlay--active {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}
.mobile-menu__overlay-contact {
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.mobile-menu__overlay-contact-container {
  position: absolute;
  bottom: 0;
  padding-block: 17px;
  width: 100%;
  background-color: #262C63;
  color: #fff;
  left: 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
}
.mobile-menu__overlay-contact-container:hover {
  color: #FF5003;
}
.mobile-menu__overlay-contact-container .zmdi {
  font-size: 23px;
}
.mobile-menu__overlay-wrapper {
  padding: 17px 21px;
  max-height: 93.5%;
  overflow-y: auto;
}
.mobile-menu__overlay-wrapper::-webkit-scrollbar {
  width: 4px;
}
.mobile-menu__overlay-wrapper::-webkit-scrollbar-thumb {
  background-color: #262C63;
  border-radius: 10px;
}
.mobile-menu__language {
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.mobile-menu__language-upper {
  display: flex;
  flex-direction: row;
  gap: 6px;
  position: relative;
  align-items: flex-end;
}
.mobile-menu__language-upper-text {
  color: #000;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.mobile-menu__language-overlay {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transform: translateX(100%);
  background-color: #fff;
  top: 80px;
  width: 100%;
  height: calc(100vh - 80px);
  z-index: 3;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  border-top: 3px solid #262C63;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 17px 21px;
}
.mobile-menu__language-overlay::-webkit-scrollbar {
  width: 4px;
}
.mobile-menu__language-overlay::-webkit-scrollbar-thumb {
  background-color: #262C63;
  border-radius: 10px;
}
.mobile-menu__language-overlay > *:not(:last-child) {
  border-bottom: 0.5px solid #E7E7E7;
}
.mobile-menu__language-overlay--active {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}
.mobile-menu__language-overlay-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-menu__icon {
  width: 24px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.mobile-menu__icon-bar {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: black;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.mobile-menu__icon-bar:nth-child(1) {
  top: 0px;
  right: 0;
  left: auto;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.mobile-menu__icon-bar:nth-child(2) {
  top: 9px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.mobile-menu__icon-bar:nth-child(3) {
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.mobile-menu__icon--open .mobile-menu__icon-bar:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 1px;
  left: 8px;
  right: auto;
  width: 100%;
  height: 2px;
  background-color: #262C63;
}
.mobile-menu__icon--open .mobile-menu__icon-bar:nth-child(2) {
  width: 0%;
  opacity: 0;
}
.mobile-menu__icon--open .mobile-menu__icon-bar:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 18px;
  left: 8px;
  height: 2px;
  background-color: #262C63;
}

/*=============================================
=            Modal Video            =
=============================================*/
.modal-video {
  width: 100%;
  position: relative;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.modal-video::backdrop {
  background-color: rgba(38, 44, 99, 0.7);
}
.modal-video__inner {
  width: 600px;
  width: 100%;
  padding: 25px;
  position: relative;
}
@media (max-width: 991px) {
  .modal-video__inner {
    padding: 0;
  }
}
.modal-video__close {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  background-color: #FF5003;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 991px) {
  .modal-video__close {
    height: 40px;
    width: 40px;
  }
}
.modal-video__close:hover, .modal-video__close:focus {
  background-color: #262C63;
}
.modal-video__close-icon {
  font-size: 26px;
}
.modal-video__video {
  height: 80vh;
  width: 100%;
  object-fit: contain;
}

/*=====  End of Modal Video  ======*/
/*=============================================
=            navbar            =
=============================================*/
.navbar {
  background-color: #262C63;
  color: #fff;
}
.navbar__link {
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.navbar__link:hover, .navbar__link:focus {
  scale: 1.1;
}
.navbar__wrapper {
  width: 1440px;
  padding-inline: 32px;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .navbar {
    flex-direction: column-reverse;
    padding-block-end: 15px;
  }
}
.navbar__socials {
  gap: 8px;
}
.navbar__right {
  gap: 55px;
}
.navbar__dropdown {
  display: flex;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  flex-direction: column;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  gap: 30px;
  top: 100%;
  left: 0;
  z-index: 2;
  width: min-content;
  background-color: #262C63;
  padding: 20px 30px;
  align-items: start;
  justify-content: start;
  transition: visibility 0.2s linear 0.2s, opacity 0.2s linear;
  transform: translateZ(0);
}
.navbar__dropdown.visible {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.navbar__dropdown-item {
  position: relative;
  transition: color 0.1s ease-in;
}
.navbar__dropdown-item:hover {
  color: #FF5003;
}
.navbar__dropdown-item.selected {
  color: #FF5003;
}
.navbar__dropdown-item.selected::before {
  content: "✓";
  position: absolute;
  left: -20px;
  top: -1px;
}
.navbar__dropdowns-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  height: 100%;
  color: #F6F7F9;
}
.navbar__dropdowns-breaker {
  width: 1px;
  background-color: rgba(246, 247, 249, 0.5);
}
.navbar__dropdowns-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 7px;
}
.navbar__dropdowns-item-upper {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: 7px;
  cursor: pointer;
}
.navbar__contact {
  padding: 13px 17px 8px;
  background-color: #FF5003;
  height: 100%;
}
.navbar__contact-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
}
.navbar__contact-number {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.8px;
}

/*=====  End of navbar  ======*/
/*=============================================
=            News Card Grid            =
=============================================*/
.news-grid {
  position: relative;
}
.news-grid__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-row-gap: 20px;
  grid-column-gap: 16px;
}
.news-grid__header {
  margin-bottom: 56px;
}
.news-grid__title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  line-height: 30px;
  max-width: 880px;
  text-transform: uppercase;
}
.news-grid__title-highlight {
  color: #262C63;
}
.news-grid__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}
.news-grid__image-container {
  position: relative;
  min-height: 177px;
  max-height: 177px;
}
.news-grid__date {
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;
  background-color: #FF5003;
  border-radius: 0 5px;
  color: #fff;
  padding: 15px 27px 12px;
  font-size: 12px;
}
.news-grid__horizontal-line {
  height: 1px;
  margin: 0 auto;
  width: 85%;
  background-color: #DADADA;
}
.news-grid__item {
  border-radius: 5px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.news-grid__item:hover {
  box-shadow: 0px 6px 8px 6px rgba(0, 0, 0, 0.05);
}
.news-grid__item-title {
  color: #1a202c;
  margin-bottom: 17px;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.44px;
}
.news-grid__item-content {
  padding-block-end: 17px;
  margin: 23px 18px 0px;
  font-size: 14px;
  font-weight: 300;
}
.news-grid__item-summary {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news-grid__link {
  color: #1E234F;
  font-size: 16px;
  padding: 12px 17px 15px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.news-grid__link:hover {
  color: #FF5003;
}

/*=====  End of News Card Grid  ======*/
/*=============================================
=            News Card List            =
=============================================*/
.news-list {
  position: relative;
}
.news-list__grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(280px, 1fr));
  grid-row-gap: 20px;
  grid-column-gap: 16px;
}
.news-list__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}
.news-list__image-container {
  position: relative;
  min-height: 177px;
  max-height: 177px;
}
.news-list__date {
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;
  background-color: #FF5003;
  border-radius: 0 5px;
  color: #fff;
  padding: 15px 27px 12px;
  font-size: 12px;
}
.news-list__horizontal-line {
  height: 1px;
  margin: 0 auto;
  width: 85%;
  background-color: #DADADA;
}
.news-list__item {
  border-radius: 5px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.news-list__item:hover {
  box-shadow: 0px 6px 8px 6px rgba(0, 0, 0, 0.05);
}
.news-list__item-title {
  color: #1a202c;
  margin-bottom: 17px;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.44px;
}
.news-list__item-content {
  padding-block-end: 17px;
  margin: 23px 18px 0px;
  font-size: 14px;
  font-weight: 300;
}
.news-list__item-summary {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news-list__link {
  color: #1E234F;
  font-size: 16px;
  padding: 12px 17px 15px;
}
.news-list__pagination {
  padding: 0 26px;
  margin-top: 69px;
  gap: 10px;
}
@media (max-width: 991px) {
  .news-list__pagination {
    gap: 8px;
    margin-top: 32px;
  }
}
.news-list__pagination-icon {
  font-size: 24px;
}
.news-list__pagination-button {
  height: 40px;
  width: 40px;
  border-radius: 2px;
  border: 2px solid #DADADA;
  cursor: pointer;
  font-family: inherit;
  font-size: 13px;
  font-weight: 800;
  line-height: normal;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 991px) {
  .news-list__pagination-button {
    height: 34px;
    width: 34px;
  }
}
.news-list__pagination-button:hover, .news-list__pagination-button:focus {
  border-color: #1E234F;
}
.news-list__pagination-button:disabled {
  opacity: 0.3;
  cursor: default;
}
.news-list__pagination-button:disabled:hover {
  border-color: #BEBEBE;
}
.news-list__pagination-button--active {
  border-color: #1E234F;
  color: #fff;
  background: #1E234F;
}

/*=====  End of News Card List  ======*/
.single-news {
  display: flex;
  flex-direction: row;
  gap: 180px;
  padding: 40px 50px;
  flex: 1;
  justify-content: space-between;
  margin: 0 auto;
  width: fit-content;
}
.single-news__right {
  width: 950px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.single-news__right-image {
  width: 100%;
  height: 480px;
  background-size: cover;
}
.single-news__right-content {
  display: none;
  font-family: inherit;
}
.single-news__right-content.active {
  display: block;
}
.single-news__right-content .container {
  padding: 0px;
}
.single-news__author-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
}
.single-news__author-title {
  color: #FF5003;
  font-family: inherit;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.single-news__author-name {
  color: #262C63;
  font-family: inherit;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}
.single-news__author-date {
  color: #666;
  font-family: inherit;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}
.single-news__left {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  gap: 40px;
}
.single-news__left-title {
  color: #0B2653;
  font-family: inherit;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 26.4px;
  text-transform: capitalize;
}
.single-news__tabs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.single-news__tabs-item {
  color: #1C1C1C;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  padding: 20px;
  width: 100%;
  background-color: #F7F8F9;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  will-change: scale;
}
@media (min-width: 768px) {
  .single-news__tabs-item:hover {
    background-color: #F4F4F4;
    scale: 1.1;
  }
}
.single-news__tabs-item.active {
  background-color: #262C63;
  color: #FFF;
  position: relative;
}
.single-news__tabs-item.active::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 5px;
  height: 50%;
  background-color: #FF5003;
  transform: translateY(-50%);
}
.single-news__share-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.single-news__share-caption {
  color: #262C63;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: capitalize;
}
.single-news__social-wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.single-news__social-button {
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: scale 0.3s ease-in-out;
  will-change: scale;
}
.single-news__social-button:hover {
  scale: 1.1;
}
.single-news__social-image {
  width: 100%;
  height: 100%;
}
.single-news__tags {
  gap: 24px;
}
.single-news__tags-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}
.single-news__tags-item {
  padding: 8px 10px;
  border-radius: 2px;
  border: 1px solid #E7EBEF;
  color: #51668A;
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-transform: capitalize;
}
.single-news__posts {
  gap: 24px;
}
.single-news__posts-container {
  display: flex;
  flex-direction: column;
}
.single-news__posts-image {
  width: 80px;
  height: 80px;
}
.single-news__posts-item {
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-block: 20px;
}
.single-news__posts-item:first-child {
  padding-block-start: 0px;
}
.single-news__posts-item:last-child {
  padding-block-end: 0px;
}
.single-news__posts-item:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #E7EBEF;
}
.single-news__posts-title {
  color: #0B2653;
  font-family: inherit;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  text-transform: capitalize;
}
.single-news__posts-title:hover {
  color: #FF5003;
}
.single-news__posts-date {
  color: #51668A;
  font-family: inherit;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
}
.single-news__posts-text-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.single-news__input-text {
  padding: 17px 20px;
  color: #51668A;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 3px;
  border: 2px solid #E7EBEF;
  background: #F4F4F4;
}
.single-news__input-text::placeholder {
  color: #c4c4c4;
}

@media (max-width: 991px) {
  .single-news {
    flex-direction: column;
    gap: 40px;
    padding-inline: 20px;
  }
  .single-news__right {
    width: 100%;
    gap: 16px;
  }
  .single-news__right-image {
    height: 300px;
  }
  .single-news__right-content.active {
    padding: 0px;
  }
  .single-news__left {
    max-width: 100%;
    gap: 30px;
  }
  .single-news__left-title {
    font-size: 20px;
    line-height: 22px;
  }
  .single-news__author-container {
    flex-direction: row;
    justify-content: space-between;
  }
  .single-news__author-title {
    font-size: 14px;
    line-height: 16px;
  }
  .single-news__author-name {
    font-size: 12px;
    line-height: 14px;
  }
  .single-news__author-date {
    font-size: 12px;
    line-height: 14px;
  }
  .single-news__tabs-item {
    padding: 14px 15px;
    font-size: 14px;
    line-height: 19px;
  }
  .single-news__share-caption {
    font-size: 14px;
    line-height: 16px;
  }
  .single-news__tags {
    gap: 12px;
  }
  .single-news__tags-item {
    padding: 4px 8px;
    font-size: 10px;
    line-height: 12px;
  }
  .single-news__posts {
    gap: 11px;
  }
  .single-news__posts-image {
    width: 60px;
    height: 60px;
  }
  .single-news__posts-item {
    padding-block: 10px;
  }
  .single-news__posts-title {
    font-size: 16px;
    line-height: 18px;
  }
  .single-news__posts-date {
    font-size: 11px;
    line-height: 13px;
  }
}
.not-found {
  margin: 0 auto;
  gap: 20px;
}
.not-found .link {
  width: fit-content;
}
.not-found .link__label {
  text-decoration: unset;
}
.not-found__title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.not-found__subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 1rem;
}
.not-found__left {
  flex: 1;
  align-self: center;
}
.not-found__image {
  max-height: 100%;
  max-width: 600px;
  margin: auto;
}

@media (max-width: 767px) {
  .not-found {
    flex-direction: column;
    gap: 5px;
  }
}
/*=============================================
=            Product Card Grid            =
=============================================*/
.product-grid__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 17px;
}
.product-grid__subtitle {
  color: #282E39;
  font-family: inherit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.product-grid__details {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 5px;
}
.product-grid__details > *:not(:last-child) {
  position: relative;
}
.product-grid__details > *:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -12px;
  width: 100%;
  height: 0.5px;
  background-color: #E7EAEE;
}
.product-grid__details-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-inline: 13px;
}
.product-grid__details-key {
  color: #000;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
  flex-shrink: 0;
  white-space: nowrap;
}
.product-grid__details-value {
  color: #000;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  text-align: right;
}
.product-grid__header {
  margin-bottom: 42px;
}
@media (max-width: 991px) {
  .product-grid__header {
    flex-direction: column;
  }
}
.product-grid__header-right {
  gap: 30px;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  .product-grid__header-right {
    margin-block-start: 10px;
  }
}
.product-grid__header-right .link {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
  padding: 15px 30px;
}
.product-grid__header-right .link__label {
  padding-inline: 28px;
}
.product-grid__header-left {
  max-width: 629px;
}
.product-grid__title {
  color: #000;
  font-size: 39px;
  font-weight: 700;
  line-height: 200%;
  letter-spacing: -0.78px;
}
@media (max-width: 991px) {
  .product-grid__title {
    line-height: 100%;
    margin-block-end: 10px;
  }
}
.product-grid__title-highlight {
  color: #262C63;
}
.product-grid__item {
  padding: 22px;
  border: 1px solid #F2F2F2;
  gap: 12px;
  background-color: #fff;
}
.product-grid__item-image {
  height: 196px;
  width: 80%;
  object-fit: contain;
}
.product-grid__item-image-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-grid__item-title {
  text-transform: capitalize;
}
.product-grid__item-title-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.product-grid__item-model {
  font-weight: 700;
  text-transform: uppercase;
  color: #262C63;
}
.product-grid__buttons {
  gap: 11px;
  margin-top: auto;
}
.product-grid__buttons .link {
  width: 100%;
  font-size: 14px;
}

/*=====  End of Product Card Grid  ======*/
.product-details {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.product-details__glide {
  max-width: 590px;
}
.product-details__upper {
  display: flex;
  flex-direction: row;
  gap: 170px;
  justify-content: space-around;
}
.product-details__upper-left {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.product-details__upper-left__image {
  width: 533px;
  height: 461px;
}
.product-details__upper-left__image-container {
  position: relative;
}
.product-details__upper-left__image-container #glide__pagination {
  position: absolute;
  background-color: #fff;
  top: 10px;
  right: 10px;
  padding: 10px 15px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  color: black;
  border-radius: 20px;
}
.product-details__upper-left .glide {
  max-width: 530px;
}
.product-details__upper-left .glide__slide {
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-details__upper-left .glide__slide.active {
  border: 4px solid #e7e7e7;
}
.product-details__upper-right {
  display: flex;
  flex-direction: column;
}
.product-details__upper-right table {
  width: 100%;
  border-collapse: collapse;
  margin-block: 10px;
}
.product-details__upper-right table tr:nth-child(even) {
  background-color: #E7E7E7;
}
.product-details__upper-right table tr:nth-child(odd) {
  background-color: #ffffff;
}
.product-details__upper-right table td {
  border: 1px solid #E7E7E7;
  padding: 10px;
}
.product-details__upper-right table td:first-child {
  width: 30%;
  color: #666;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.product-details__upper-right table td:last-child {
  color: #2C2C2C;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.product-details__upper-brochure-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  will-change: transform;
}
.product-details__upper-brochure-container:hover {
  transform: scale(1.1);
}
.product-details__upper-brochure-image {
  display: inline;
  width: 35px;
  height: 28;
}
.product-details__upper-brochure-text {
  color: #262C63;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.6px;
}
.product-details__upper-button {
  padding: 14px 20px;
  border-radius: 4px;
  background: #FF5003;
  color: #FFF;
  font-family: inherit;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.6px;
  width: fit-content;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.product-details__upper-button:hover {
  background: #262C63;
}
.product-details__upper-button-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.product-details__upper-product-name {
  color: #262C63;
  font-family: inherit;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 47.88px;
  text-transform: uppercase;
}
.product-details__upper-category-name {
  color: #000;
  font-family: inherit;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 47.88px; /* 133% */
  text-transform: uppercase;
}
.product-details__lower {
  margin-block-start: 40px;
  display: flex;
  flex-direction: column;
}
.product-details__lower-inner {
  margin: 0;
}
.product-details__bar {
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: #F7F8F9;
  margin-block-end: 30px;
}
.product-details__bar-item {
  padding: 10px 20px;
  color: #626262;
  text-align: center;
  font-family: inherit;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
}
.product-details__bar-item.active {
  background-color: #262C63;
  color: #FFF;
}
.product-details__bar-item.active::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #262C63;
}
.product-details__specs-inner {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  gap: 100px;
}
.product-details__specs-inner.active {
  display: flex;
}
.product-details__specs-left {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
}
.product-details__specs-right {
  flex-grow: 1;
}
.product-details__specs-table {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.product-details__specs-table table {
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
}
.product-details__specs-table table td {
  padding-block: 10px;
  border: 1px solid #E7E7E7;
}
.product-details__specs-table table td:first-child {
  color: #000;
  font-family: inherit;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 32%;
}
.product-details__specs-table table td:last-child {
  padding-inline-start: 20px;
  color: #000;
  font-family: inherit;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.product-details__specs-table-name {
  color: #1C1C1C;
  font-family: inherit;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.2px;
  text-transform: uppercase;
}
.product-details__features-inner {
  display: none;
  flex-direction: column;
  gap: 40px;
}
.product-details__features-inner.active {
  display: flex;
}
.product-details__features-item {
  padding: 15px 25px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.product-details__features-item:hover {
  box-shadow: 0px 6px 7px 8px rgba(218, 218, 218, 0.6);
}
.product-details__features-item-title {
  margin-block-end: 10px;
  color: #1c1c1c;
  font-family: inherit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.2px;
}
.product-details__features-item-title:after {
  margin-block-start: 10px;
  content: "";
  display: block;
  width: 100%;
  height: 1.5px;
  background-color: #e7e7e7;
}
.product-details__features-item-content {
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.product-details__features-item-content .container {
  padding: 0;
  max-width: none;
}
.product-details__equipment-inner {
  display: none;
  gap: 23px;
}
.product-details__equipment-inner.active {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 23px;
}
.product-details__equipment-item {
  padding: 10px 20px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.product-details__equipment-item:hover {
  box-shadow: 0px 6px 7px 8px rgba(218, 218, 218, 0.6);
}
.product-details__equipment-item-title {
  color: #1c1c1c;
  font-family: inherit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.2px;
  margin-block-end: 10px;
}
.product-details__equipment-item-title:after {
  margin-block-start: 10px;
  content: "";
  display: block;
  width: 100%;
  height: 1.5px;
  background-color: #e7e7e7;
}
.product-details__equipment-item-content {
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 767px) {
  .product-details {
    padding-inline: 20px;
  }
  .product-details__upper {
    flex-direction: column;
    gap: 30px;
  }
  .product-details__upper-left__image {
    width: 350px;
    height: 350px;
  }
  .product-details__upper-left__image-container #glide__pagination {
    top: 0;
    right: 0;
    padding: 5px 10px;
    font-size: 12px;
  }
  .product-details__upper-left .glide {
    max-width: 100%;
  }
  .product-details__upper-left .glide__slide {
    height: 100px;
    width: 100px;
  }
  .product-details__upper-right table tr td:first-child {
    width: 10%;
  }
  .product-details__upper-button-container {
    flex-direction: column;
    gap: 14px;
    align-items: center;
  }
  .product-details__upper-category-name {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
  .product-details__upper-product-name {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
  .product-details__bar {
    flex-direction: column;
    gap: 10px;
    margin-block-end: 20px;
  }
  .product-details__bar-item {
    padding: 10px 0;
    font-size: 16px;
  }
  .product-details__bar-item.active::after {
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #262C63;
  }
  .product-details__specs-inner {
    flex-direction: column;
    gap: 20px;
  }
  .product-details__specs-inner.active {
    gap: 25px;
  }
  .product-details__specs-table table td:first-child {
    width: 35%;
  }
  .product-details__specs-left {
    gap: 25px;
  }
  .product-details__features-inner {
    flex-direction: column;
    gap: 20px;
  }
  .product-details__equipment-inner.active {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .product-details__equipment-item {
    flex: 1;
  }
}
.product-listing {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 1440px;
  background-color: transparent;
  padding-block: 40px;
}
.product-listing--wrapper {
  background-color: #F8F8F8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.product-listing__left {
  width: 332px;
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.product-listing__left-image {
  width: 100%;
  max-height: 376px;
}
.product-listing__select {
  padding: 4px 15px;
  border-radius: 3px;
  border: 1px solid #9F9F9F;
}
.product-listing__select-container {
  display: flex;
  flex-direction: row;
  gap: 6px;
}
.product-listing__pagination-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.product-listing__pagination-button {
  border-radius: 2px;
  background: #F1F1F1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  will-change: background-color, scale, color;
  font-size: 16px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.product-listing__pagination-button:hover {
  background-color: #262C63;
  color: #fff;
  scale: 1.05;
}
.product-listing__pagination-button.active {
  background-color: #262C63;
  color: #fff;
}
.product-listing__details {
  padding: 17px 21px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
}
.product-listing__details-right {
  display: flex;
  flex-direction: row;
  gap: 35px;
}
@media (max-width: 991px) {
  .product-listing__details {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
}
.product-listing--no-results-image {
  display: none;
  width: 100%;
  height: 100%;
  max-width: 700px;
  max-height: 700px;
  object-fit: contain;
}
.product-listing__right {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}
.product-listing__right--loading {
  height: 100vh;
  justify-content: space-between;
}
.product-listing__right--loading .product-listing__grid, .product-listing__right--loading .product-listing__details:last-child {
  display: none;
}
.product-listing__right--loading .loader {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}
.product-listing__right--no-results {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
}
.product-listing__right--no-results .product-listing__grid, .product-listing__right--no-results .product-listing__details {
  display: none;
}
.product-listing__right--no-results .product-listing--no-results-image {
  display: block;
}
.product-listing__filter-key {
  color: #000;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.product-listing__filter-key.disabled {
  color: #9F9F9F;
  cursor: not-allowed;
}
.product-listing__filter-key.disabled:hover {
  color: #9F9F9F;
}
.product-listing__filter-key.active {
  color: #262C63;
  font-weight: 700;
}
.product-listing__filter-key:hover {
  color: #262C63;
  font-weight: 700;
}
.product-listing__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 17px;
}
.product-listing__checkbox {
  width: 20px;
  height: 20px;
  background-color: #F8F8F8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-appearance: none;
}
.product-listing__checkbox:checked {
  background-color: #262C63;
}
.product-listing__checkbox:checked::after {
  content: "✔";
  color: #fff;
  font-size: 14px;
}
.product-listing__checkbox-label {
  color: #000;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  max-width: 82%;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.product-listing__checkbox-label label {
  cursor: pointer;
}
.product-listing__checkbox-label:hover {
  color: #262C63;
  font-weight: 700;
}
.product-listing__checkbox-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.product-listing__accordion.active .product-listing__accordion-lower {
  display: flex;
}
.product-listing__accordion--loading .product-listing__checkbox-container {
  cursor: not-allowed;
}
.product-listing__accordion--loading .product-listing__checkbox-container * {
  cursor: not-allowed;
}
.product-listing__accordion-title {
  color: #333;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.product-listing__accordion-upper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 11px 14px;
  background-color: #F8F8F8;
  cursor: pointer;
}
.product-listing__accordion-lower {
  display: none;
  padding: 11px 14px;
  display: none;
  flex-direction: column;
  gap: 18px;
  max-height: 232px;
  overflow: auto;
  background-color: #fff;
}
.product-listing__accordion-lower::-webkit-scrollbar {
  width: 4px;
}
.product-listing__accordion-lower::-webkit-scrollbar-thumb {
  background-color: #262C63;
  border-radius: 10px;
}
.product-listing__accordion-lower.active {
  display: flex;
}

@media (max-width: 767px) {
  .product-listing {
    width: 100%;
    padding-block: 20px;
    flex-direction: column;
    gap: 20px;
  }
  .product-listing__left {
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }
  .product-listing__left-image {
    max-width: 400px;
    max-height: 376px;
  }
  .product-listing__left-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
  }
  .product-listing__details {
    flex-direction: column;
    gap: 20px;
  }
  .product-listing__right {
    width: 100%;
  }
}
.loader {
  display: none;
  border: 8px solid rgba(255, 80, 3, 0.3);
  border-top: 8px solid #262C63;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.rte__title {
  margin-bottom: 10px;
  color: #000;
}
.rte__subtitle {
  color: inherit;
  margin-bottom: 56px;
}
.rte h1,
.rte h2,
.rte h3,
.rte h4,
.rte h5 {
  color: inherit;
  display: block;
  margin-bottom: 20px;
}
.rte p,
.rte ol,
.rte ul {
  color: inherit;
  display: block;
  margin-bottom: 20px;
}
.rte ul,
.rte ol,
.rte li {
  margin-left: 16px;
}
.rte ul,
.rte ol {
  margin-top: 20px;
}
.rte ul {
  list-style: disc;
}
.rte ol {
  list-style: decimal;
}
.rte strong {
  font-weight: 700;
}
.rte a {
  color: #262C63;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

/*=====  End of RTE  ======*/
.search {
  display: flex;
  flex-direction: column;
}
.search__count {
  padding-block-start: 30px;
  font-weight: 500;
  opacity: 0.8;
}
.search__pagination {
  padding: 17px 20px;
  color: #262C63;
  font-family: inherit;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  border-radius: 2px;
  border: 2px solid #E7EBEF;
  cursor: pointer;
}
.search__pagination:not(.search__pagination--active):hover {
  background-color: #F6F7F9;
}
.search__pagination:not(.search__pagination--active):hover:active {
  color: #fff;
}
.search__pagination--active {
  background-color: #262C63;
  border-color: #262C63;
  color: #FFF;
  pointer-events: none;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.search__pagination--active:hover {
  color: #fff;
  opacity: 0.8;
}
.search__pagination-container {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-block-start: 40px;
}
.search__found-text {
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-inline-start: 12px;
  margin-block-start: 40px;
}
.search__top {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.search__top-title {
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-inline-start: 23px;
}
.search__result {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  padding-block: 30px;
}
.search__result-link {
  color: #262C63;
  font-family: inherit;
  font-size: 20.836px;
  font-style: normal;
  font-weight: 700;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.search__result-link:hover {
  color: #FF5003;
}
.search__result-description {
  max-width: 88%;
  color: #333;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.search__result:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #E7EAEE;
}
.search__section {
  margin-block-start: 40px;
}
.search__section-title {
  color: #333;
  font-family: inherit;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  position: relative;
  padding-block-end: 15px;
  margin-block-end: 11px;
}
.search__section-title:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #E7EAEE;
}
.search__input {
  outline: none;
  font-size: 15px;
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.search__input::placeholder {
  color: #64748B;
}
.search__input-icon {
  width: 24px;
  height: 24px;
}
.search__input-container {
  display: flex;
  flex-direction: row;
  gap: 22px;
  width: 100%;
}
.search__input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 22px;
  padding: 7px 10px 8px 22px;
  background-color: #F6F7F9;
  align-items: center;
}
.search__input-wrapper .button {
  padding: 12px 20px;
  line-height: unset;
}
.search__input-wrapper .button__label {
  font-size: 14px;
  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  min-width: 100px;
}

.highlight--primary {
  color: #262C63;
}
.highlight--secondary {
  color: #FF5003;
}
.highlight--yellow {
  color: #FBF719;
}

@media (max-width: 991px) {
  .search__result-description {
    max-width: 100%;
  }
  .search__section {
    margin-block-start: 30px;
  }
  .search__found-text {
    margin-inline-start: 0;
    margin-block-start: 30px;
  }
  .search__input-wrapper {
    padding: 7px 10px 8px 10px;
    flex-direction: column;
  }
  .search__input-wrapper .button {
    width: 100%;
  }
  .search__top-title {
    margin-inline-start: 0;
    text-align: center;
  }
}
/*=============================================
=            Services Component            =
=============================================*/
.services {
  position: relative;
  background: linear-gradient(90deg, #262C63 50%, transparent 65%, transparent 100%);
}
@media (max-width: 767px) {
  .services {
    background: linear-gradient(90deg, #262C63 75%, transparent 85%, transparent 100%);
  }
}
.services__title {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 30px;
}
.services__inner {
  margin: 0 auto;
  padding: 51px 134px 10px;
}
@media (max-width: 991px) {
  .services__inner {
    padding: 51px 30px 10px;
  }
}
.services__tabs {
  color: #fff;
  gap: 10px;
  box-sizing: border-box;
  border-bottom: 6px solid #E4EBF2;
  width: fit-content;
  padding: 8px;
}
@media (max-width: 767px) {
  .services__tabs {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
}
.services__label {
  position: relative;
  max-width: 125px;
  font-size: 16px;
  text-align: center;
  transition: background-color, border-color 0.2s ease-in-out;
  cursor: pointer;
}
.services__label:focus {
  border-color: #000;
}
.services__label:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -14px;
  transform: translate(-50%, 0);
  height: 6px;
  width: 100%;
  background-color: #FF5003;
  z-index: -1;
}
.services__label:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 100%) rotate(45deg);
  height: 22px;
  width: 22px;
  background: linear-gradient(135deg, #FF5003 50%, transparent 50%);
  z-index: -1;
}
.services__label label {
  cursor: pointer;
}
.services__label:first-of-type:before, .services__label:last-of-type:before {
  width: 136%;
}
.services__radio {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}
.services__radio + .services__label {
  margin: 0;
  padding: 5px 0px;
  box-sizing: border-box;
  position: relative;
  font-size: 16px;
  font-weight: 400;
}
.services__radio:hover + .services__label {
  border-color: #FF5003;
}
.services__radio:checked + .services__label {
  color: #FF5003;
  z-index: 1;
}
.services__cards {
  display: none;
}
.services__cards-top {
  gap: 24px;
  margin: 32px 0 24px;
}
@media (max-width: 991px) {
  .services__cards-top {
    flex-direction: column;
  }
}
.services__card {
  max-width: 50%;
}
@media (max-width: 991px) {
  .services__card {
    min-width: auto;
    max-width: none;
    width: 100%;
  }
}
.services__card-image {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  height: 100%;
  object-fit: cover;
}
.services__card-title {
  margin-bottom: 20px;
  margin-inline-end: auto;
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.5px;
  color: #FF5003;
}
@media (max-width: 991px) {
  .services__card-title {
    font-size: 25px;
    line-height: 29px;
    margin-bottom: 10px;
  }
}
.services__card-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #262C63;
}
.services .rte {
  color: #fff;
  padding: 0;
  line-height: 30px;
  margin-block-end: 10px;
}
.services .rte li {
  color: #fff;
}
.services ::-webkit-scrollbar {
  height: 8px;
  width: 4px;
  background: rgba(255, 80, 3, 0.5);
}
.services ::-webkit-scrollbar-thumb {
  background: #FF5003;
  border-radius: 20px;
}

/*=====  End of Services Component  ======*/
.services-page-hero {
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
  width: 100%;
  position: relative;
  color: #FFF;
}
.services-page-hero:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(15, 54, 123, 0.56) 0%, rgba(38, 44, 99, 0.56) 100%);
  height: 100%;
  width: 100%;
  z-index: 0;
}
.services-page-hero__inner {
  max-width: 830px;
  padding-block-start: 100px;
  margin-left: 90px;
  z-index: 1;
}
@media (max-width: 767px) {
  .services-page-hero__inner {
    margin-left: 0;
    padding-block-start: 50px;
  }
}
.services-page-hero__title {
  color: #FFF;
  font-family: inherit;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 57px;
  text-transform: capitalize;
  margin-bottom: 14px;
}
@media (max-width: 767px) {
  .services-page-hero__title {
    font-size: 25px;
    line-height: 28px;
    text-align: center;
  }
}
.services-page-hero__content {
  font-family: inherit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
@media (max-width: 767px) {
  .services-page-hero__content {
    font-size: 16px;
    text-align: center;
  }
}
.services-page-hero .hero__breadcrumbs {
  left: 85px;
}

/*=============================================
=            Video Carousel            =
=============================================*/
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.video {
  position: relative;
  margin-bottom: 180px;
}
@media (max-width: 767px) {
  .video {
    padding-bottom: 200px;
  }
}
@media (max-width: 479px) {
  .video {
    padding-bottom: 250px;
  }
}
.video__wrapper {
  position: relative;
}
.video__wrapper--with-pointer {
  cursor: pointer;
}
.video__player {
  background-color: #000;
  width: 100%;
  max-height: 700px;
  aspect-ratio: 32/15;
  pointer-events: none;
}
.video__thumbnail {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  pointer-events: none;
}
.video__thumbnail--visible {
  opacity: 1;
  pointer-events: auto;
}
.video__thumbnail-play-icon {
  z-index: 1;
  cursor: pointer;
  pointer-events: none;
  position: absolute;
  width: 100px;
  inset-inline-start: 50%;
  inset-block-start: 50%;
  transform: translate(-50%, -50%);
}
.video__text-container {
  z-index: 1;
  color: #fff;
  max-width: 400px;
  position: absolute;
  transform: translateY(-36%);
}
@media (max-width: 767px) {
  .video__text-container {
    max-width: 100%;
    inset-inline-end: 0;
    inset-block-end: -2px;
    transform: translateY(100%);
  }
}
.video__text-upper {
  padding: 30px 50px;
}
@media (max-width: 767px) {
  .video__text-upper {
    color: #000;
    height: fit-content !important;
    padding: 15px 32px;
    color: #000;
  }
}
.video__text-lower {
  background-color: #FF5003;
  padding: 16px 50px;
  padding-bottom: 50px;
}
@media (max-width: 767px) {
  .video__text-lower {
    padding: 15px 32px;
  }
}
.video__text-title {
  height: fit-content;
  margin-top: auto;
  max-width: 75%;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
@media (max-width: 767px) {
  .video__text-title {
    max-width: 100%;
  }
}
.video__text-content {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-block-end: 20px;
}
.video__text-link {
  position: relative;
  padding-inline-start: 34px;
  font-size: 15px;
  font-weight: 700;
}
.video__text-link-arrow {
  position: absolute;
  width: 22px;
  inset-block-start: 0;
  inset-inline-start: 0;
}

/*=====  End of Video Carousel  ======*/
.widget {
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.widget__toggle, .widget__content {
  background-color: #FF5003;
  border-radius: 50%;
  text-align: center;
  position: relative;
}
.widget__toggle {
  width: 65px;
  height: 65px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  will-change: scale;
}
.widget__toggle:hover {
  transform: scale(0.9);
}
.widget__toggle-icon {
  height: 27px;
  width: 27px;
}
.widget__list {
  display: flex;
  visibility: hidden;
  flex-direction: column;
  margin-right: -10px;
  gap: 10px;
}
.widget__list--active {
  visibility: visible;
  animation: listAnimation 0.3s ease;
}
.widget__list-item {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  background-color: #FF5003;
  padding: 6px 5px 6px 12px;
  border-radius: 32px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  font-family: inherit;
  cursor: pointer;
  transition: scale 0.3s ease;
}
.widget__list-item:hover, .widget__list-item:nth-child(2):hover {
  scale: 0.9;
  color: #fff;
}
.widget__list-item:nth-child(2) {
  transform: translateX(-23%);
}
.widget__list-icon {
  width: 28px;
  height: 25px;
}
.widget__list-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 8px;
  border-radius: 50%;
  background-color: #fff;
}

@keyframes listAnimation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.enquiry-form {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
}
.enquiry-form__title {
  color: #FF5003;
  font-family: inherit;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}
.enquiry-form__subtitle {
  color: #262C63;
  font-family: inherit;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  text-transform: capitalize;
  max-width: 75%;
}
.enquiry-form .umbraco-forms-page {
  padding-block-start: 10px;
  display: flex;
  flex-direction: column;
  gap: 19px;
}
.enquiry-form .umbraco-forms-page .umbraco-forms-fieldset .row-fluid {
  display: flex;
  flex-direction: row;
  gap: 26px;
  justify-content: space-between;
}
.enquiry-form .umbraco-forms-page .umbraco-forms-container {
  flex: 1;
}
.enquiry-form .umbraco-forms-page .umbraco-forms-container textarea {
  height: 166px;
}

@media (max-width: 991px) {
  .enquiry-form {
    padding: 37px 18px 66px;
    gap: 7px;
  }
  .enquiry-form__title {
    font-size: 12px;
    line-height: 12px;
  }
  .enquiry-form__subtitle {
    font-size: 16px;
    line-height: 30px;
    max-width: 100%;
  }
  .enquiry-form .umbraco-forms-page {
    padding-block-start: 11px;
    gap: 10px;
  }
  .enquiry-form .umbraco-forms-page .umbraco-forms-fieldset .row-fluid {
    flex-direction: column;
    gap: 10px;
  }
  .enquiry-form .umbraco-forms-page .umbraco-forms-container textarea {
    height: 100px;
  }
}
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.get-in-touch {
  background-size: cover;
  height: fit-content;
  position: relative;
}
.get-in-touch:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #E7EBEF;
}
.get-in-touch__background {
  position: absolute;
  height: 695px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
}
.get-in-touch__background-image {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.get-in-touch__background-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #262C63;
  opacity: 0.85;
  z-index: 1;
}
.get-in-touch__bottom {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 80px;
  align-items: center;
  margin-block-end: 30px;
}
.get-in-touch__bottom-left {
  color: #262C63;
  font-family: inherit;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  flex: 1;
  display: flex;
  justify-content: center;
}
.get-in-touch__bottom-right {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 50px;
}
.get-in-touch__wrapper-top {
  padding-block-start: 130px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 80px;
}
.get-in-touch__quote {
  color: #fff;
  font-family: inherit;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 47px;
  overflow: auto;
  position: relative;
  max-width: 100%;
}
.get-in-touch__quote-glide--slides {
  position: relative;
  overflow-y: auto;
  height: 250px;
}
.get-in-touch__quote::-webkit-scrollbar {
  width: 4px;
}
.get-in-touch__quote::-webkit-scrollbar-thumb {
  background-color: #FF5003;
  border-radius: 10px;
}
.get-in-touch__person-name {
  color: #fff;
  font-family: inherit;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: capitalize;
}
.get-in-touch__person-job {
  color: #F9F9F9;
  font-family: inherit;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
}
.get-in-touch__person-container {
  display: none;
  flex-direction: column;
  gap: 12px;
}
.get-in-touch__person-container.active {
  display: flex;
  animation: fade_in_show 0.3s;
}
.get-in-touch__image {
  height: 80px;
  width: 60px;
  border-radius: 2px;
}
.get-in-touch__image-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  width: fit-content;
}
.get-in-touch__image-symbol {
  width: 30px;
  height: 22px;
  align-self: flex-end;
}
.get-in-touch__button {
  color: #FF5003;
  font-size: 24px;
  padding: 4px 8px;
  border-radius: 2px;
  border: 2px solid #FF5003;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.get-in-touch__button:hover {
  color: #fff;
  border-color: #fff;
}
.get-in-touch__button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.get-in-touch__button:disabled:hover, .get-in-touch__button:disabled:focus {
  color: #FF5003;
  border-color: #FF5003;
}
.get-in-touch__buttons {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  gap: 10px;
}
.get-in-touch__testimonial-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.get-in-touch__testimonial-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-left: 2px solid #fff;
  padding-inline-start: 42px;
}
.get-in-touch__testimonial-title {
  color: #FF5003;
  font-family: inherit;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 16.5px;
  text-transform: capitalize;
  margin-left: 10px;
}
.get-in-touch__right {
  max-width: 585px;
  flex: 1;
}
.get-in-touch__contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: 1px solid #F2F2F2;
  width: fit-content;
  gap: 10px;
}
.get-in-touch__contact-bottom {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.get-in-touch__contact-label {
  font-size: 13px;
  font-weight: 400;
}
.get-in-touch__contact-value {
  font-weight: 700;
  font-size: 14px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.get-in-touch__contact-value:hover {
  color: #FF5003;
  text-decoration: underline;
}
.get-in-touch__contact-icon {
  width: 16px;
  height: 16px;
}
.get-in-touch__left {
  background-color: #fff;
  box-shadow: 0px 1px 73px 0px rgba(40, 40, 40, 0.15);
  padding: 60px 45px 85px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 5px;
  z-index: 2;
  overflow: visible;
  height: fit-content;
}
.get-in-touch__left-wrapper {
  max-width: 585px;
  min-height: 650px;
}
.get-in-touch__left-title {
  color: #262C63;
  font-family: inherit;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  text-transform: capitalize;
}
.get-in-touch__left-subtitle {
  color: #262C63;
  font-family: inherit;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
.get-in-touch__left .umbraco-forms-page {
  display: flex;
  flex-direction: column;
  gap: 26px;
}
.get-in-touch__left .umbraco-forms-page .umbraco-forms-fieldset .row-fluid {
  display: flex;
  flex-direction: row;
  gap: 37px;
  justify-content: space-between;
}
.get-in-touch__left .umbraco-forms-page .umbraco-forms-container {
  flex: 1;
}
.get-in-touch__left .umbraco-forms-page .umbraco-forms-container textarea {
  height: 135px;
}

@keyframes fade_in_show {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@media (max-width: 991px) {
  .get-in-touch {
    display: block;
    height: fit-content;
  }
  .get-in-touch:after {
    bottom: unset;
  }
  .get-in-touch__wrapper {
    display: flex;
    flex-direction: column;
  }
  .get-in-touch__wrapper-top {
    padding-block-start: unset;
    flex-direction: column-reverse;
    gap: 50px;
  }
  .get-in-touch__left {
    padding: 12px 10px;
    border-radius: 5px 5px 0 0;
  }
  .get-in-touch__left-title {
    text-align: center;
  }
  .get-in-touch__left-wrapper {
    min-height: unset;
  }
  .get-in-touch__left .umbraco-forms-page {
    display: flex;
    flex-direction: column;
    gap: 26px;
  }
  .get-in-touch__left .umbraco-forms-page .umbraco-forms-fieldset .row-fluid {
    display: flex;
    flex-direction: column;
    gap: 26px;
    justify-content: space-between;
  }
  .get-in-touch__left .umbraco-forms-page .umbraco-forms-container {
    flex: 1;
  }
  .get-in-touch__left .umbraco-forms-page .umbraco-forms-container textarea {
    height: 135px;
  }
  .get-in-touch__right {
    padding: 56px 32px;
    max-width: 100%;
  }
  .get-in-touch__bottom {
    background-color: #fff;
    padding-block: 10px;
    z-index: 1;
    flex-direction: column;
    gap: 20px;
  }
  .get-in-touch__quote-glide--slides {
    height: 250px;
  }
}
.umbraco-forms-page.success {
  text-align: center;
  font-size: 50px;
  color: #262C63;
}
.umbraco-forms-page.disabled input, .umbraco-forms-page.disabled select, .umbraco-forms-page.disabled textarea {
  pointer-events: none;
  background-color: #F9F9F9;
  opacity: 0.5;
  color: #757575;
}
.umbraco-forms-page input, .umbraco-forms-page select, .umbraco-forms-page textarea {
  flex: 1;
  width: 100%;
  border: 1px solid #ECECEC;
  background: #F9F9F9;
  padding: 15px 21px;
  color: #000;
  resize: none;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.umbraco-forms-page input::placeholder, .umbraco-forms-page select::placeholder, .umbraco-forms-page textarea::placeholder {
  color: #757575;
}
@media (max-width: 991px) {
  .umbraco-forms-page input, .umbraco-forms-page select, .umbraco-forms-page textarea {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .umbraco-forms-page input, .umbraco-forms-page select, .umbraco-forms-page textarea {
    text-align: center;
  }
}
.umbraco-forms-page textarea {
  padding-inline-start: 19px;
}
.umbraco-forms-page input[type=submit] {
  cursor: pointer;
  color: #FFF;
  font-family: inherit;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.6px;
  background-color: #262C63;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.umbraco-forms-page input[type=submit]:hover {
  background-color: #FF5003;
}
.umbraco-forms-page select {
  color: #757575;
  flex: 1;
  width: 100%;
  padding-inline-start: 16px;
}
.umbraco-forms-page label {
  display: none;
}

.umbraco-forms-hidden {
  display: none;
}

.error-list {
  color: #FF0000;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px 20px;
  list-style-type: disc;
}
.error-list__item {
  color: #FF0000;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}