/*=============================================
=            News Card Grid            =
=============================================*/

.news-grid {
    position: relative;

    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-row-gap: 20px;
        grid-column-gap: 16px;
    }

    &__header {
        margin-bottom: 56px;
    }

    &__title {
        color: $text--black;
        font-size: 36px;
        font-weight: 700;
        line-height: 30px;
        max-width: 880px;
        text-transform: uppercase;

        &-highlight {
            color: $text--primary;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;

        &-container {
            position: relative;
            min-height: 177px;
            max-height: 177px;
        }
    }

    &__date {
        position: absolute;
        inset-block-end: 0;
        inset-inline-start: 0;
        background-color: $secondary;
        border-radius: 0 5px;
        color: $white;
        padding: 15px 27px 12px;
        font-size: 12px;
    }

    &__horizontal-line {
        height: 1px;
        margin: 0 auto;
        width: 85%;
        background-color: $medium-grey;
    }

    &__item {
        border-radius: 5px;

        @include transition;

        &:hover {
            box-shadow: 0px 6px 8px 6px rgba(0, 0, 0, 0.05);
        }

        &-title {
            color: #1a202c;
            margin-bottom: 17px;
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
            letter-spacing: -0.44px;
        }

        &-content {
            padding-block-end: 17px;
            margin: 23px 18px 0px;
            font-size: 14px;
            font-weight: 300;
        }

        &-summary {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }
    }

    &__link {
        color: $deep-sapphire;
        font-size: 16px;
        padding: 12px 17px 15px;
        @include transition;

        &:hover {
            color: $text--secondary;   
        }
    }
}

/*=====  End of News Card Grid  ======*/
