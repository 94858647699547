/*=============================================
=            brands Card Grid            =
=============================================*/

.brands-grid {
    margin: 0 auto;
    padding: 74px 82px;
    max-width: 1440px;

    @include media-breakpoint-down(sm) {
        padding: 32px;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        grid-gap: 48px;
    }

    &__header {
        margin-bottom: 100px;
    }

    &__title {
        color: $text--dark-blue;
        font-size: 36px;
        font-weight: 500;
        line-height: 40px;
        max-width: 880px;
    }

    &__subtitle {
        position: relative;
        color: $secondary;
        max-width: 612px;
        font-size: 18px;
        font-weight: 500;
        line-height: 14px;
        margin: 15px 0;

        &:before,
        &:after {
            content: '';
            position: absolute;
            inset-block-start: 50%;
            width: 50%;
            border-bottom: 2px solid $secondary;
        }

        &:before {
            inset-inline-start: -15px;
            margin-inline-start: -50%;
        }

        &:after {
            inset-inline-end: -15px;
            margin-inline-end: -50%;
        }
    }

    &__content {
        font-size: 23px;
        font-weight: 400;
        line-height: 35px;
        max-width: 650px;
        text-align: center;

        @include media-breakpoint-down(sm) {
            text-align: start;
        }
    }

    &__item {
        @include transition;

        &:hover {
            scale: 1.05;
        }
        
        &-image {
            min-height: 120px;
            max-height: 120px;
            object-fit: contain;
            padding: 0 30px;
        }
        
        &-caption {
            text-align: center;
            color: $secondary;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
    }
}

/*=====  End of brands Card Grid  ======*/
