.rte {

    &__title {
        margin-bottom: 10px;
        color: $black;
    }

    &__subtitle {        
        color: inherit;
        margin-bottom: 56px;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: inherit;
        display: block;
        margin-bottom: 20px;
    }

    p,
    ol,
    ul {
        color: inherit;
        display: block;
        margin-bottom: 20px;
    }

    ul,
    ol,
    li {
        margin-left: 16px;
    }

    ul,
    ol {
        margin-top: 20px;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    strong {
        font-weight: 700;
    }

    a {
        color: $primary;
        font-weight: 700;
        text-decoration: underline;
        cursor: pointer;
    }
}

/*=====  End of RTE  ======*/
